<h1 style="margin-bottom: 30px;">Cobrar Factura</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="controls">
        <app-date-picker *ngIf="!data.partialPaymentDate" formControlName="actualPaymentDate"
            label="Fecha Cobro Real"></app-date-picker>
        <app-input *ngIf="data.partialPaymentDate" formControlName="actualPaymentDate" label="Fecha Cobro Real"
            [default]="data?.partialPaymentDate | formatDate" [isDisabled]="true" appearance="legacy"></app-input>
        <p class="tiny tiny-date">Fecha cobro proyectada: {{ data.paymentDate | formatDate }}</p>
    </div>

    <div *ngIf="dateWarning==='before'">
        <h3>AVISO!</h3>
        <p class="warning">La fecha de cobro real seleccionada es anterior a la fecha de cobro prevista.</p>
        <input type="submit" class="button button-red" value="¡Sí, proceder!" />
    </div>
    <div *ngIf="dateWarning==='notDelayed'">
        <p class="warning">No tiene mora.</p>
    </div>
    <div *ngIf="dateWarning==='delayed'">
        <p class="text-red">{{feeData?.actualDuration}} días de duración
        </p>
        <app-checkbox [checked]="isChecked" (selectionChanged)="selectDelayedFee($event)"
            content="Aplicar penalidad de mora">
        </app-checkbox>
        <p *ngIf="!isChecked" class="text-red">Tiene mora de {{delayedDays}} días, por favor no olvides aplicar la
            penalidad en
            caso sea necesario.
        </p>
        <div *ngIf="isChecked">
            <div class="column">
                <div class="fee-data">
                    <lib-data-point class="v-margin-group">
                        <div class="title">
                            <lib-text color="dark-gray" content="Monto neto"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs"
                                [content]="data.invoice.currencySymbol + (data.invoice.netAmount | numberSpacing)"></lib-text>
                        </div>
                    </lib-data-point>

                    <lib-data-point class="v-margin-group" aligned="right">
                        <div class="title">
                            <lib-text color="dark-gray" content="Garantía"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs"
                                [content]="data.invoice.currencySymbol + (data.invoice.reservationAmount | numberSpacing)"></lib-text>
                        </div>
                    </lib-data-point>
                </div>
                <div class="fee-data">
                    <lib-data-point class="v-margin-group">
                        <div class="title">
                            <lib-text color="dark-gray"
                                [content]="data.invoice.isConfirming ? 'Costo mensual de Confirming' : 'Costo mensual de factoring'"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs" [content]="feeData?.tcem + '%'"></lib-text>
                        </div>
                    </lib-data-point>
                    <lib-data-point class="v-margin-group" aligned="right">
                        <div class="title">
                            <lib-text color="dark-gray" content="Intereses moratorios"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs"
                                [content]="data.invoice.currencySymbol + (feeData?.lateFee | numberSpacing)"></lib-text>
                        </div>
                    </lib-data-point>
                </div>
                <div class=" fee-data">
                    <lib-data-point class="v-margin-group">
                        <div class="title">
                            <lib-text color="dark-gray" content="Costo sin IGV"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs"
                                [content]="data.invoice.currencySymbol + (feeData?.costWithoutIGV | numberSpacing)"></lib-text>
                        </div>
                    </lib-data-point>
                </div>
                <div class=" fee-data">
                    <lib-data-point class="v-margin-group">
                        <div class="title">
                            <lib-text color="dark-gray" content="Costo de financiamiento"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs"
                                [content]="data.invoice.currencySymbol + (feeData?.investorReturn | numberSpacing)"></lib-text>
                        </div>
                    </lib-data-point>
                    <lib-data-point class="v-margin-group" aligned="right">
                        <div class="title">
                            <lib-text color="dark-gray"
                                [content]="'Comisión Finsmart' + (data.invoice.commissionAdjustment ? ' (Ajuste de comisión Finsmart)' : '')"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs"
                                [content]="data.invoice.currencySymbol + (feeData?.finsmartReturn | numberSpacing) + (data.invoice.commissionAdjustment ? ' (' + feeData?.commissionAdjustment + ')' : '')"></lib-text>
                        </div>
                    </lib-data-point>
                </div>

                <div class="fee-data">
                    <lib-data-point class="v-margin-group">
                        <div class="title">
                            <lib-text color="dark-gray" content="IGV"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs"
                                [content]="data.invoice.currencySymbol + (feeData?.finsmartReturnIGV | numberSpacing)"></lib-text>
                        </div>
                    </lib-data-point>
                    <lib-data-point class="v-margin-group" aligned="right">
                        <div class="title">
                            <lib-text color="dark-gray" content="Precio total"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text size="xs"
                                [content]="data.invoice.currencySymbol + (feeData?.newTotalReturn | numberSpacing)"></lib-text>
                        </div>
                    </lib-data-point>
                </div>
                <div class="fee-data">
                    <lib-data-point class="v-margin-group">
                        <div class="title">
                            <lib-text color="dark-gray" content="Devolución"></lib-text>
                        </div>
                        <div class="value">
                            <lib-text [color]="newRefundAmount < 0 ? 'red' : 'green'" size="xs"
                                [content]="data.invoice.currencySymbol + (newRefundAmount | numberSpacing)"></lib-text>
                        </div>
                    </lib-data-point>
                </div>
            </div>
        </div>

        <div *ngIf="isChecked && data.invoice.invoicingStatus !== 'delay'">
            <hr>
            <h1 style="margin-bottom: 8px;">Facturación</h1>
            <div style="margin-bottom: 24px;">
                <app-checkbox (selectionChanged)="isInvoicingStopped=$event" [checked]="false"
                    content="No facturar automáticamente">
                </app-checkbox>
            </div>
            <!-- (change)="changeSelection($event.value)" -->
            <mat-radio-group *ngIf="!this.data.invoice.isConfirming && !isInvoicingStopped"
                formControlName="invoicingConfiguration" class="radio-button-list">
                <mat-radio-button *ngFor="let option of invoicingOptions" [value]="option.value">
                    {{ option.text }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div *ngIf="isChecked && data.invoice.invoicingStatus === 'delay'">
            <hr>
            <h1 style="margin-bottom: 8px;">Facturación</h1>
            <p style="font-size: 14px;margin-bottom: 24px;">Las opciones de facturación aparecerán al momento de cobrar
                la última partición.
            </p>
        </div>
    </div>
    <div class="button-container">
        <div *ngIf="dateWarning!=='before'">
            <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Confirmar'"
                [type]="'secondary'"></fs-ui-button>
        </div>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>

</form>