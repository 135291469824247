import { HotjarService } from 'src/app/shared/services/hotjar.service';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { getTodayDateOnly, getDaysDiff } from 'src/app/shared/util/date.util';
import { SocketService } from 'src/app/shared/services/socket.service';
import { capitalizeFirstLetters } from './../../../../../shared/util/string.util';
import { CreditNoteConfirmationComponent } from './../../organisms/credit-note-confirmation/credit-note-confirmation.component';
import { decode } from 'js-base64';
import { WindowScrollService } from './../../../../../shared/services/window-scroll.service';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChecklistModalComponent } from './../../../../../shared/UI/organisms/checklist-modal/checklist-modal.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { InvoiceSelectors } from '../../../state/invoices.selector-types';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigureInvoiceDialogComponent } from '../../organisms/configure-invoice-dialog/configure-invoice-dialog.component';
import { InvoicesActions } from '../../../state/invoices.action-types';
import { ReviewRatesComponent } from '../../organisms/review-rates/review-rates.component';
import { PayClientAdvanceDialogComponent } from '../../organisms/pay-client-advance-dialog/pay-client-advance-dialog.component';
import { CollectInvoiceDialogComponent } from '../../organisms/collect-invoice-dialog/collect-invoice-dialog.component';
import { EditAmountsInvoceDialogComponent } from '../../organisms//edit-amounts-invoce-dialog/edit-amounts-invoce-dialog.component';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import * as Big from 'big.js';
import { InvoiceService } from '../../../services/invoice.service';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { take, takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { EmailType } from 'src/app/shared/enums/EmailType.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankAccountsService } from 'src/app/features/bank-accounts/services/bank-accounts.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ShortenPipe } from 'src/app/shared/pipes/shorten.pipe';
import { CurrencyNamePipe } from 'src/app/shared/pipes/currency-name.pipe';
import { BankAccountItemOrganism } from 'src/app/features/bank-accounts/UI/molecules/bank-account-item/bank-account-item.organism';
import { PhysicalInvoiceTableOrganism } from '../../organisms/physical-invoice-table/physical-invoice-table.organism';
import { Location } from '@angular/common';
import { SuplierService } from '../../../../my-supliers/services/supliers.service';
import { EmailBoDialogComponent } from '../../organisms/email-bo-dialog/email-bo-dialog.component';
import { EmailPreviewComponent } from '../../organisms/email-preview/email-preview.component';
import { NewObservationDialogComponent } from '../../organisms/new-observation-dialog/new-observation-dialog.component';
import { GTMService } from 'src/app/shared/services/gtm.service';
import { showToast } from 'src/app/shared/util/toaster.util';
import { Toaster } from 'ngx-toast-notifications';
import { UserStatus } from 'src/app/shared/enums/UserStatus.enum';
import { InvoiceToCavaliComponent } from '../../organisms/invoice-to-cavali/invoice-to-cavali.component';
import { CavaliResponseDialogComponent } from '../../organisms/cavali-response-dialog/cavali-response-dialog.component';
import { GroupingInvoiceComponent } from '../grouping-invoice/grouping-invoice.component';
import { InvoiceGroupConfirmationComponent } from '../../organisms/invoice-group-confirmation/invoice-group-confirmation.component';
import { InvoiceForSaleDialogComponent } from '../../organisms/invoice-for-sale-dialog/invoice-for-sale-dialog.component';
import { ConfirmInvoiceForSaleComponent } from '../../organisms/confirm-invoice-for-sale/confirm-invoice-for-sale.component';
import moment from 'moment';
import { invoice } from 'e2e/helpers/invoice.helper';
import { AddAnnouncementDialogComponent } from '../../organisms/add-announcement-dialog/add-announcement-dialog.component';
import { CommentOnConfirmationComponent } from '../../organisms/comment-on-confirmation/comment-on-confirmation.component';
import { ForwardannouncementComponent } from '../../organisms/forwardannouncement/forwardannouncement.component';
import { ForwardAnnouncementConfirmationComponent } from '../../organisms/forward-announcement-confirmation/forward-announcement-confirmation.component';
moment.locale('es');
import lodash from 'lodash';
import {
  DataPointsOperation,
  DataPointsCostDetail,
} from '../../../../../shared/helperData/data-points-definition';
import { MotiveDialogComponent } from '../../organisms/motive-dialog/motive-dialog.component';
import { InvoiceMotiveConfirmationComponent } from '../../organisms/invoice-motive-confirmation/invoice-motive-confirmation.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ConfirmatSetConditionsComponent } from '../../organisms/confirmat-set-conditions/confirmat-set-conditions.component';
import { TEAValidation } from 'src/app/shared/validators/tea-value.validators';
import { ConfirmationDonwloadPopupComponent } from '../../organisms/confirmation-donwload-popup/confirmation-donwload-popup.component';
import { UsersActions } from 'src/app/features/users/state/users.action-types';
import { LoadProfile } from 'src/app/features/users/state/users.actions';
import { InvoiceStatus } from 'src/app/shared/enums/InvoiceStatus.enum';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
})
export class InvoiceDetailsComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  ngUnsubscribe: Subject<void> = new Subject<void>();
  form: FormGroup;
  formbank: FormGroup;
  formbankSupplier: FormGroup;
  editDistributionForm: FormGroup;
  selectedInvoice;
  selectedInvoiceId;
  selectedBankAccount;
  incomingBA;
  bankAccounts;
  loggedInUserRole;
  loggedInUserStatus;
  editPaymentFlag = false;
  editDistributionFlag = false;
  invoiceUngroupList = [];
  actionType = 'edit';
  firtsOfDecember = new Date(2020, 11, 1).toISOString();
  totalFactoring;
  selectedBankAccountSupplier;
  bankAccountsSupplier;
  emailPanel = false;
  subscription;
  sendEmail = false;
  dataEmail;
  observationPanel = false;
  userObservations;
  invoiceAdmin;
  buttonActiveAction = false;
  iconClass = 'upload-icon';
  iconClassWithdraw = 'download-off';
  iconClassCreditNote = 'post-add';
  groupInvoiceList;
  invoiceGroupList;
  mainInvoice;
  invoicesGroup;
  groupingModal = false;
  invoiceForGrouping;
  showRemoveCavaliButton = false;
  showUploadToCavaliButton = false;
  scheduleInvoice;
  openModalSchedule = false;
  mode;
  selected = 0;
  investments = [];
  announcements = [];
  announcementDetail;
  openModalComment = false;
  confirmationMode = '';
  originalMessage;
  openModalResend = false;
  dataPointsOperation = DataPointsOperation;
  dataPointsCostDetail = DataPointsCostDetail;
  scrollSize = 0;
  isScrollIndicatorVisible = false;
  showMotiveDialog = false;
  rejectionReason = '';
  lastConfiguration;
  numberCopied = new BehaviorSubject<any>(false);
  numberCopiedDebtor = new BehaviorSubject<any>(false);
  warrantyCheck = false;
  showAcceptButton = false;
  conditions;
  setConditionsModal = false;
  invoiceNewStatus;
  hasProgressSection = false;
  isMoreDetailOpen = true;
  activePanel = '';
  bubbleOpened = '';
  invoiceTab = 0;
  deploymentDate = environment.deploymentDate;
  isPageFirstLoaded = false;
  showDownloadSummarySheet;
  constructor(
    private toaster: Toaster,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    public dialog: MatDialog,
    public service: InvoiceService,
    private bankAccountService: BankAccountsService,
    private spinner: NgxSpinnerService,
    private shortenPipe: ShortenPipe,
    private currencyNamePipe: CurrencyNamePipe,
    private location: Location,
    private router: Router,
    public supplierService: SuplierService,
    private gtmService: GTMService,
    private windowScrollService: WindowScrollService,
    private clipboard: Clipboard,
    private socketService: SocketService,
    private cdref: ChangeDetectorRef,
    private mixpanelService: MixpanelService,
    private hotjarService: HotjarService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.selectedInvoiceId = params.invoiceId;
      this.getRoleUser();

      this.store
        .select(UsersSelectors.profile())
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((loggedUser) => {
          if (loggedUser) {
            // console.log(loggedUser);
            this.showDownloadSummarySheet = loggedUser.showDownloadSummarySheet;
          }
        });
      if (
        this.loggedInUserRole === Roles.ADMIN ||
        this.loggedInUserRole === Roles.SUPER_ADMIN ||
        this.loggedInUserRole === Roles.CLIENT_HAPPINESS
      ) {
        this.fetchInvoice(this.selectedInvoiceId);
      } else {
        this.store
          .select(InvoiceSelectors.byId(params.invoiceId))
          .subscribe((data) => {
            this.selectedInvoice = data;
            this.hasProgressSection =
              this.selectedInvoice?.status != 'collected' &&
              this.selectedInvoice?.status != 'finalized' &&
              this.selectedInvoice?.status != 'disapproved';

            if (
              this.selectedInvoice ||
              this.loggedInUserRole === Roles.CLIENT
            ) {
              if (
                this.isStatusInProcess(this.selectedInvoice.status) &&
                !this.selectedInvoice.advancePaymentDate
              ) {
                this.invoiceTab = 0;
              } else if (
                this.selectedInvoice.status !== 'collected' &&
                this.selectedInvoice.status !== 'finalized' &&
                this.selectedInvoice.status !== 'disapproved' &&
                this.selectedInvoice.advancePaymentDate
              ) {
                this.invoiceTab = 1;
              } else if (
                this.selectedInvoice.status === 'collected' ||
                this.selectedInvoice.status === 'finalized'
              ) {
                this.invoiceTab = 2;
              } else if (this.selectedInvoice.status === 'disapproved') {
                this.invoiceTab = 3;
              }

              if (!this.isPageFirstLoaded) {
                const eventData: any = {
                  page_url: environment.clientUrl + this.router.url,
                  page_title: 'Operation',
                  section_step: 'Detail',
                  active_tab: `Operation - ${this.getTabName(this.invoiceTab)}`,
                  operation_id: this.selectedInvoice._id,
                };

                this.mixpanelService.newEvent('page_view', eventData);

                this.isPageFirstLoaded = true;
              }
            }
          });
      }

      this.subscription = this.overlayService
        .getCloseEmitter()
        .subscribe((obs) => {
          if (this.sendEmail) {
            let data;
            data = {
              type: this.dataEmail.type,
              isExternal: this.dataEmail.isExternal,
              currency: this.dataEmail.currency,
              cc: this.dataEmail.cc,
              to: this.dataEmail.to,
            };
            if (!this.dataEmail.isExternal) {
              data = {
                type: this.dataEmail.type,
                isExternal: this.dataEmail.isExternal,
                currency: this.dataEmail.currency,
                cc: this.dataEmail.cc,
                file: this.dataEmail.file,
                notes: this.dataEmail.notes,
                to: this.dataEmail.to,
                observations: obs,
              };
            }

            this.getRoleUser();
            if (
              (this.loggedInUserRole === Roles.ADMIN ||
                this.loggedInUserRole === Roles.SUPER_ADMIN) &&
              this.router.url === '/invoices/' + this.selectedInvoiceId
            ) {
              this.send(data);
            }
          }

          if (
            this.setConditionsModal &&
            (this.loggedInUserRole === Roles.ADMIN ||
              this.loggedInUserRole === Roles.SUPER_ADMIN)
          ) {
            this.configureInvoice(
              this.conditions?.tdmPercentage,
              this.conditions?.reservationPercentage,
              this.conditions?.distributionPercentage
            );
          }

          if (
            this.groupingModal &&
            (this.loggedInUserRole === Roles.ADMIN ||
              this.loggedInUserRole === Roles.SUPER_ADMIN)
          ) {
            //this.groupingInvoices(this.mainInvoice, this.invoiceForGrouping)
            this.showDialogGroup(
              this.mainInvoice,
              this.invoicesGroup,
              this.invoiceForGrouping
            );
          }
          if (
            this.openModalSchedule &&
            (this.loggedInUserRole === Roles.ADMIN ||
              this.loggedInUserRole === Roles.SUPER_ADMIN)
          ) {
            this.putInvoiceForSale(this.selectedInvoice, this.scheduleInvoice);
          }

          if (
            this.openModalComment &&
            this.loggedInUserRole === Roles.CLIENT_HAPPINESS
          ) {
            if (this.confirmationMode === 'save') {
              this.newAnnouncement(
                this.selectedInvoice,
                this.announcementDetail
              );
            } else {
              this.editAnnouncement(this.announcementDetail, 'return');
            }
          }

          if (
            this.openModalResend &&
            this.loggedInUserRole === Roles.CLIENT_HAPPINESS
          ) {
            this.resendAnnouncement(this.announcementDetail);
          }

          if (this.showMotiveDialog) {
            this.rejectInvoice();
          }
        });
    });

    this.route.queryParams.subscribe((params) => {
      if (params.template_message) {
        const eventData: any = {
          template_message: params.template_message,
        };

        this.mixpanelService.newEvent('Whatsapp link clicked', eventData);
      }
    });

    this.editDistributionForm = this.fb.group({
      newDistribution: ['', [Validators.required, TEAValidation(18)]],
    });

    this.form = this.fb.group({
      paymentDate: ['', Validators.required],
    });

    this.formbank = this.fb.group({
      bankAccount: ['', Validators.required],
    });

    this.formbankSupplier = this.fb.group({
      bankAccountSupplier: ['', Validators.required],
    });

    this.isScrollIndicatorVisible =
      this.selectedInvoice?.status === 'configured';

    this.socketService.socketEvent.subscribe((event) => {
      if (event == 'update_operation') {
        this.fetchInvoice(this.selectedInvoiceId);
      }

      if (event == 'update_client') {
        this.store.dispatch(InvoicesActions.LoadAll());
      }
    });
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.windowScrollService.scrollY.subscribe((e) => {
        this.scrollSize = e;
      });
    }, 1000);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!event.target?.className.includes('bubble') && this.bubbleOpened) {
      this.bubbleOpened = '';
    }
  }

  getUserStatus() {
    this.store
      .select(UsersSelectors.profileProperty('status'))
      .pipe(take(1))
      .subscribe((status) => (this.loggedInUserStatus = status));
  }

  getRoleUser() {
    this.store
      .select(UsersSelectors.profileProperty('role'))
      .pipe(take(1))
      .subscribe((role) => (this.loggedInUserRole = role));
  }

  getObservations(data) {
    const clientId = data?.issuer ? data.issuer._id : data;

    this.service.getObservations(clientId).subscribe((res) => {
      if (res) {
        this.userObservations = res;
      }
    });
  }

  setBankAccountList(invoice) {
    // this.service.byId(this.selectedInvoiceId).subscribe((res: any) => {

    // });
    this.bankAccountService
      .getAllByUserId(invoice.issuer._id)
      .subscribe((result) => {
        this.bankAccounts = result.bankAccounts
          .map((bankAccount) => ({
            ...bankAccount,
            bankSummary: `    
            ${bankAccount.name} 
            ${this.currencyNamePipe.transform(bankAccount.currency)}  
            ${this.shortenPipe.transform(bankAccount.number)}`,
          }))
          .filter((bankAccount) => bankAccount.currency === invoice.currency);
        this.selectedBankAccount = this.bankAccounts.filter(
          (bankAccount) => bankAccount._id === invoice.bankAccount._id
        )[0];
      });
  }

  setBankAccountListSupplier(invoice) {
    this.supplierService
      .getBanksAdmin(invoice.supplier._id, invoice.issuer._id)
      .subscribe((result) => {
        this.bankAccountsSupplier = result.bankAccounts
          .map((bankAccount) => ({
            ...bankAccount,
            bankSummary: `    
          ${bankAccount.name} 
          ${this.currencyNamePipe.transform(bankAccount.currency)}  
          ${this.shortenPipe.transform(bankAccount.number)}`,
          }))
          .filter((bankAccount) => bankAccount.currency === invoice.currency);
        this.selectedBankAccountSupplier = this.bankAccountsSupplier.filter(
          (bankAccount) => bankAccount._id === invoice.supplierBankAccount._id
        )[0];
      });
  }

  setSelectedBankAccount() {
    this.service.byId(this.selectedInvoiceId).subscribe((res: any) => {
      this.selectedBankAccount = this.bankAccounts.filter(
        (bankAccount) => bankAccount._id === res.bankAccount._id
      )[0];
      this.reFetchInvoice.bind(this);
    });
  }

  setSelectedBankAccountSupplier() {
    this.service.byId(this.selectedInvoiceId).subscribe((res: any) => {
      this.selectedBankAccountSupplier = this.bankAccountsSupplier.filter(
        (bankAccount) => bankAccount._id === res.supplierBankAccount._id
      )[0];
      this.reFetchInvoice.bind(this);
    });
  }

  changeBankAccount(e) {
    if (e && e._id !== this.selectedBankAccount?._id) {
      this.overlayService.startConfirmation(
        { bankAccount: e._id, _id: this.selectedInvoiceId },
        {
          component: BankAccountItemOrganism,
          entity: e,
        },
        'details',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'Cuenta bancaria actualizada',
          },
        },
        this.setSelectedBankAccount.bind(this),
        true
      );
    }
  }

  changeBankSupplier(e) {
    if (e && e._id !== this.selectedBankAccountSupplier._id) {
      if (e._id !== this.selectedBankAccountSupplier._id) {
        this.overlayService.startConfirmation(
          { supplierBankAccount: e._id, _id: this.selectedInvoiceId },
          {
            component: BankAccountItemOrganism,
            entity: e,
          },
          'details',
          'edit',
          null,
          InvoicesActions.Saving,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Cuenta bancaria actualizada',
            },
          },
          this.setSelectedBankAccountSupplier.bind(this)
        );
      }
    }
  }
  //invoiceAlreadyGrouped? is when we re open the modal again to show the last invoices already grouped
  groupingInvoices(mainInvoice, invoiceAlreadyGrouped?) {
    let filterDefault;
    if (mainInvoice.isConfirming) {
      filterDefault = {
        statuses: [mainInvoice.status],
        supplier: mainInvoice.supplier?._id,
        debtor: mainInvoice.debtor?._id,
        issuer: mainInvoice.issuer?._id,
        currency: mainInvoice.currency,
        isConfirming: mainInvoice.isConfirming,
        isSunatProcess: mainInvoice.isSunatProcess,
      };
    } else {
      filterDefault = {
        statuses: [mainInvoice.status],
        debtor: mainInvoice.debtor?._id,
        issuer: mainInvoice.issuer?._id,
        currency: mainInvoice.currency,
        isConfirming: mainInvoice.isConfirming,
        isSunatProcess: mainInvoice.isSunatProcess,
      };
    }

    if (mainInvoice.isSunatProcess) {
      filterDefault.paymentDate = mainInvoice.paymentDate;
    }

    this.service
      .getGroupInvoice(filterDefault, mainInvoice)
      .subscribe((res: any) => {
        if (res) {
          const actualInvoices = res.entities;
          const invoicesGroup = actualInvoices
            .filter((inv) => inv._id != mainInvoice._id)
            .filter((inv) => {
              if (mainInvoice.checklist && inv.checklist) {
                //risk checks
                const mainInvoiceDetail = mainInvoice.checklist
                  .find((i) => i.type === 'risk')
                  .subChecks.find((s) => s.type === 'invoice detail');
                const mainInvoiceRisk = mainInvoice.checklist
                  .find((i) => i.type === 'risk')
                  .subChecks.find((s) => s.type === 'accepted risk');

                const invoiceDetail = inv.checklist
                  .find((i) => i.type === 'risk')
                  .subChecks.find((s) => s.type === 'invoice detail');
                const invoiceRisk = inv.checklist
                  .find((i) => i.type === 'risk')
                  .subChecks.find((s) => s.type === 'accepted risk');

                //warranty check
                let warrantyStatus = true;
                if (mainInvoice.isConfirming) {
                  const mainWarrantyCheck = mainInvoice.checklist.find(
                    (i) => i.type === 'warranty'
                  );
                  const warrantyCheck = inv.checklist.find(
                    (i) => i.type === 'warranty'
                  );

                  warrantyStatus =
                    mainWarrantyCheck.status === warrantyCheck.status;
                }

                //cavali checks
                if (mainInvoice.isSunatProcess) {
                  const mainEmailResponseCheck = mainInvoice.checklist
                    .find((i) => i.type === 'cavali')
                    .subChecks.find((s) => s.type === 'email response');
                  const mainDateAmountCheck = mainInvoice.checklist
                    .find((i) => i.type === 'cavali')
                    .subChecks.find((s) => s.type === 'date amount');

                  const emailResponseCheck = inv.checklist
                    .find((i) => i.type === 'cavali')
                    .subChecks.find((s) => s.type === 'email response');
                  const dateAmountCheck = inv.checklist
                    .find((i) => i.type === 'cavali')
                    .subChecks.find((s) => s.type === 'date amount');

                  if (
                    mainEmailResponseCheck?.status ===
                      emailResponseCheck?.status &&
                    mainDateAmountCheck?.status === dateAmountCheck?.status &&
                    mainInvoiceDetail?.status === invoiceDetail?.status &&
                    mainInvoiceRisk?.status === invoiceRisk?.status &&
                    warrantyStatus
                  ) {
                    return inv;
                  }
                } else {
                  if (
                    mainInvoiceDetail?.status === invoiceDetail?.status &&
                    mainInvoiceRisk?.status === invoiceRisk?.status &&
                    warrantyStatus
                  ) {
                    return inv;
                  }
                }
              } else {
                return inv;
              }
            });

          this.showDialogGroup(
            mainInvoice,
            invoicesGroup,
            invoiceAlreadyGrouped
          );
        }
      });
  }

  showDialogGroup(mainInvoice, invoicesGroup, invoiceAlreadyGrouped?) {
    this.mainInvoice = mainInvoice;
    this.invoicesGroup = invoicesGroup;
    const dialogRef = this.dialog.open(GroupingInvoiceComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      minWidth: '100vw',
      height: '100%',
      autoFocus: false,
      position: {
        left: '0',
      },
      data: { mainInvoice, invoicesGroup, invoiceAlreadyGrouped },
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.groupingModal = true;
        this.invoiceForGrouping = result;

        //to send to InvoiceGroupConfirmationComponent all the codes to display in table
        const physicalInvoices = this.invoiceForGrouping
          .map((inv) => inv.physicalInvoices)
          .reduce((acc, val) => {
            if (acc) {
              return [...acc, ...val];
            }
          });

        const groupList = result;

        //to pass to the InvoiceGroupConfirmationComponent
        const invoices = {
          groupList,
          mainInvoice,
          physicalInvoices,
        };

        //to send to the endpoint only the ids to group without the main one
        const invoiceIds = this.invoiceForGrouping
          .filter((inv) => inv._id != mainInvoice._id)
          .map((inv) => {
            return { _id: inv._id };
          });

        //to send to the endpoint
        const entity = {
          invoiceIds,
          _id: this.selectedInvoiceId,
        };

        const callbackGrouping = () => {
          this.reFetchInvoice();
          this.resetGrouping();
        };

        this.overlayService.startConfirmation(
          entity,
          {
            component: InvoiceGroupConfirmationComponent,
            entity: invoices,
          },
          'details',
          'edit',
          null,
          InvoicesActions.Saving,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Ajuste realizado',
            },
            onConfirm: {
              title: '¿Estás seguro?',
              message: 'Este es el detalle de la nueva agrupación:',
            },
          },
          callbackGrouping.bind(this),
          '60%'
        );
      } else {
        this.groupingModal = false;
      }
    });
  }

  openDialogHelper(dialogComp, data?, autoFocus?, width?) {
    let focus = autoFocus == false ? false : true;
    let fixedWidth = width ?? '';
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      width: fixedWidth,
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: focus,
      data: data,
    });

    return dialogRef;
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  resetGrouping() {
    this.groupingModal = false;
  }

  getInvestedPercentage(availableBalancePercentage) {
    availableBalancePercentage = availableBalancePercentage
      ? availableBalancePercentage
      : 100.0;
    return Big(100).minus(availableBalancePercentage).toFixed(2);
  }

  approveInvoice() {
    this.overlayService.startConfirmation(
      { status: 'approved', _id: this.selectedInvoiceId },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La factura se ha aprobado',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  configureInvoice(tdm?, reservation?, tea?) {
    let dialogRef = this.openDialogHelper(ConfigureInvoiceDialogComponent, {
      tdm,
      reservation,
      tea,
      _id: this.selectedInvoiceId,
    });
    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.conditions = result;
        this.setConditionsModal = true;
        this.service
          .getCostValues(this.selectedInvoiceId, result)
          .subscribe((data: any) => {
            if (data) {
              const callback = () => {
                this.reFetchInvoice();
                this.resetFlag();
              };
              this.overlayService.startConfirmation(
                {
                  ...result,
                  _id: this.selectedInvoiceId,
                  homeUrl: environment.clientUrl,
                },
                {
                  component: ConfirmatSetConditionsComponent,
                  entity: data,
                },
                'details',
                'edit',
                null,
                InvoicesActions.Saving,
                `invoices/${this.selectedInvoiceId}`,
                InvoiceSelectors.savingSuccessStateById,
                {
                  onSuccess: {
                    showCheck: true,
                    title: 'La factura se ha configurado correctamente',
                    messsage:
                      'La compañía emisora ha sido notificada que su financiamiento de la factura está pendiente de su aceptación.',
                  },
                },
                callback.bind(this),
                '90%'
              );
            }
          });
      }
    });
  }
  resetFlag() {
    this.setConditionsModal = false;
  }
  rejectInvoice() {
    const dialogRef = this.openDialogHelper(
      MotiveDialogComponent,
      {
        data: this.rejectionReason,
        type: 'invoice',
      },
      false
    );

    this.connectDialogListeners(dialogRef, (result) => {
      if (result?.event == 'cancel' || !result) {
        this.showMotiveDialog = false;
        this.rejectionReason = '';
        return;
      }
      this.showMotiveDialog = true;
      this.rejectionReason = result?.data;
      this.overlayService.startConfirmation(
        {
          status: 'disapproved',
          _id: this.selectedInvoiceId,
          rejectionReason: this.rejectionReason,
        },
        {
          component: InvoiceMotiveConfirmationComponent,
          entity: { rejectionReason: this.rejectionReason },
        },
        'details',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'La factura se ha desaprobado',
          },
        },
        this.reFetchInvoice.bind(this)
      );
    });
  }

  revertConfiguration() {
    this.overlayService.startConfirmation(
      { status: 'approved', _id: this.selectedInvoiceId },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La configuración se ha revertido',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }
  revertInvoiceToPDC() {
    this.overlayService.startConfirmation(
      { status: 'pending confirmation', _id: this.selectedInvoiceId },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La factura se ha puesto en pendiente de conformidad.',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  cancelSale() {
    this.overlayService.startConfirmation(
      { status: 'pending confirmation', _id: this.selectedInvoiceId },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La factura se ha retirado',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  isConfirmingChanged(isConfirming) {
    this.overlayService.startConfirmation(
      { isConfirming: isConfirming, _id: this.selectedInvoiceId },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La factura se ha actualizado',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  putInvoiceForSale(invoice, result?) {
    if (
      invoice.checklist &&
      invoice.status === 'pending confirmation' &&
      invoice.checklist.every(
        (c) => c.status == 'approved' || c.status == 'exception'
      )
    ) {
      let dialogRef = this.openDialogHelper(
        InvoiceForSaleDialogComponent,
        invoice,
        false
      );

      this.connectDialogListeners(dialogRef, (result: any) => {
        if (result) {
          let invoicesTelgram = [this.selectedInvoice];

          if (result.selectedInvoices && result.selectedInvoices.length)
            invoicesTelgram = invoicesTelgram.concat(result.selectedInvoices);

          this.service
            .getTelegramMessage(invoicesTelgram, result.onSaleTime)
            .subscribe((data: any) => {
              this.scheduleInvoice = result;
              this.openModalSchedule = true;

              result.telegramMessage = data.telegram;

              if (result.event === 'Cancel') {
                this.deactivateModalSchedule();
                return;
              }

              const callbackSchedule = () => {
                this.reFetchInvoice();
                this.deactivateModalSchedule();
              };

              this.overlayService.startConfirmation(
                {
                  status: 'for sale',
                  _id: this.selectedInvoiceId,
                  onSaleDate: result.onSaleDate,
                  onSaleSlot: result.onSaleSlot,
                  invoiceIds: result.selectedInvoices?.map((inv) => {
                    return { _id: inv._id };
                  }),
                  telegramMessage: result.telegramMessage,
                },
                {
                  component: ConfirmInvoiceForSaleComponent,
                  entity: { ...this.selectedInvoice, ...result },
                },
                'details',
                'edit',
                null,
                InvoicesActions.Saving,
                `invoices/${this.selectedInvoiceId}`,
                InvoiceSelectors.savingSuccessStateById,
                {
                  onSuccess: {
                    showCheck: true,
                    title: 'La factura ha sido enviada a las oportunidades.',
                  },
                  onConfirm: {
                    title: '¿Estás seguro?',
                  },
                },
                callbackSchedule.bind(this)
              );
            });
        } else {
          this.openModalSchedule = false;
        }
      });
    }
  }

  payAdvanceToClient(invoice) {
    if (
      invoice.checklist &&
      invoice.checklist.every(
        (c) => c.status == 'approved' || c.status == 'exception'
      )
    ) {
      let dialogRef = this.openDialogHelper(PayClientAdvanceDialogComponent);
      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          this.overlayService.startConfirmation(
            { ...result, _id: this.selectedInvoiceId },
            null,
            'resultOnly',
            'edit',
            null,
            InvoicesActions.Saving,
            `invoices/${this.selectedInvoiceId}`,
            InvoiceSelectors.savingSuccessStateById,
            null,
            this.reFetchInvoice.bind(this)
          );
        }
      });
    }
  }
  copyToClipboard(data) {
    this.clipboard.copy(data);
    this.numberCopied.next(true);
    setTimeout(() => {
      this.numberCopied.next(false);
    }, 1000);
  }

  copyToClipboardDebtor(data) {
    this.clipboard.copy(data);
    this.numberCopiedDebtor.next(true);
    setTimeout(() => {
      this.numberCopiedDebtor.next(false);
    }, 1000);
  }

  collectInvoice(paymentDate) {
    let dialogRef = this.openDialogHelper(CollectInvoiceDialogComponent, {
      paymentDate,
    });
    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.overlayService.startConfirmation(
          { ...result, _id: this.selectedInvoiceId },
          null,
          'resultOnly',
          'edit',
          null,
          InvoicesActions.Saving,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          null,
          this.reFetchInvoice.bind(this)
        );
      }
    });
  }

  finalizeInvoice() {
    this.overlayService.startConfirmation(
      {
        status: 'finalized',
        _id: this.selectedInvoiceId,
        homeUrl: environment.clientUrl,
      },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La factura ha sido finalizada',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  acceptInvoiceConfiguredRates(invoice) {
    this.getUserStatus();
    if (this.loggedInUserStatus == UserStatus['Email Verified']) {
      showToast(
        this.toaster,
        'Tu cuenta aún está pendiente de la aprobación del administrador. Comunícate con tu especialista para más información.',
        false
      );
    } else {
      this.overlayService.startConfirmation(
        { status: 'pending confirmation', _id: this.selectedInvoiceId },
        {
          component: ReviewRatesComponent,
          entity: invoice,
        },
        'details',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'Condiciones aceptadas',
          },
          onConfirm: {
            title: '¿Estás seguro?',
          },
        }
      );
    }
  }

  downloadInvoice(invoiceId) {
    this.spinner.show();

    this.service.downloadInvoiceFiles(invoiceId).subscribe((result) => {
      if (result.file) {
        const fileName = `invoice_${new Date().toISOString()}_${
          this.selectedInvoiceId
        }.zip`;
        saveAs(result.file, fileName);
      } else {
        window.alert('¡No se encuentra el archivo!');
      }

      this.spinner.hide();
    });
  }

  reFetchInvoice() {
    if (this.loggedInUserRole === Roles.CLIENT_HAPPINESS) {
      this.openModalComment = false;
    }
    this.fetchInvoice(this.selectedInvoiceId);
  }

  fetchInvoice(invoiceId) {
    this.rejectionReason = '';
    this.showMotiveDialog = false;
    this.service.getConfiguration(invoiceId).subscribe((res) => {
      this.lastConfiguration = res;
    });

    this.service.byId(invoiceId).subscribe((data: any) => {
      this.selectedInvoice = data;
      this.checkCavali(data);
      if (this.loggedInUserRole !== Roles.CLIENT_HAPPINESS) {
        this.getObservations(this.selectedInvoice);
        this.setBankAccountList(data);
        if (data.isConfirming) {
          this.setBankAccountListSupplier(data);
        }
      } else {
        this.checkShowingAcceptButton();
        this.setMode();
        this.getInvestorsList(data);
        this.getAnnouncementsList(data);
      }

      if (this.selectedInvoice.isConfirming && this.selectedInvoice.checklist) {
        this.warrantyCheck =
          this.selectedInvoice.checklist.find((c) => c.type == 'warranty')
            ?.status == 'pending';
      }
    });
  }
  getInvestorsList(data) {
    this.service.getInvestors(data._id).subscribe((data) => {
      if (data) {
        this.investments = data;
      }
    });
  }

  getAnnouncementsList(data) {
    this.service.getAnnouncements(data._id).subscribe((res) => {
      if (res) {
        this.announcements = res;
      }
    });
  }

  setMode() {
    if (
      this.selectedInvoice.status !== 'finalized' &&
      this.selectedInvoice.toBeCollectedIn !== 'En mora'
    ) {
      this.mode = 'actual';
    } else if (
      this.selectedInvoice.status !== 'finalized' &&
      this.selectedInvoice.toBeCollectedIn === 'En mora'
    ) {
      this.mode = 'delayed';
    } else if (this.selectedInvoice.status === 'finalized') {
      this.mode = 'historic';
    }
  }

  checkCavali(invoice) {
    // invoice.physicalInvoices.map(inv => {
    // if ((inv.cavaliStatus === 'in progress')) {
    //   this.showRemoveCavaliButton = true
    // }

    // if ((inv.cavaliStatus && inv.cavaliProcessId && (inv.cavaliStatus === 'loading' || inv.cavaliStatus === 'error' || inv.cavaliStatus === 'withdrawn') && !inv.hasOwnProperty('cavaliPayerResponse')) || (!inv.hasOwnProperty('cavaliStatus') && !inv.hasOwnProperty('cavaliProcessId'))) {
    //   this.showUploadToCavaliButton = true
    // }

    this.showRemoveCavaliButton = invoice.physicalInvoices.some(
      (inv) => inv.cavaliStatus === 'in progress'
    );
    this.showUploadToCavaliButton = invoice.physicalInvoices.some(
      (inv) => inv.cavaliStatus !== 'in progress'
    );
  }

  openDatePicker() {
    this.editPaymentFlag = !this.editPaymentFlag;
  }

  openDistributionEditer() {
    this.editDistributionFlag = !this.editDistributionFlag;
  }

  openEditInvoiceModal(totalAmount, retentionAmount, code) {
    let dialogRef = this.openDialogHelper(EditAmountsInvoceDialogComponent, {
      totalAmount,
      retentionAmount,
      code,
    });
    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.overlayService.startConfirmation(
          { ...result, _id: this.selectedInvoiceId },
          null,
          'resultOnly',
          'edit',
          null,
          InvoicesActions.Saving,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          null,
          this.reFetchInvoice.bind(this)
        );
      }
    });
  }

  updateDistribution() {
    if (this.editDistributionForm.valid) {
      const distribution = this.getDistribution(
        this.editDistributionForm.value.newDistribution,
        this.selectedInvoice.tdmPercentage
      );

      this.overlayService.startConfirmation(
        {
          teaPercentage: this.editDistributionForm.value.newDistribution,
          _id: this.selectedInvoiceId,
        },
        null,
        'resultOnly',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'La factura se ha actualizado',
          },
        },
        this.reFetchInvoice.bind(this)
      );
      this.editDistributionFlag = !this.editDistributionFlag;
    }
  }

  acceptWarranty() {
    this.overlayService.startConfirmation(
      {
        warrantyCheck: true,
        _id: this.selectedInvoice._id,
      },
      null,
      'details',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onConfirm: {
          showCheck: true,
          title: 'Confirmación de modificación',
        },
        onSuccess: {
          showCheck: true,
          title: 'Modificación realizada',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  updatePaymentDate() {
    if (this.form.valid) {
      this.overlayService.startConfirmation(
        {
          paymentDate: this.form.value.paymentDate,
          _id: this.selectedInvoiceId,
        },
        null,
        'resultOnly',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'La factura se ha actualizado',
          },
        },
        this.reFetchInvoice.bind(this)
      );
      this.editPaymentFlag = !this.editPaymentFlag;
    }
  }

  addInvoiceUngroupList(code) {
    if (!this.invoiceUngroupList.includes(code)) {
      this.invoiceUngroupList.push(code);
    } else {
      this.invoiceUngroupList = this.invoiceUngroupList.filter(
        (item) => item !== code
      );
    }
  }

  ungroupInvoices(invoice) {
    const physicalInvoices = invoice.physicalInvoices.filter((inv): any =>
      this.invoiceUngroupList.includes(inv.code)
    );

    const entity = {
      codes: this.invoiceUngroupList.toString(),
      _id: this.selectedInvoiceId,
      physicalInvoices,
    };

    const resultConfig = {
      onConfirm: {
        title: 'Estás a punto de desagrupar las siguientes facturas',
      },
      onSuccess: {
        showCheck: true,
        title: 'Se desagrupó la factura',
      },
      hasServerValue: true,
    };

    const callback = () => {
      this.reFetchInvoice();
      this.deactivateUngrouping();
    };

    this.overlayService.startConfirmation(
      entity,
      PhysicalInvoiceTableOrganism,
      'details',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${invoice._id}`,
      InvoiceSelectors.savingSuccessStateById,
      resultConfig,
      callback.bind(this)
    );
  }

  activateUngrouping(invoice) {
    this.actionType = 'ungroup';
  }

  deactivateUngrouping() {
    this.actionType = 'edit';
    this.invoiceUngroupList = [];
  }

  deactivateModalSchedule() {
    this.openModalSchedule = false;
  }
  getTotalAmountFactoring(value1, value2, value3) {
    this.totalFactoring =
      parseFloat(value1) + parseFloat(value2) + parseFloat(value3);
    return this.totalFactoring.toFixed(2);
  }

  getSalePrice(comission) {
    let totalSale = parseFloat(comission) * 0.18 + parseFloat(comission);
    return totalSale.toFixed(2);
  }

  goBack() {
    let event = {
      event: '[Platform][Contact][Cancel]',
      _inputName: 'Clicked Regresar In Detail',
      _inputCategory: 'Invoice Review / Detail',
    };
    this.gtmService.newEvent(event);
    if (
      this.loggedInUserRole === 'admin' ||
      this.loggedInUserRole === 'client happiness'
    ) {
      if (this.location.back() === undefined) {
        this.router.navigateByUrl('/operations');
      } else {
        this.location.back();
      }
    } else {
      this.location.back();
    }
  }

  navigateToInvest(invoice: any) {
    const navigationExtra: NavigationExtras = {
      state: { invoice },
    };

    this.router.navigate(['/financial-transactions/invest'], navigationExtra);
  }

  openEmailPanel() {
    this.emailPanel = !this.emailPanel;
    if (!this.emailPanel) this.getObservations(this.selectedInvoice);
    this.groupingModal = false;
  }

  openObsPanel() {
    this.observationPanel = !this.observationPanel;
    this.groupingModal = false;
    this.updateAllObs(false);
  }

  send($event) {
    if ($event.type) {
      let data = {
        type: $event.type,
        isExternal: $event.isExternal,
        file: $event.file,
        notes: $event.notes,
        _id: this.selectedInvoiceId,
        currency: this.selectedInvoice.currency,
        to: $event.sendTo,
        cc: $event.cc,
        subject: $event.subject,
        observations: $event.observations,
      };
      // data = this.dataEmail ? this.dataEmail : data;
      let dialogRef = this.openDialogHelper(EmailBoDialogComponent, {
        data: this.dataEmail ? this.dataEmail : data,
      });

      dialogRef.beforeClosed().subscribe((res) => {
        this.dataEmail = '';
      });

      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          if (result.event === 'Cancel') {
            this.dataEmail = '';
            return;
          }
          if (this.dataEmail) {
            this.dataEmail.cc = result.cc;
            this.dataEmail.to = result.to;
          }
          this.sendEmail = true;
          this.dataEmail = this.dataEmail ? this.dataEmail : result;
          const callbackEmail = () => {
            this.reFetchInvoice();
            this.resetEmailSend();
          };

          result.isConfirming = this.selectedInvoice.isConfirming;
          this.overlayService.startConfirmation(
            { result: result, _id: this.selectedInvoiceId },
            {
              component: EmailPreviewComponent,
              entity: result,
            },
            'details',
            'edit',
            null,
            InvoicesActions.SavingEmail,
            `invoices/${this.selectedInvoiceId}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: 'Correo enviado!',
              },
              onConfirm: {
                title: EmailType[$event.type],
              },
            },
            callbackEmail.bind(this),
            '60%',
            'Enviar',
            'Regresar'
          );
        }
      });
    }
  }

  resetEmailSend() {
    this.sendEmail = !this.sendEmail;
    this.dataEmail = '';
  }
  addObservation(invoice) {
    let data = {
      invoiceId: this.selectedInvoiceId,
      userId: invoice.issuer._id,
    };
    let dialogRef = this.openDialogHelper(NewObservationDialogComponent, {
      data: data,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.service.saveObservations(result).subscribe((data) => {
          if (data) {
            this.getObservations(result.client);
          }
        });
      }
    });
  }
  activeObservation(obs) {
    obs.isDone = !obs.isDone;
    this.updateObservation(obs);
  }

  setIGV(obs) {
    obs.isTaxable = !obs.isTaxable;
    this.updateObservation(obs);
  }

  deleteObs(obs) {
    obs.isDeleted = true;
    this.overlayService.startConfirmation(
      { _id: this.selectedInvoiceId, ...obs },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.DeleteObs,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'Se ha eliminado la observación',
        },
      },
      this.updateAllObs.bind(this)
    );
  }

  updateAllObs(shouldWait = true) {
    if (shouldWait) {
      setTimeout(() => {
        this.getObservations(this.selectedInvoice.issuer._id);
      }, 700);
    } else {
      this.getObservations(this.selectedInvoice.issuer._id);
    }
  }

  updateObservation(obs) {
    this.service.updateObservation(obs).subscribe((data) => {
      if (data) {
        this.getObservations(obs.client);
      }
    });
  }

  uploadToCavali(invoice) {
    // this.iconClass = 'loader-icon';

    const codes = invoice.physicalInvoices
      .filter((inv) => {
        if (
          !inv.hasOwnProperty('cavaliStatus') ||
          (inv.hasOwnProperty('cavaliStatus') &&
            (inv.cavaliStatus === 'error' || inv.cavaliStatus === 'withdrawn'))
        ) {
          return inv;
        }
      })
      .map((inv): any => inv.code)
      .toString();

    const entity = {
      _id: this.selectedInvoiceId,
      codes,
    };

    const resultConfig = {
      onConfirm: {
        title: '¿Estás seguro?',
      },
      onSuccess: {
        showCheck: true,
        title: 'Modificación realizada',
      },
    };

    const callback = () => {
      this.reFetchInvoice();
      this.finishLoading();
    };

    this.overlayService.startConfirmation(
      entity,
      InvoiceToCavaliComponent,
      'details',
      'create',
      null,
      InvoicesActions.SendingCavali,
      `invoices/${invoice._id}`,
      InvoiceSelectors.savingSuccessStateById,
      resultConfig,
      callback.bind(this)
    );
  }

  finishLoading() {
    this.iconClass = 'upload-icon';
  }

  openCavaliModal(code) {
    let dialogRef = this.openDialogHelper(CavaliResponseDialogComponent, {
      invoiceId: this.selectedInvoiceId,
      code: code,
    });
    // dialogRef.close((result) => {
    //   if (result) {
    //     if (result.action === 'upload') {
    //       this.uploadAnInvoiceToCavali(result.code)
    //     } else if (result.action === 'remove') {
    //       this.removeAnInvoiceFromCavali(result.code)
    //     }
    //   }
    // })
    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        if (result.action === 'upload') {
          this.uploadAnInvoiceToCavali(result.code);
        } else if (result.action === 'remove') {
          this.removeAnInvoiceFromCavali(result.code);
        }
      }
    });
  }

  uploadAnInvoiceToCavali(physicalInvoice) {
    // this.iconClass = 'loader-icon';

    const codes = [physicalInvoice.code].toString();

    const entity = {
      _id: this.selectedInvoiceId,
      codes,
    };

    const resultConfig = {
      onConfirm: {
        title: '¿Estás seguro?',
      },
      onSuccess: {
        showCheck: true,
        title: 'Modificación realizada',
      },
    };

    const callback = () => {
      this.reFetchInvoice();
      this.finishLoading();
    };

    this.overlayService.startConfirmation(
      entity,
      InvoiceToCavaliComponent,
      'details',
      'create',
      null,
      InvoicesActions.SendingCavali,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      resultConfig,
      callback.bind(this)
    );
  }

  gotoSlack(invoice) {
    let url = invoice.slackDirectUrl;
    window.open(url, '_blank');
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.groupingModal = false;
    this.openModalSchedule = false;
    this.openModalComment = false;

    this.subscription.unsubscribe();
    this.resetEmailSend();
    this.groupingModal = false;
    this.openModalSchedule = false;
    this.openModalComment = false;

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  removeFromCavali(invoice) {
    const codes = invoice.physicalInvoices
      .filter((inv) => {
        if (inv.cavaliStatus === 'in progress') {
          return inv;
        }
      })
      .map((inv): any => inv.code)
      .toString();

    const physicalInvoices = invoice.physicalInvoices.filter(
      (inv) => inv.cavaliStatus === 'in progress'
    );

    const entity = {
      codes,
      _id: this.selectedInvoiceId,
      physicalInvoices,
    };

    const resultConfig = {
      onConfirm: {
        title: '¿Estás seguro?',
      },
      onSuccess: {
        showCheck: true,
        title: 'Retiro de CAVALI realizado',
      },
    };

    const callback = () => {
      this.reFetchInvoice();
      this.finishLoading();
    };

    this.overlayService.startConfirmation(
      entity,
      PhysicalInvoiceTableOrganism,
      'details',
      'edit',
      null,
      InvoicesActions.SendingCavali,
      `invoices/${invoice._id}`,
      InvoiceSelectors.savingSuccessStateById,
      resultConfig,
      callback.bind(this)
    );
  }

  removeAnInvoiceFromCavali(physicalInvoice) {
    let invoice = this.selectedInvoice;
    const physicalInvoices = invoice.physicalInvoices.filter(
      (inv): any => inv.code === physicalInvoice.code
    );
    const codes = [physicalInvoice.code].toString();
    const entity = {
      _id: this.selectedInvoiceId,
      codes,
      physicalInvoices,
    };

    const resultConfig = {
      onConfirm: {
        title: '¿Estás seguro?',
      },
      onSuccess: {
        showCheck: true,
        title: 'Retiro de CAVALI realizado',
      },
    };

    const callback = () => {
      this.reFetchInvoice();
    };

    this.overlayService.startConfirmation(
      entity,
      PhysicalInvoiceTableOrganism,
      'details',
      'edit',
      null,
      InvoicesActions.SendingCavali,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      resultConfig,
      callback.bind(this)
    );
  }
  getDate(invoice) {
    let hour = invoice?.onSaleSlot === '1' ? '12:30' : '17:30';
    let date = moment(invoice?.onSaleDate).format('dddd DD [de] MMMM, ') + hour;
    return date;
  }

  getValidationClass(invoice) {
    if (
      (invoice.status === 'pending confirmation' &&
        invoice.evaluation.status === 'rejected') ||
      invoice.evaluation.lastEvaluationDays > 60 ||
      !invoice.evaluation.hasOwnProperty('status') ||
      invoice.debtor?.isObserve
    ) {
      return 'red-filter .no-cursor';
    } else {
      if (
        invoice.checklist &&
        invoice.checklist.every(
          (c) => c.status == 'approved' || c.status == 'exception'
        )
      ) {
        return 'c-pointer';
      } else {
        return 'red-filter .no-cursor';
      }
    }
  }

  getValidationTitle(invoice) {
    if (
      invoice.status === 'pending confirmation' &&
      (invoice.evaluation.status === 'rejected' ||
        invoice.evaluation.lastEvaluationDays > 60 ||
        !invoice.evaluation.hasOwnProperty('status') ||
        invoice.debtor?.isObserve)
    ) {
      return 'title red-filter .no-cursor';
    } else {
      if (
        invoice.checklist &&
        invoice.checklist.every(
          (c) => c.status == 'approved' || c.status == 'exception'
        )
      ) {
        return 'title c-pointer';
      } else {
        return 'title red-filter .no-cursor';
      }
    }
  }

  updateTabSelected($event) {
    this.selected = $event;

    this.isScrollIndicatorVisible =
      this.selectedInvoice.status === 'configured' && this.selected === 0;
  }

  newAnnouncement(invoice, announcementDetail?) {
    this.confirmationMode = 'save';
    const dialogRef = this.dialog.open(AddAnnouncementDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: false,
      data: { announcementDetail },
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.openModalComment = true;
        this.announcementDetail = result;

        if (result.event === 'Cancel') {
          this.openModalComment = false;
          return;
        }

        if (result.file === '') {
          delete result.file;
        }
        if (result.filename === '') {
          delete result.filename;
        }
        this.overlayService.startConfirmation(
          { ...result, _id: this.selectedInvoiceId },
          {
            component: CommentOnConfirmationComponent,
            entity: result,
          },
          'details',
          'create',
          null,
          InvoicesActions.SaveAnnouncement,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Actualización realizada',
            },
            onConfirm: {
              title: '¿Estás seguro?',
            },
          },
          this.reFetchInvoice.bind(this)
        );
      } else {
        this.openModalComment = false;
      }
    });
  }

  editAnnouncement(announcementDetail, mode = '') {
    this.confirmationMode = 'edit';

    const dialogRef = this.dialog.open(AddAnnouncementDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: false,
      data: { announcementDetail, mode: 'edit' },
    });

    this.connectDialogListeners(dialogRef, (result) => {
      // debugger;
      if (result) {
        this.openModalComment = true;
        this.announcementDetail = result;

        if (mode !== 'return') {
          this.originalMessage = result.originalMessage;
        }

        if (result.event === 'Cancel') {
          this.openModalComment = false;
          return;
        }

        if (result.file === '') {
          delete result.file;
        }
        if (result.filename === '') {
          delete result.filename;
        }

        let data = { ...result, _id: this.selectedInvoiceId };

        if (data.hasOwnProperty('originalMessage')) {
          delete data.originalMessage;
        }

        if (!data.hasOwnProperty('announcementId')) {
          data.announcementId = announcementDetail.announcementId;
        }

        this.overlayService.startConfirmation(
          data,
          {
            component: CommentOnConfirmationComponent,
            entity: {
              ...result,
              mode: this.confirmationMode,
              savedOriginalMessage: this.originalMessage,
            },
          },
          'details',
          'edit',
          null,
          InvoicesActions.SaveAnnouncement,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Actualización realizada',
            },
            onConfirm: {
              title: '¿Estás seguro?',
            },
          },
          this.reFetchAfterEdit.bind(this)
        );
      } else {
        this.openModalComment = false;
      }
    });
  }

  resendAnnouncement(announcement) {
    let announcementDetail;

    if (announcement?.announcementDetail) {
      announcementDetail = {
        ...announcement.announcementDetail,
        invoices: announcement.invoices,
        linkedInvoices: announcement.linkedInvoices,
      };
    } else {
      announcementDetail = announcement;
    }
    announcementDetail.mainInvoiceId = this.selectedInvoice;
    const dialogRef = this.dialog.open(ForwardannouncementComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: false,
      data: { announcementDetail },
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.openModalResend = true;

        this.announcementDetail = {
          announcementDetail: result.announcementDetail,
          invoices: result.invoices,
          linkedInvoices: result.linkedInvoices,
        };

        if (result.event === 'Cancel') {
          this.openModalResend = false;
          return;
        }

        let dataForConfirmation = {
          invoices: result.invoices,
          announcementDetail: result.announcementDetail,
        };

        let dataToSave = {
          invoiceIds: result?.invoiceIds,
          announcementId:
            result?.announcement?.announcementId || result?.announcementId,
        };

        this.overlayService.startConfirmation(
          { ...dataToSave, _id: this.selectedInvoiceId },
          {
            component: ForwardAnnouncementConfirmationComponent,
            entity: dataForConfirmation,
          },
          'details',
          'create',
          null,
          InvoicesActions.ForwardAnnouncement,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Actualización realizada',
            },
            onConfirm: {
              title: '¿Estás seguro?',
            },
          },
          this.reFetchAfterEdit.bind(this)
        );
      } else {
        this.openModalResend = false;
      }
    });
  }
  reFetchAfterEdit() {
    if (this.loggedInUserRole === Roles.CLIENT_HAPPINESS) {
      this.openModalComment = false;
      this.openModalResend = false;
    }
    localStorage.removeItem('originalMessage');
    localStorage.removeItem('originalFileName');

    this.fetchInvoice(this.selectedInvoiceId);
  }

  getDistribution(tea: any, tdm: any) {
    let normalizedTea = Number(tea) / 100;
    let normalizedTdm = Number(tdm) / 100;

    let tem = Number((Math.pow(normalizedTea + 1, 1 / 12) - 1).toFixed(6));

    return ((tem / normalizedTdm) * 100).toFixed(10);
  }

  downloadSummary(type: string, invoice) {
    if (
      invoice.status === InvoiceStatus['Awaiting Collection'] ||
      invoice.status === InvoiceStatus.Collected ||
      invoice.status === InvoiceStatus.Finalized
    ) {
      this.downloadFormalDocument(type);
    }
  }

  downloadFormalDocument(type: string, flag?) {
    this.spinner.show();
    this.service
      .downloadInvoiceDocument(this.selectedInvoiceId, type, flag)
      .subscribe((result) => {
        const codes = this.selectedInvoice.physicalInvoices
          .map((p: any) => p.code)
          .join(',');
        const operationType = this.selectedInvoice.isConfirming
          ? 'Confirming'
          : 'Factoring';

        if (result) {
          const fileName =
            type === 'summary_sheet'
              ? `Hoja de Resumen - Condiciones de ${operationType} - ${codes}.pdf`
              : `Carta de cesión de derechos - ${codes}.pdf`;

          var ab = new ArrayBuffer(result.file.data.length);
          var view = new Uint8Array(ab);
          for (var i = 0; i < result.file.data.length; ++i) {
            view[i] = result.file.data[i];
          }

          const f = new Blob([new Uint8Array(result.file.data)]);

          saveAs(f, fileName);
          if (type === 'summary_sheet') {
            this.store.dispatch(LoadProfile());
          }
          this.spinner.hide();
        }
      });
  }

  downloadInvoicing(invoice: any) {
    this.spinner.show();

    this.service
      .downloadInvoiceDocument(invoice._id, 'invoicing')
      .subscribe((result) => {
        if (result) {
          const codes = invoice.physicalInvoices
            .map((p: any) => p.code)
            .join(',');

          const filename = `${invoice?.issuer?.companyName?.toUpperCase()} - ${codes}.zip`;
          var ab = new ArrayBuffer(result.file.data.length);
          var view = new Uint8Array(ab);
          for (var i = 0; i < result.file.data.length; ++i) {
            view[i] = result.file.data[i];
          }

          const f = new Blob([new Uint8Array(result.file.data)]);

          saveAs(f, filename);
        }

        this.spinner.hide();
      });
  }

  getTotalOf(key) {
    let total = this.selectedInvoice?.physicalInvoices?.reduce(function (
      previousValue,
      currentValue
    ) {
      return parseFloat(previousValue) + parseFloat(currentValue[key] || 0);
    },
    0);

    return total.toString();
  }

  uploadCreditNote(fileInput: any) {
    let filePromises = [];
    let isDisabled = false;

    fileInput.forEach((f: any) => {
      const filePromise = new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = () =>
          resolve({ filename: f.name, reader: reader.result });
      });

      filePromises.push(filePromise);
    });

    Promise.all(filePromises).then((files: any) => {
      let errorMessages = [];
      let firstPaymentDate = '';
      let invoicesCreditNote = [];
      let newPaymentDate = '';

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        let fileText = '';

        try {
          fileText = decode(file.reader.split(';base64,')[1]);
        } catch (error) {
          console.log('wrong format');
        }

        const parsedCreditNote = this.service.parseXmlCreditNote(fileText);

        if (parsedCreditNote) {
          parsedCreditNote.xml = fileText;

          let errors = [];

          if (
            invoicesCreditNote.some(
              (c: any) => c.code === parsedCreditNote.relatedInvoiceCode
            )
          ) {
            errors.push(
              `ya se está aplicando una nota a la factura ${parsedCreditNote.relatedInvoiceCode}`
            );
          }

          if (
            Date.parse(parsedCreditNote.invoiceIssueDate) >
            Date.parse(parsedCreditNote.issueDate)
          ) {
            errors.push(
              'la fecha de emisión de la nota debe ser mayor o igual a la de la factura'
            );
          }

          if (
            this.selectedInvoice.debtor.companyRuc !== parsedCreditNote.payerRuc
          ) {
            errors.push('no tiene el mismo deudor');
          }

          if (
            parsedCreditNote.issuerRuc !==
            (this.selectedInvoice.isConfirming
              ? this.selectedInvoice.supplier.companyRuc
              : this.selectedInvoice.issuer.companyRuc)
          ) {
            errors.push('no tiene el mismo proveedor');
          }

          if (
            !this.selectedInvoice.physicalInvoices.some(
              (p: any) => p.code === parsedCreditNote.relatedInvoiceCode
            )
          ) {
            errors.push(
              `el código "${parsedCreditNote.relatedInvoiceCode}" no está contenido en el grupo de facturas`
            );
          }

          if (
            firstPaymentDate &&
            parsedCreditNote.paymentDate !== firstPaymentDate
          ) {
            errors.push('debe tener la misma fecha de pago que las otras');
          }

          let existingCreditNote = this.selectedInvoice.physicalInvoices
            .map((p: any) => p.creditNote)
            ?.find((c: any) => c && c?.code === parsedCreditNote.code);

          if (
            existingCreditNote &&
            moment(parsedCreditNote.issueDate, 'YYYY-MM-DD').isBefore(
              moment(existingCreditNote.issueDate, 'YYYY-MM-DD')
            )
          ) {
            errors.push(
              'la fecha de emisión de la nueva nota debe ser mayor a la aplicada actualmente'
            );
          }

          if (
            existingCreditNote &&
            existingCreditNote.code === parsedCreditNote.code
          ) {
            errors.push(`el código ${parsedCreditNote.code} ya está asociado`);
          }

          if (errors.length > 0) {
            errorMessages.push(
              `• La nota de crédito "${file.filename}" ${errors
                .join(', ')
                .replace(/,([^,]*)$/, ' y' + '$1')}.`
            );
          } else {
            if (index === 0) {
              firstPaymentDate = parsedCreditNote.paymentDate;

              if (firstPaymentDate !== this.selectedInvoice.paymentDate) {
                newPaymentDate =
                  firstPaymentDate || this.selectedInvoice.paymentDate;
              }
            }
            const physicalInvoice = lodash.cloneDeep(
              this.selectedInvoice.physicalInvoices.find(
                (p: any) => p.code === parsedCreditNote.relatedInvoiceCode
              )
            );

            if (!parsedCreditNote.netAmount) {
              physicalInvoice.originalNetAmount = physicalInvoice.netAmount;
              physicalInvoice.originalRetentionAmount = Number(
                physicalInvoice.retentionAmount
              ).toFixed(2);
            } else {
              const discount =
                Number(physicalInvoice.netAmount) -
                Number(parsedCreditNote.netAmount);

              physicalInvoice.originalNetAmount =
                physicalInvoice.originalNetAmount || physicalInvoice.netAmount;
              physicalInvoice.originalRetentionAmount =
                physicalInvoice.originalRetentionAmount ||
                Number(physicalInvoice.retentionAmount).toFixed(2);
              physicalInvoice.netAmount = parsedCreditNote.netAmount;
              physicalInvoice.retentionAmount = (
                Number(physicalInvoice.retentionAmount) + discount
              ).toFixed(2);
            }

            physicalInvoice.creditNote = parsedCreditNote;

            if (physicalInvoice.retentionAmount < 0) {
              errorMessages.push(
                `• La nota de crédito "${file.filename}" posee un monto mayor al original.`
              );
            } else {
              invoicesCreditNote.push(physicalInvoice);
            }
          }
        } else {
          errorMessages.push(
            `• El archivo "${file.filename}" no es una nota de crédito válida.`
          );
        }
      }

      if (!invoicesCreditNote.length) {
        isDisabled = true;
      }

      this.overlayService.startConfirmation(
        { invoicesCreditNote, invoice: this.selectedInvoice, newPaymentDate },
        {
          component: CreditNoteConfirmationComponent,
          entity: {
            invoicesCreditNote,
            invoice: this.selectedInvoice,
            newPaymentDate,
            errorMessages,
          },
        },
        'details',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'Se actualizó la operación',
          },
        },
        this.reFetchInvoice.bind(this),
        null,
        'Confirmar',
        'Cancelar',
        false,
        isDisabled
      );
    });
  }

  resendEmail(investor: any) {
    let payload = {
      email: investor.email,
      names: capitalizeFirstLetters(investor.names),
      invoiceId: this.selectedInvoice._id,
    };

    this.overlayService.startConfirmation(
      payload,
      null,
      'question',
      'create',
      null,
      InvoicesActions.ResendAnnouncementEmail,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: '!Correo enviado!',
        },
        onConfirm: {
          title: '¿Estás seguro de reenviar el correo?',
        },
      },
      null
    );
  }

  checkShowingAcceptButton() {
    if (
      this.selectedInvoice.status == 'configured' ||
      this.selectedInvoice.status == 'pending confirmation'
    ) {
      if (this.selectedInvoice?.checklist) {
        let teaCheck;
        teaCheck = this.selectedInvoice.checklist.filter(
          (ch) => ch.type == 'tea'
        )[0];
        if (teaCheck.status == 'pending') {
          this.showAcceptButton = true;
        } else {
          this.showAcceptButton = false;
        }
      }
    }
  }

  acceptTea() {
    const distribution = this.getDistribution(
      this.selectedInvoice.tea,
      this.selectedInvoice.tdmPercentage
    );

    this.overlayService.startConfirmation(
      {
        teaPercentage: this.selectedInvoice.tea,
        _id: this.selectedInvoiceId,
      },
      null,
      'details',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onConfirm: {
          showCheck: true,
          title: '¿Estás seguro?',
        },
        onSuccess: {
          showCheck: true,
          title: 'Valor ha sido aceptado!',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  getCheck(checklist, type) {
    const check = checklist.find((c) => c.type == type);
    return check;
  }

  hasException(check) {
    return check?.subChecks?.some((sb) => sb.status == 'exception');
  }

  openCheckModal(event) {
    let entity = { ...lodash.cloneDeep(event), invoice: this.selectedInvoice };

    let checklist, checklistSupplier, data;
    if (entity.type === 'documents' && entity.invoice?.isConfirming) {
      checklistSupplier = [
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'pending' && c.subType == 'provider'
          ),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'denied' && c.subType == 'provider'
          ),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) =>
              (c.status == 'approved' || c.status == 'exception') &&
              c.subType == 'provider'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
      checklist = [
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'pending' && c.subType == 'user'
          ),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'denied' && c.subType == 'user'
          ),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) =>
              (c.status == 'approved' || c.status == 'exception') &&
              c.subType == 'user'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
    } else {
      this.addCavaliTooltip(entity);

      checklist = [
        {
          items: entity.subChecks.filter((c) => c.status == 'pending'),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter((c) => c.status == 'denied'),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'approved' || c.status == 'exception'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
    }

    data = { ...entity, checklist, checklistSupplier };

    const dialogRef = this.dialog.open(ChecklistModalComponent, {
      maxHeight: '100vh',
      width: '1200px',
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchInvoice(this.selectedInvoiceId);
    });
  }

  addCavaliTooltip(entity) {
    if (entity.type === 'cavali') {
      for (let index = 0; index < entity.subChecks.length; index++) {
        if (entity.subChecks[index].type == 'compliance') {
          entity.subChecks[index].tooltipText =
            this.selectedInvoice.physicalInvoices
              .map((p) => {
                let status = '---';

                if (p.cavaliStatus == 'in progress') {
                  status = 'Sin respuesta';
                }

                if (p.cavaliPayerResponse) {
                  switch (p.cavaliPayerResponse) {
                    case 'express compliance':
                      status = 'Conformidad Expresa';
                      break;
                    case 'rectified compliance':
                      status = 'Subsanada';
                      break;
                    case 'disagreement':
                    case 'rectified disagreement':
                      status = 'Disconformidad';
                      break;
                    default:
                      status = 'Conformidad presunta';
                      break;
                  }
                }

                return `• ${p.code} \xa0\ \xa0\ (${status})`;
              })
              .join('\n');
        }
      }
    }
  }

  getCodes(physicalInvoices: any) {
    return physicalInvoices?.map((p: any) => p.code).join(', ');
  }

  getClientCheckIcon(checkStatus: any) {
    switch (checkStatus) {
      case 'approved':
      case 'exception':
        return '../../../../../../assets/icons/green-check-circle.svg';
      case 'denied':
        return '../../../../../../assets/icons/red-alert-circle.svg';
      default:
        return '../../../../../../assets/icons/white-circle.svg';
    }
  }

  getRemainingDays(toBeCollectedIn: any, duration: number) {
    const daysLabel = duration === 1 ? 'día' : 'días';
    const quedaLabel = duration === 1 ? 'Queda' : 'Quedan';

    if (toBeCollectedIn === 'En mora') {
      switch (true) {
        case duration >= 1 && duration < 31:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#FFB330',
          };
        case duration >= 31:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#FF0000',
          };

        default:
          return {
            text: `Mora de ${duration} ${daysLabel}`,
            color: '#2D2D2D',
          };
      }
    } else {
      return {
        text: `${quedaLabel} ${duration} ${daysLabel}`,
        color: '#2D2D2D',
      };
    }
  }

  datesPercentage(initialDate: any, finalDate: any) {
    if (initialDate && finalDate) {
      const todaysDateInPeru = getTodayDateOnly();
      let initDate = getDaysDiff(finalDate, todaysDateInPeru);

      const final = getDaysDiff(finalDate, initialDate);

      const result = new Big(initDate)
        .abs()
        .div(final)
        .abs()
        .minus(1)
        .abs()
        .times(100)
        .toFixed(2);

      return result;
    } else {
      return 0;
    }
  }

  updateEstimatedTransfer(invoice: any) {
    if (invoice.hasEstimatedTransfer) {
      this.service
        .update({ _id: invoice._id, hasEstimatedTransfer: false })
        .subscribe();
    }
  }

  getTransferDisclaimer(invoice: any) {
    const bankName = invoice?.isConfirming
      ? invoice?.supplierBankAccount?.name
      : invoice?.bankAccount?.name;
    if (
      bankName == 'Banco de Crédito del Perú' ||
      bankName == 'BBVA Continental'
    ) {
      return 'Las transferencias entre cuentas del mismo banco suelen llegar de manera inmediata.';
    } else {
      return 'La transferencia está sujeta a tiempos interbancarios.';
    }
  }

  formatTransferTime(date) {
    return moment(date)
      ?.format('DD/MMM/YYYY - HH:mm')
      ?.replace(/\./g, '')
      ?.toUpperCase();
  }

  isStatusInProcess(status: string) {
    return [
      'in process',
      'approved',
      'configured',
      'pending confirmation',
      'for sale',
      'partially sold',
      'sale closed',
    ].includes(status);
  }

  getTabName(index: number) {
    switch (index) {
      case 0:
        return 'En proceso';
      case 1:
        return 'Por cobrar';
      case 2:
        return 'Cobrada';
      case 3:
        return 'Desaprobada';
      default:
        return '';
    }
  }

  openPanel(panelName: string) {
    if (this.activePanel === panelName) {
      this.activePanel = '';
    } else {
      this.activePanel = panelName;

      const eventData: any = {
        operation_id: this.selectedInvoice._id,
        operation_type: this.selectedInvoice.isConfirming
          ? 'Confirming'
          : 'Factoring',
        process_type: this.selectedInvoice.isSunatProcess ? 'SUNAT' : 'Cavali',
        accordion_name: panelName,
      };

      this.mixpanelService.newEvent('open_accordion', eventData);
      this.hotjarService.newEvent('open_accordion');
    }
  }

  getStatusInvoice(invoice) {
    if (
      invoice.status === InvoiceStatus.Collected ||
      invoice.status === InvoiceStatus.Finalized
    ) {
      this.invoiceNewStatus = 'collected';
      return 'Cobrada';
    } else if (invoice.status === InvoiceStatus['Awaiting Collection']) {
      this.invoiceNewStatus = 'to collect';
      return 'Por cobrar';
    } else if (
      invoice.advancePaymentDate ||
      (invoice.clientChecks.isFinanceReady && !invoice.advancePaymentDate)
    ) {
      this.invoiceNewStatus = 'finance';
      return 'Financiando';
    } else {
      this.invoiceNewStatus = 'in process';
      return 'En proceso';
    }
  }
  checkForDisabled(invoice) {
    if (
      invoice.status === InvoiceStatus['Awaiting Collection'] ||
      invoice.status === InvoiceStatus.Collected ||
      invoice.status === InvoiceStatus.Finalized
    ) {
      return 'enabled';
    } else {
      return 'disabled';
    }
  }

  getAvailableBalance(invoice) {
    return Big(100).minus(invoice?.availableBalancePercentage || 0);
  }
}
