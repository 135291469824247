import {
  removeOperations,
  updateOperationInvestments,
  updateOperations,
} from './../../../shared/util/ngrx.util';
import { createReducer, on } from '@ngrx/store';
import {
  updateStoreState,
  updateRecalulatedOpportunity,
} from 'src/app/shared/util/ngrx.util';
import { InvoicesActions } from './invoices.action-types';

export interface State {
  loading: boolean;
  loaded: boolean;
  opportunitiesLoading: boolean;
  operationsLoading: boolean;
  operationsLoaded: boolean;
  opportunitiesLoaded: boolean;
  saving: boolean;
  savingSucceeded: boolean;
  savingMode: 'create' | 'edit' | 'createInvoice' | 'createInvoiceNew';
  savingEntityId: string;
  entities: any | null;
  lastErrorMessage: any | null;
  lastErrorStatus: any | null;
  opportunities: any | null;
  companies: any | null;
  accountManagers: any | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  opportunitiesLoading: false,
  opportunitiesLoaded: false,
  operationsLoaded: false,
  operationsLoading: false,
  saving: undefined,
  savingSucceeded: undefined,
  savingMode: undefined,
  savingEntityId: undefined,
  entities: undefined,
  opportunities: undefined,
  companies: undefined,
  accountManagers: undefined,
  lastErrorMessage: undefined,
  lastErrorStatus: undefined,
};

export const _reducer = createReducer(
  initialState,
  on(InvoicesActions.LoadAll, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(InvoicesActions.LoadAllSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      entities: action.entities,
    };
  }),
  on(InvoicesActions.LoadAllOpportunities, (state, action) => {
    return {
      ...state,
      opportunitiesLoading: true,
    };
  }),
  on(InvoicesActions.LoadAllOperations, (state, action) => {
    return {
      ...state,
      operationsLoading: true,
    };
  }),
  on(InvoicesActions.UpdateOperations, (state, action) => {
    return {
      ...state,
      operations: updateOperations(state, action),
    };
  }),
  on(InvoicesActions.RemoveOperations, (state, action) => {
    return {
      ...state,
      operations: removeOperations(state, action),
    };
  }),
  on(InvoicesActions.LoadAllOperationsSuccess, (state, action) => {
    return {
      ...state,
      operationsLoading: false,
      operationsLoaded: true,
      operations: action.operations,
    };
  }),
  on(InvoicesActions.LoadAllOpportunitiesSuccess, (state, action) => {
    return {
      ...state,
      opportunitiesLoading: false,
      opportunitiesLoaded: true,
      opportunities: action.opportunities,
    };
  }),
  on(InvoicesActions.LoadAllOperationsFailed, (state, action) => {
    return {
      ...state,
      operationsLoading: false,
      operationsLoaded: false,
      lastErrorMessage: action.error.error,
      lastErrorStatus: action.error.status,
    };
  }),
  on(InvoicesActions.LoadAllOpportunitiesFailed, (state, action) => {
    return {
      ...state,
      opportunitiesLoading: false,
      opportunitiesLoaded: false,
      lastErrorMessage: action.error.error,
      lastErrorStatus: action.error.status,
    };
  }),
  on(InvoicesActions.Saving, (state, action) => {
    return {
      ...state,
      saving: true,
      savingSucceeded: undefined,
      savingMode: action.mode,
      savingEntityId: action.entity.id,
    };
  }),
  on(InvoicesActions.SaveAdditionalnvoicings, (state, action) => {
    return {
      ...state,
      saving: true,
      savingSucceeded: undefined,
      savingMode: action.mode,
      savingEntityId: action.entity.id,
    };
  }),
  on(InvoicesActions.SaveAnnouncement, (state, action) => {
    return {
      ...state,
      saving: true,
      savingSucceeded: undefined,
      savingMode: action.mode,
      savingEntityId: action.entity.id,
    };
  }),
  on(InvoicesActions.SavingEmail, (state, action) => {
    return {
      ...state,
      saving: true,
      savingSucceeded: undefined,
      savingMode: action.mode,
      savingEntityId: action.entity.id,
    };
  }),
  on(InvoicesActions.SaveSuccess, (state, action) => {
    return {
      ...state,
      saving: false,
      savingSucceeded: true,
      savingMode: undefined,
      entities: updateStoreState(
        state.entities,
        action.entity,
        state.savingMode
      ),
    };
  }),
  on(InvoicesActions.SaveFailed, (state, action) => {
    return {
      ...state,
      saving: false,
      savingSucceeded: false,
      savingMode: undefined,
      lastErrorMessage: action.error.error,
      lastErrorStatus: action.error.status,
    };
  }),
  on(InvoicesActions.SendingCavali, (state, action) => {
    return {
      ...state,
      saving: true,
      savingSucceeded: undefined,
      savingMode: action.mode,
      savingEntityId: action.entity.id,
    };
  }),
  on(InvoicesActions.OpportunityUpdated, (state, action) => {
    return {
      ...state,
      opportunities: updateRecalulatedOpportunity(state.opportunities, action),
    };
  }),
  on(InvoicesActions.InvestmentsUpdated, (state, action) => {
    return {
      ...state,
      operations: updateOperationInvestments(state, action),
    };
  }),
  on(InvoicesActions.Reset, (state, action) => {
    return initialState;
  })
);

export function reducer(state, action) {
  return _reducer(state, action);
}
