<p class="subtitle">Vas a emitir las siguientes facturas</p>

<div *ngIf="!isBulkInvoicing" style="display: flex;flex-direction: column; gap:15px">
    <div class="invoicing-group" *ngIf="invoicingDetails?.user || invoicingDetails?.additionalInvoicings?.user?.length">
        <div>
            <p class="detail-title">Facturas al {{entity.invoice.isConfirming ? 'deudor' : 'proveedor'}}</p>
            <div class="invoicings-list" *ngIf="invoicingDetails?.user">
                <app-invoicing-ticket title="Por costo de servicio" [costDetail]="invoicingDetails?.user?.service">
                </app-invoicing-ticket>
                <app-invoicing-ticket *ngIf="invoicingDetails?.user?.lateFee" title="Intereses moratorios"
                    [costDetail]="invoicingDetails?.user?.lateFee">
                </app-invoicing-ticket>
            </div>

            <div class="invoicings-list" *ngIf="invoicingDetails?.additionalInvoicings?.user">
                <app-invoicing-ticket *ngFor="let item of invoicingDetails?.additionalInvoicings.user"
                    [title]="item.title" [costDetail]="item.additionalInvoicing">
                </app-invoicing-ticket>
            </div>
        </div>
    </div>

    <div class="invoicing-group"
        *ngIf="invoicingDetails?.debtor || invoicingDetails?.additionalInvoicings?.debtor?.length">
        <div>
            <p class="detail-title">Facturas al deudor</p>
            <div class="invoicings-list" *ngIf="invoicingDetails?.user">
                <app-invoicing-ticket title="Por costo de servicio" [costDetail]="invoicingDetails?.debtor?.service">
                </app-invoicing-ticket>
                <app-invoicing-ticket *ngIf="invoicingDetails?.debtor?.lateFee" title="Intereses moratorios"
                    [costDetail]="invoicingDetails?.debtor?.lateFee">
                </app-invoicing-ticket>
            </div>
            <div class="invoicings-list" *ngIf="invoicingDetails?.additionalInvoicings?.debtor">
                <app-invoicing-ticket *ngFor="let item of invoicingDetails?.additionalInvoicings.debtor"
                    [title]="item.title" [costDetail]="item.additionalInvoicing">
                </app-invoicing-ticket>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isBulkInvoicing" style="display: flex;flex-direction: column;gap:24px; margin-top: 26px;">
    <div class="bulk-invoicing" *ngFor="let invoicingDetails of bulkInvoicingDetails">
        <div class="invoicing-group" *ngIf="invoicingDetails?.user">
            <div>
                <p class="bulk-detail-title">{{invoicingDetails.operationCodes}}</p>
                <p class="bulk-detail-title">Facturas al {{entity.invoice.isConfirming ? 'deudor' : 'proveedor'}}</p>
                <div class="invoicings-list">
                    <app-invoicing-ticket title="Por costo de servicio" [costDetail]="invoicingDetails?.user?.service">
                    </app-invoicing-ticket>
                    <app-invoicing-ticket *ngIf="invoicingDetails?.user?.lateFee" title="Intereses moratorios"
                        [costDetail]="invoicingDetails?.user?.lateFee">
                    </app-invoicing-ticket>
                </div>
            </div>
        </div>

        <div class="invoicing-group" *ngIf="invoicingDetails?.debtor">
            <div>
                <p class="bulk-detail-title">Facturas al deudor</p>
                <div class="invoicings-list">
                    <app-invoicing-ticket title="Por costo de servicio"
                        [costDetail]="invoicingDetails?.debtor?.service">
                    </app-invoicing-ticket>
                    <app-invoicing-ticket *ngIf="invoicingDetails?.debtor?.lateFee" title="Intereses moratorios"
                        [costDetail]="invoicingDetails?.debtor?.lateFee">
                    </app-invoicing-ticket>
                </div>
            </div>
        </div>
    </div>
</div>