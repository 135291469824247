import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';

@Component({
  selector: 'add-invoicing-concept',
  templateUrl: './add-invoicing-concept.component.html',
  styleUrls: ['./add-invoicing-concept.component.scss'],
})
export class AddInvoicingConceptComponent implements OnInit {
  form!: FormGroup;

  concepts = [
    {
      text: 'Selecciona el concepto',
      value: '',
      isHidden: false,
    },
    {
      text: 'Copia literal',
      value: 'literalCopy',
      isHidden: false,
    },
    {
      text: 'Comisión interplaza',
      value: 'commission',
      isHidden: false,
    },
    {
      text: 'Gastos legales con IGV',
      value: 'legalExpensesIgv',
      isHidden: false,
    },
    {
      text: 'Tasas legales sin IGV',
      value: 'legalExpenses',
      isHidden: false,
    },
    {
      text: 'Intereses moratorios',
      value: 'additionalFee',
      isHidden: false,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<AddInvoicingConceptComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const savedTypes =
      this.data?.invoice?.invoicings?.map((i: any) => i.type) || [];
    // this.concepts = this.concepts.filter((concept) => {
    //   let keepValue = !savedTypes.includes(concept.value);
    //   return keepValue;
    // });

    this.form = this.fb.group({
      selectedConcepts: this.fb.array([this.createConceptGroup()]),
    });

    this.form.valueChanges.subscribe((changes) => {
      // const selectedTypes = changes.selectedConcepts.map((c: any) => c.type);
      // this.concepts.forEach((concept) => {
      //   concept.isHidden = selectedTypes.includes(concept.value);
      // });
    });
  }

  addConcept() {
    this.selectedConcepts.push(this.createConceptGroup());
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      const result = this.form.value.selectedConcepts;
      result.forEach((res: any) => {
        res.typeDescription = this.concepts.find(
          (concept) => concept.value === res.type
        )?.text;
        res.isToDebtor =
          !this.data?.invoice?.isConfirming &&
          !(
            res.type === 'legalExpenses' ||
            res.type === 'legalExpensesIgv' ||
            res.type === 'additionalFee'
          )
            ? false
            : res.isToDebtor;
      });
      this.dialogRef.close(result);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  get selectedConcepts(): FormArray {
    return this.form.get('selectedConcepts') as FormArray;
  }

  createConceptGroup(): FormGroup {
    return this.fb.group({
      type: ['', Validators.required],
      amount: [
        '',
        [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
      isToDebtor: [true],
    });
  }
}
