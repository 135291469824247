import { SaveAdditionalnvoicings } from './../../../../invoices/state/invoices.actions';
import { dataURItoBlob } from './../../../../../shared/util/string.util';
import { profile } from './../../../../users/state/users.selectors';
import { EditAmountConfirmationComponent } from './../../../../risk-debtors/UI/organisms/edit-amount-confirmation/edit-amount-confirmation.component';
import { NewConfirmationModalComponent } from './../../../../../shared/UI/molecules/new-confirmation-modal/new-confirmation-modal.component';
import { ConfirmingEditAmountsInvoceDialogComponent } from '../../../../invoices/UI/organisms/confirming-edit-amounts-invoce-dialog/confirming-edit-amounts-invoce-dialog.component';
import { CavaliResponseDialogComponent } from './../../../../invoices/UI/organisms/cavali-response-dialog/cavali-response-dialog.component';
import { decode } from 'js-base64';
import { CreditNoteConfirmationComponent } from './../../../../invoices/UI/organisms/credit-note-confirmation/credit-note-confirmation.component';
import { InvoiceGroupConfirmationComponent } from './../../../../invoices/UI/organisms/invoice-group-confirmation/invoice-group-confirmation.component';
import { GroupingInvoiceComponent } from './../../../../invoices/UI/templates/grouping-invoice/grouping-invoice.component';
import { InvoiceToCavaliComponent } from './../../../../invoices/UI/organisms/invoice-to-cavali/invoice-to-cavali.component';
import { PhysicalInvoiceTableOrganism } from './../../../../invoices/UI/organisms/physical-invoice-table/physical-invoice-table.organism';
import { EditAmountsInvoceDialogComponent } from './../../../../invoices/UI/organisms/edit-amounts-invoce-dialog/edit-amounts-invoce-dialog.component';
import { SocketService } from 'src/app/shared/services/socket.service';
import { AddContactsEmailsComponent } from './../../../../risk-debtors/UI/organisms/add-contacts-emails/add-contacts-emails.component';
import { Overlay } from '@angular/cdk/overlay';
import { SaveResultComponent } from 'src/app/shared/modules/overlay/ui/save-result/save-result.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { TelegramConfirmationComponent } from './../../../../risk-debtors/UI/organisms/telegram-confirmation/telegram-confirmation.component';
import { AddtelegramComponent } from './../../../../risk-debtors/UI/organisms/addtelegram/addtelegram.component';
import { ObservePayerComponent } from './../../../../risk-debtors/UI/organisms/observe-payer/observe-payer.component';
import { EvaluationSelectors } from 'src/app/features/risk-pending-evaluations/states/evaluations.selector-types';
import { EvaluationsActions } from 'src/app/features/risk-pending-evaluations/states/evaluations.action-types';
import { AddNoteDialogComponent } from './../../../../risk-debtors/UI/organisms/add-note-dialog/add-note-dialog.component';
import { OverlayService } from 'src/app/shared/modules/overlay/services/overlay.service';
import { DebtorService } from './../../../../risk-debtors/services/debtor.service';
import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.states';
import { BankAccountsService } from 'src/app/features/bank-accounts/services/bank-accounts.service';
import { InvoiceService } from 'src/app/features/invoices/services/invoice.service';
import { InvoiceSelectors } from 'src/app/features/invoices/state/invoices.selector-types';
import { SuplierService } from 'src/app/features/my-supliers/services/supliers.service';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { Roles } from 'src/app/shared/enums/Roles.enum';
import { CurrencyNamePipe } from 'src/app/shared/pipes/currency-name.pipe';
import { ShortenPipe } from 'src/app/shared/pipes/shorten.pipe';
import { ChecklistModalComponent } from 'src/app/shared/UI/organisms/checklist-modal/checklist-modal.component';
import lodash from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { NotExpr } from '@angular/compiler';
import { ConfigureInvoiceDialogComponent } from 'src/app/features/invoices/UI/organisms/configure-invoice-dialog/configure-invoice-dialog.component';
import { environment } from 'src/environments/environment';
import { InvoicesActions } from 'src/app/features/invoices/state/invoices.action-types';
import { ConfirmationSettingConditionComponent } from '@finsmart/ui-lib/lib/components/v1/4-implementations/confirmation-setting-condition/confirmation-setting-condition.component';
import { ConfirmatSetConditionsComponent } from 'src/app/features/invoices/UI/organisms/confirmat-set-conditions/confirmat-set-conditions.component';
import { AddContactDialogComponent } from 'src/app/features/my-clients/UI/organisms/add-contact-dialog/add-contact-dialog.component';
import { AddContactConfirmationComponent } from 'src/app/features/my-clients/UI/organisms/add-contact-confirmation/add-contact-confirmation.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MotiveDialogComponent } from 'src/app/features/invoices/UI/organisms/motive-dialog/motive-dialog.component';
import { InvoiceMotiveConfirmationComponent } from 'src/app/features/invoices/UI/organisms/invoice-motive-confirmation/invoice-motive-confirmation.component';
import { InvoiceForSaleDialogComponent } from 'src/app/features/invoices/UI/organisms/invoice-for-sale-dialog/invoice-for-sale-dialog.component';
import { ConfirmInvoiceForSaleComponent } from 'src/app/features/invoices/UI/organisms/confirm-invoice-for-sale/confirm-invoice-for-sale.component';
import { PayClientAdvanceDialogComponent } from 'src/app/features/invoices/UI/organisms/pay-client-advance-dialog/pay-client-advance-dialog.component';
import { CollectInvoiceDialogComponent } from 'src/app/features/invoices/UI/organisms/collect-invoice-dialog/collect-invoice-dialog.component';
import * as Big from 'big.js';
import { Location } from '@angular/common';
import { WindowScrollService } from 'src/app/shared/services/window-scroll.service';
import { EmailBoDialogComponent } from 'src/app/features/invoices/UI/organisms/email-bo-dialog/email-bo-dialog.component';
import { EmailPreviewComponent } from 'src/app/features/invoices/UI/organisms/email-preview/email-preview.component';
import { EmailType } from 'src/app/shared/enums/EmailType.enum';
import moment from 'moment';
import { NewObservationDialogComponent } from 'src/app/features/invoices/UI/organisms/new-observation-dialog/new-observation-dialog.component';
import { capitalizeFirstLetters } from 'src/app/shared/util/string.util';
import { CommentOnConfirmationComponent } from 'src/app/features/invoices/UI/organisms/comment-on-confirmation/comment-on-confirmation.component';
import { AddAnnouncementDialogComponent } from 'src/app/features/invoices/UI/organisms/add-announcement-dialog/add-announcement-dialog.component';
import { ForwardannouncementComponent } from 'src/app/features/invoices/UI/organisms/forwardannouncement/forwardannouncement.component';
import { ForwardAnnouncementConfirmationComponent } from 'src/app/features/invoices/UI/organisms/forward-announcement-confirmation/forward-announcement-confirmation.component';
import { PartialPaymentDialogComponent } from 'src/app/features/invoices/UI/organisms/partial-payment-dialog/partial-payment-dialog.component';
import { PartialPaymentConfirmationComponent } from 'src/app/features/invoices/UI/organisms/partial-payment-confirmation/partial-payment-confirmation.component';
import { ChooseEmailsDialogComponent } from 'src/app/features/invoices/UI/organisms/choose-emails-dialog/choose-emails-dialog.component';
import { Toaster } from 'ngx-toast-notifications';
import { InvoiceStatus } from 'src/app/shared/enums/InvoiceStatus.enum';
import { RejectContactDialogComponent } from 'src/app/features/my-clients/UI/organisms/reject-contact-dialog/reject-contact-dialog.component';
import { SplitInvoiceDialogComponent } from 'src/app/features/invoices/UI/organisms/split-invoice-dialog/split-invoice-dialog.component';
import { ConfirmationSplitInvoiceComponent } from 'src/app/features/invoices/UI/templates/confirmation-split-invoice/confirmation-split-invoice.component';
import { InvoiceTeaConfigurationDialogComponent } from 'src/app/features/invoices/UI/organisms/invoice-tea-configuration/invoice-tea-configuration-dialog.component';
import { invoice } from 'e2e/helpers/invoice.helper';
import { InvoicingConfirmationComponent } from 'src/app/features/invoices/UI/organisms/invoicing-confirmation/invoicing-confirmation.component';
import { AddInvoicingConceptComponent } from 'src/app/features/invoices/UI/organisms/add-invoicing-concept/add-invoicing-concept.component';

@Component({
  selector: 'app-operation-detail',
  templateUrl: './operation-detail.component.html',
  styleUrls: ['./operation-detail.component.scss'],
})
export class OperationDetailComponent implements OnInit, OnDestroy {
  selectedInvoiceId;
  loggedInUserRole;
  selectedInvoice;
  showRemoveCavaliButton = false;
  lastConfiguration;
  bankAccounts;
  selectedBankAccount;
  warrantyCheck = false;
  showUploadToCavaliButton = false;
  selectedBankAccountSupplier;
  bankAccountsSupplier;
  debtor;
  newEvaluationLoading = false;
  subscription;
  setConditionsModal = false;
  conditions;
  telegramModalData: any = null;
  contacts: any;
  selected = 0;
  openModalContact = false;
  contactInformation;
  isValidating = false;
  userObservations;
  rejectionReason;
  showMotiveDialog = false;
  openModalSchedule = false;
  scheduleInvoice;
  previousUrl;
  dataEmail;
  sendEmail = false;
  headersObs = [];
  displayBackButton = false;
  userBankAccounts;
  supplierBankAccounts;
  invoiceUngroupList = [];
  investments;
  mode;
  openModalComment = false;
  announcementDetail;
  confirmationMode;
  openModalResend;
  displayBubbleDesembolso;
  @ViewChild('creditNoteFile') creditNoteFile: ElementRef;
  originalMessage;
  announcements;
  partialPaymentModal = false;
  paymentDate;
  dataPartialPayment;
  isEditorFlow = false;
  isAdvanceModalOpened = false;
  confirmationData = null;
  collectionEmailReady;
  addContactMode;
  displayBubble = false;
  abbleToDisplay = false;
  iconClass = 'c-pointer';
  dataEmailDesembolso;
  sendEmailDesembolso;
  desembolsoFlag;
  emailTitle;
  iconClassInvoice;
  splitInvoiceModal = false;
  resultPartitions;
  ungroupModal = false;
  displayDownload = false;
  blockersInvoiceForSale = [
    {
      item: 'checkList',
      value: false,
      msg: 'Todos los checks deben estar completos.',
    },
    {
      item: 'delayedInvoices',
      value: false,
      msg: ' El pagador no debe tener operaciones vencidas.',
    },
    {
      item: 'pendingCreditNote',
      value: false,
      msg: 'Falta subir una o más notas de crédito.',
    },
    {
      item: 'minimunDuration',
      value: false,
      msg: 'La duración debe ser mayor a 14 días.',
    },
    {
      item: 'confimingWarranty',
      value: false,
      msg: 'El colchón no debe ser negativo.',
    },
    {
      item: 'riskObservePayer',
      value: false,
      msg: 'Riesgos debe levantar la observación del pagador.',
    },
    // {
    //   item: 'cavaliRealiableNotication',
    //   value: false,
    //   msg: 'No se ha comunicado de manera fehaciente la anotación en cuenta.',
    // },
    {
      item: 'desembolsoEmail',
      value: false,
      msg: 'No puedes enviar el correo debido a que la deuda coactiva ha sido aprobada con excepción. El equipo de Operaciones debe revisar si es necesaria alguna retención y encargarse de enviar el correo de desembolso.',
    },
  ];
  isAdditionalInvoicingDisabled = false;

  constructor(
    private route: ActivatedRoute,
    public service: InvoiceService,
    private store: Store<AppState>,
    private bankAccountService: BankAccountsService,
    private currencyNamePipe: CurrencyNamePipe,
    private shortenPipe: ShortenPipe,
    public supplierService: SuplierService,
    public dialog: MatDialog,
    private debtorService: DebtorService,
    private overlayService: OverlayService,
    private overlay: Overlay,
    private router: Router,
    private socketService: SocketService,
    private spinner: NgxSpinnerService,
    private location: Location,
    private windowScrollService: WindowScrollService,
    private toaster: Toaster
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getRoleUser();
      this.selectedInvoiceId = params.invoiceId;
      this.fetchInvoice(this.selectedInvoiceId);
      this.getLocalStorage();
      // debugger;
      this.socketService.socketEvent.subscribe((event) => {
        if (event == 'update_operation') {
          this.fetchInvoice(this.selectedInvoiceId);
        }

        if (event.name === 'investments_update') {
          try {
            if (this.selectedInvoiceId === event?.data?.invoiceId) {
              this.selectedInvoice.availableBalanceAmount =
                event?.data?.newAvailBalance;
              this.selectedInvoice.availableBalancePercentage =
                event?.data?.newAvailBalancePercentage;
              this.selectedInvoice.status = event?.data?.newInvoiceStatus;
            }
          } catch (error) {
            console.log(error);
          }
        }
      });

      this.setHeadersObs();
    });

    this.subscription = this.overlayService
      .getCloseEmitter()
      .subscribe((obs) => {
        if (this.sendEmail) {
          let data;
          data = {
            type: this.dataEmail.type,
            isExternal: this.dataEmail.isExternal,
            currency: this.dataEmail.currency,
            cc: this.dataEmail.cc,
            to: this.dataEmail.to,
          };
          if (!this.dataEmail.isExternal) {
            data = {
              type: this.dataEmail.type,
              isExternal: this.dataEmail.isExternal,
              currency: this.dataEmail.currency,
              cc: this.dataEmail.cc,
              file: this.dataEmail.file,
              notes: this.dataEmail.notes,
              to: this.dataEmail.to,
              observations: obs,
            };
          }

          if (
            (this.loggedInUserRole === Roles.ADMIN ||
              this.loggedInUserRole === Roles.ACCOUNT_MANGER ||
              this.loggedInUserRole === Roles.ACCOUNT_MANAGER_SUPERVISOR ||
              this.loggedInUserRole === Roles.SUPER_ADMIN) &&
            this.router.url === '/invoices/' + this.selectedInvoiceId
          ) {
            this.send(data);
          }
        }

        if (
          this.sendEmailDesembolso &&
          this.loggedInUserRole === Roles.ACCOUNT_MANAGER_SUPERVISOR
        ) {
          let data;
          data = {
            type: this.dataEmailDesembolso.type,
            isExternal: this.dataEmailDesembolso.isExternal,
            currency: this.dataEmailDesembolso.currency,
            cc: this.dataEmailDesembolso.cc,
            to: this.dataEmailDesembolso.to,
          };
          this.sendDesembolsoEmail(this.selectedInvoice, data);
        }
        if (
          this.setConditionsModal &&
          (this.loggedInUserRole === Roles.ADMIN ||
            this.loggedInUserRole === Roles.SUPER_ADMIN ||
            this.loggedInUserRole === Roles.ACCOUNT_MANGER ||
            this.loggedInUserRole === Roles.ACCOUNT_MANAGER_SUPERVISOR)
        ) {
          this.configureInvoice(
            this.conditions?.tdmPercentage,
            this.conditions?.reservationPercentage,
            this.conditions?.distributionPercentage
          );
        } else if (
          this.setConditionsModal &&
          this.loggedInUserRole === Roles.RISK_ANALYST
        ) {
          this.editTelegram(this.debtor);
        } else if (this.openModalContact) {
          if (this.addContactMode === 'edit') {
            this.editValidContacts(this.contactInformation);
          }
          if (this.addContactMode === 'add') {
            this.addContact(this.contactInformation);
          }
        }

        if (
          this.openModalComment &&
          this.loggedInUserRole === Roles.CLIENT_HAPPINESS
        ) {
          if (this.confirmationMode === 'save') {
            this.newAnnouncement(this.selectedInvoice, this.announcementDetail);
          } else {
            this.editAnnouncement(this.announcementDetail, 'return');
          }
        }

        if (
          this.partialPaymentModal &&
          (this.loggedInUserRole === Roles.ADMIN ||
            this.loggedInUserRole === Roles.SUPER_ADMIN)
        ) {
          this.partialPayment(this.paymentDate, this.dataPartialPayment);
        }
        if (
          this.openModalResend &&
          this.loggedInUserRole === Roles.CLIENT_HAPPINESS
        ) {
          this.resendAnnouncement(this.announcementDetail);
        }

        if (
          this.splitInvoiceModal &&
          (this.loggedInUserRole === Roles.ADMIN ||
            this.loggedInUserRole === Roles.SUPER_ADMIN ||
            this.loggedInUserRole === Roles.CLIENT_HAPPINESS)
        ) {
          this.openInvoiceSplit(this.resultPartitions);
        }

        if (this.showMotiveDialog) {
          this.rejectInvoice();
        }

        if (
          this.openModalSchedule &&
          (this.loggedInUserRole === Roles.ADMIN ||
            this.loggedInUserRole === Roles.SUPER_ADMIN)
        ) {
          this.putInvoiceForSale(this.selectedInvoice, this.scheduleInvoice);
        }

        if (this.confirmationData) {
          const invoiceClone = lodash.cloneDeep(this.selectedInvoice);

          invoiceClone.paymentDate = this.confirmationData.paymentDate;
          invoiceClone.physicalInvoices =
            this.confirmationData.physicalInvoices;

          let dialogRef = this.openDialogHelper(
            EditAmountsInvoceDialogComponent,
            {
              invoice: invoiceClone,
              wasEdited: true,
            },
            false,
            '500px'
          );

          this.connectDialogListeners(dialogRef, (result) => {
            if (result) {
              if (result.event === 'Cancel') {
                this.dataEmail = '';
                this.confirmationData = null;
                return;
              }

              const callBack = () => {
                this.reFetchInvoice();
                this.confirmationData = null;
              };

              this.confirmationData = result;

              this.overlayService.startConfirmation(
                {
                  _id: this.selectedInvoiceId,
                  physicalInvoices: result.physicalInvoices,
                  paymentDate: result.paymentDate,
                },
                {
                  component: EditAmountConfirmationComponent,
                  entity: {
                    newData: result,
                    originalInvoice: this.selectedInvoice,
                  },
                },
                'details',
                'edit',
                null,
                InvoicesActions.Saving,
                `invoices/${this.selectedInvoiceId}`,
                InvoiceSelectors.savingSuccessStateById,
                {
                  onSuccess: {
                    showCheck: true,
                    title: 'Facturas modificadas',
                  },
                  onConfirm: {
                    title: 'Confirma los cambios',
                  },
                },
                callBack.bind(this),
                '60%',
                'Confirmar',
                'Regresar'
              );
            }
          });
        }

        if (this.ungroupModal) {
          this.invoiceUngroupList = [];
        }
      });

    this.windowScrollService.previousUrl$.subscribe((previousUrl: string) => {
      if (previousUrl === '/invoices') {
        this.displayBackButton = true;
      }
    });
  }

  // public get validationInvoice() {
  //   //this.isInvoiceReady2(this.selectedInvoice)
  //   console.log('entraaaaa')
  //   if (this.blockersInvoiceForSale.some(item => item.value)) {
  //     return 'title red-filter .no-cursor';
  //   } else {
  //     return 'title c-pointer';
  //   }
  // }

  getValidationInvoiceForSale(invoice) {
    this.isInvoiceReady2(invoice);

    if (this.blockersInvoiceForSale.some((item) => item.value)) {
      this.iconClassInvoice = 'red-filter .no-cursor';
      this.abbleToDisplay = true;
      return 'title red-filter .no-cursor';
    } else {
      this.iconClassInvoice = 'c-pointer';
      this.abbleToDisplay = false;
      return 'title c-pointer';
    }
  }

  getValidationInvoice(invoice) {
    this.isInvoiceReady2(invoice);

    if (this.blockersInvoiceForSale.some((item) => item.value)) {
      this.iconClass = 'red-filter .no-cursor';
      this.abbleToDisplay = true;
      return 'title red-filter .no-cursor';
    } else {
      this.iconClass = 'c-pointer';
      this.abbleToDisplay = false;
      return 'title c-pointer';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  mouseEnter() {
    this.displayBubble = true;
  }

  mouseLeave() {
    this.displayBubble = false;
  }

  getRoleUser() {
    this.store
      .select(UsersSelectors.profileProperty('role'))
      .pipe(take(1))
      .subscribe((role) => (this.loggedInUserRole = role));
  }

  setHeadersObs() {
    this.headersObs.push(
      {
        label: 'Descripción',
        value: 'description',
        width: 'l', //s->20% medium->50% large ->80%
      },
      {
        label: 'Monto',
        value: 'quantity',
      },
      {
        label: 'Comentario',
        value: 'unitPrice',
      }
    );
    if (this.loggedInUserRole == 'admin') {
      this.headersObs.push(
        {
          label: '¿IGV?',
          value: 'description',
        },
        {
          label: 'Acción',
          value: 'description',
          width: 'l',
        }
      );
    }
  }

  checkValidContactsCollection() {
    if (this.contacts?.debtorContacts.collectionEmailReady) {
      this.collectionEmailReady =
        this.contacts?.debtorContacts.collectionEmailReady;
    } else {
      this.collectionEmailReady = false;
    }
  }
  fetchInvoice(invoiceId) {
    this.service.getConfiguration(invoiceId).subscribe((res) => {
      this.lastConfiguration = res;
      // this.getObservations(res);
      this.checkDesembolsoStatus();
    });

    this.service.byId(invoiceId).subscribe((data: any) => {
      this.selectedInvoice = data;
      this.route.queryParams.pipe(first()).subscribe((params) => {
        if (
          params.advanceTransfer &&
          this.loggedInUserRole == Roles.SUPER_ADMIN &&
          !this.isAdvanceModalOpened
        ) {
          this.payAdvanceToClient(this.selectedInvoice);
        }
      });

      this.checkCavali(data);

      if (this.loggedInUserRole === Roles.CLIENT_HAPPINESS) {
        this.setMode();
        this.getAnnouncementsList(data);
      }
      if (
        this.loggedInUserRole === Roles.CLIENT_HAPPINESS ||
        this.loggedInUserRole === Roles.ADMIN ||
        this.loggedInUserRole === Roles.SUPER_ADMIN
      ) {
        this.getInvestorsList(data);
      }

      if (this.selectedInvoice.isConfirming && this.selectedInvoice.checklist) {
        this.warrantyCheck =
          this.selectedInvoice.checklist.find((c) => c.type == 'warranty')
            ?.status == 'pending';
      }

      if (this.loggedInUserRole !== Roles.CLIENT_HAPPINESS) {
        this.fetchBankAccounts(data);
        this.getObservations(this.selectedInvoice);
      }
      this.fetchDebtor(data.debtor._id);
      this.checkDesembolsoStatus();
      this.getValidationInvoice(this.selectedInvoice);
      this.isAdditionalInvoicingDisabled =
        data.invoicings?.some(
          (invocing: any) => invocing.type === 'literalCopy'
        ) &&
        data.invoicings?.some(
          (invocing: any) => invocing.type === 'commission'
        ) &&
        data.invoicings?.some(
          (invocing: any) => invocing.type === 'additionalFee'
        ) &&
        data.invoicings?.some(
          (invocing: any) => invocing.type === 'legalExpenses'
        ) &&
        data.invoicings?.some(
          (invocing: any) => invocing.type === 'legalExpensesIgv'
        );
      // data.invoicings?.some(
      //   (invocing: any) =>
      //     invocing.type === 'legalExpenses' ||
      //     invocing.type === 'legalExpensesIgv'
      // );
    });
  }
  mouseEnterDesembolso() {
    this.displayBubbleDesembolso = true;
  }

  mouseLeaveDesembolso() {
    this.displayBubbleDesembolso = false;
  }

  fetchDebtor(debtorId: string) {
    this.debtorService.getById(debtorId).subscribe((debtorData: any) => {
      this.debtor = debtorData;
      this.setConditionsModal = false;

      if (
        this.loggedInUserRole === Roles.RISK_ANALYST ||
        this.loggedInUserRole === Roles.ADMIN ||
        this.loggedInUserRole === Roles.SUPER_ADMIN ||
        this.loggedInUserRole === Roles.ACCOUNT_MANGER ||
        this.loggedInUserRole === Roles.ACCOUNT_MANAGER_SUPERVISOR
      ) {
        this.debtorService
          .getContacts(
            this.debtor.companyRuc,
            this.selectedInvoice.isConfirming
              ? this.selectedInvoice.supplier.companyRuc
              : this.selectedInvoice.issuer.companyRuc
          )
          .subscribe((res) => {
            this.contacts = res;
            this.checkValidContactsCollection();
          });
      }
    });
  }

  fetchBankAccounts(invoice: any) {
    this.bankAccountService
      .getAllByUserId(invoice.issuer._id)
      .subscribe((result) => {
        this.userBankAccounts = result?.bankAccounts;
      });

    if (invoice.isConfirming) {
      this.supplierService
        .getBanksAdmin(invoice.supplier._id, invoice.issuer._id)
        .subscribe((result) => {
          this.supplierBankAccounts = result?.bankAccounts;
        });
    }
  }

  getContacts() {
    this.openModalContact = false;
    this.isValidating = false;
    this.contactInformation = '';
    setTimeout(() => {
      this.debtorService
        .getContacts(
          this.debtor.companyRuc,
          this.selectedInvoice.isConfirming
            ? this.selectedInvoice.supplier.companyRuc
            : this.selectedInvoice.issuer.companyRuc
        )
        .subscribe((res) => {
          this.contacts = res;
          this.checkValidContactsCollection();
        });
    }, 100);
  }

  checkCavali(invoice) {
    this.showRemoveCavaliButton = invoice.physicalInvoices.some(
      (inv) => inv.cavaliStatus === 'in progress'
    );
    this.showUploadToCavaliButton = invoice.physicalInvoices.some(
      (inv) => inv.cavaliStatus !== 'in progress'
    );
  }

  setBankAccountList(invoice) {
    // this.service.byId(this.selectedInvoiceId).subscribe((res: any) => {

    // });
    this.bankAccountService
      .getAllByUserId(invoice.issuer._id)
      .subscribe((result) => {
        this.bankAccounts = result.bankAccounts
          .map((bankAccount) => ({
            ...bankAccount,
            bankSummary: `    
            ${bankAccount.name} 
            ${this.currencyNamePipe.transform(bankAccount.currency)}  
            ${this.shortenPipe.transform(bankAccount.number)}`,
          }))
          .filter((bankAccount) => bankAccount.currency === invoice.currency);
        this.selectedBankAccount = this.bankAccounts.filter(
          (bankAccount) => bankAccount._id === invoice.bankAccount._id
        )[0];
      });
  }

  setBankAccountListSupplier(invoice) {
    this.supplierService
      .getBanksAdmin(invoice.supplier._id, invoice.issuer._id)
      .subscribe((result) => {
        this.bankAccountsSupplier = result.bankAccounts
          .map((bankAccount) => ({
            ...bankAccount,
            bankSummary: `    
          ${bankAccount.name} 
          ${this.currencyNamePipe.transform(bankAccount.currency)}  
          ${this.shortenPipe.transform(bankAccount.number)}`,
          }))
          .filter((bankAccount) => bankAccount.currency === invoice.currency);
        this.selectedBankAccountSupplier = this.bankAccountsSupplier.filter(
          (bankAccount) => bankAccount._id === invoice.supplierBankAccount._id
        )[0];
      });
  }

  openCheckModal(event) {
    let entity = { ...lodash.cloneDeep(event), invoice: this.selectedInvoice };

    // this.addCommentTootlip(entity)
    let checklist, checklistSupplier, data;
    if (entity.type === 'documents' && entity.invoice?.isConfirming) {
      checklistSupplier = [
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'pending' && c.subType == 'provider'
          ),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'denied' && c.subType == 'provider'
          ),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) =>
              (c.status == 'approved' || c.status == 'exception') &&
              c.subType == 'provider'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
      checklist = [
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'pending' && c.subType == 'user'
          ),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'denied' && c.subType == 'user'
          ),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) =>
              (c.status == 'approved' || c.status == 'exception') &&
              c.subType == 'user'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
    } else {
      // this.addCavaliTooltip(entity);

      checklist = [
        {
          items: entity.subChecks.filter((c) => c.status == 'pending'),
          title: 'pending',
        },
        {
          items: entity.subChecks.filter((c) => c.status == 'denied'),
          title: 'denied',
        },
        {
          items: entity.subChecks.filter(
            (c) => c.status == 'approved' || c.status == 'exception'
          ),
          title: 'approved',
          hasException: true,
        },
      ];
    }

    data = { ...entity, checklist, checklistSupplier };

    const dialogRef = this.dialog.open(ChecklistModalComponent, {
      maxHeight: '100vh',
      width: '1200px',
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchInvoice(this.selectedInvoiceId);
    });
  }

  addCavaliTooltip(entity) {
    if (entity.type === 'cavali') {
      for (let index = 0; index < entity.subChecks.length; index++) {
        if (entity.subChecks[index].type == 'compliance') {
          entity.subChecks[index].tooltipText =
            this.selectedInvoice.physicalInvoices
              .map((p) => {
                let status = '---';

                if (p.cavaliStatus == 'in progress') {
                  status = 'Sin respuesta';
                }

                if (p.cavaliPayerResponse) {
                  switch (p.cavaliPayerResponse) {
                    case 'express compliance':
                      status = 'Conformidad Expresa';
                      break;
                    case 'rectified compliance':
                      status = 'Subsanada';
                      break;
                    case 'disagreement':
                    case 'rectified disagreement':
                      status = 'Disconformidad';
                      break;
                    default:
                      status = 'Conformidad presunta';
                      break;
                  }
                }

                return `• ${p.code} \xa0\ \xa0\ (${status})`;
              })
              .join('\n');
        }
      }
    }
  }

  addCommentTootlip(entity) {
    for (let index = 0; index < entity.subChecks.length; index++) {
      if (entity.subChecks[index].comment) {
        entity.subChecks[index].tooltipText = entity.subChecks[index].comment;
      }
    }
  }

  getCheck(checklist, type) {
    const check = checklist.find((c) => c.type == type);
    return check;
  }

  hasException(check) {
    return check?.subChecks?.some((sb) => sb.status == 'exception');
  }

  gotoSlack(invoice) {
    let url = invoice.slackDirectUrl;
    window.open(url, '_blank');
  }

  gotoPipedrive(invoice) {
    let url = invoice.pipedriveDirectUrl;
    window.open(url, '_blank');
  }

  openDialogHelper(dialogComp, data?, autoFocus?, width?) {
    let focus = autoFocus == false ? false : true;
    let fixedWidth = width ?? '';
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: '100vw',
      width: fixedWidth,
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: focus,
      data: data,
    });

    return dialogRef;
  }
  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  configureInvoice(tdm?, reservation?, tea?) {
    let dialogRef = this.openDialogHelper(ConfigureInvoiceDialogComponent, {
      tdm,
      reservation,
      tea,
      _id: this.selectedInvoiceId,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.conditions = result;
        this.setConditionsModal = true;
        this.service
          .getCostValues(this.selectedInvoiceId, result)
          .subscribe((data: any) => {
            if (data) {
              const callback = () => {
                this.reFetchInvoice();
                this.resetFlag();
              };
              this.overlayService.startConfirmation(
                {
                  ...result,
                  _id: this.selectedInvoiceId,
                  homeUrl: environment.clientUrl,
                },
                {
                  component: ConfirmatSetConditionsComponent,
                  entity: data,
                },
                'details',
                'edit',
                null,
                InvoicesActions.Saving,
                `invoices/${this.selectedInvoiceId}`,
                InvoiceSelectors.savingSuccessStateById,
                {
                  onSuccess: {
                    showCheck: true,
                    title: 'La factura se ha configurado correctamente',
                    messsage:
                      'La compañía emisora ha sido notificada que su financiamiento de la factura está pendiente de su aceptación.',
                  },
                },
                callback.bind(this),
                '90%'
              );
            }
          });
      } else {
        this.setConditionsModal = false;
      }
    });
  }

  reFetchInvoice() {
    if (this.loggedInUserRole === Roles.CLIENT_HAPPINESS) {
      this.openModalComment = false;
    }
    this.fetchInvoice(this.selectedInvoiceId);
  }

  resetFlag() {
    this.setConditionsModal = false;
  }
  revertConfiguration() {
    this.overlayService.startConfirmation(
      { status: 'approved', _id: this.selectedInvoiceId },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La configuración se ha revertido',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  editNote(debtor: any) {
    let dialogRef = this.dialog.open(AddNoteDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: { note: debtor?.riskNote },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.overlayService.startConfirmation(
          {
            riskNote: result.note,
            _id: debtor._id,
          },
          null,
          'resultOnly',
          'edit',
          null,
          EvaluationsActions.SavingNote,
          null,
          EvaluationSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Nota actualizada',
            },
          },
          this.fetchDebtor.bind(this, this.selectedInvoice.debtor._id)
        );
      }
    });
  }

  observePayer(debtor: any) {
    if (debtor.isObserve !== undefined && debtor.isObserve) {
      this.overlayService.startConfirmation(
        {
          _id: debtor._id,
          isObserve: false,
        },
        null,
        'question',
        'edit',
        null,
        EvaluationsActions.SavingNote,
        null,
        EvaluationSelectors.savingSuccessStateById,
        {
          onConfirm: {
            title: '¿Estás seguro?',
          },
          onSuccess: {
            showCheck: true,
            title: 'Se ha eliminado la observación',
          },
        },
        this.fetchDebtor.bind(this, this.selectedInvoice.debtor._id)
      );
    } else {
      let dialogRef = this.dialog.open(ObservePayerComponent);
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.overlayService.startConfirmation(
            {
              riskComment: result.riskComment,
              _id: debtor._id,
              isObserve: true,
            },
            null,
            'resultOnly',
            'edit',
            null,
            EvaluationsActions.SavingNote,
            null,
            EvaluationSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: 'Pagador en observación',
              },
            },
            this.fetchDebtor.bind(this, this.selectedInvoice.debtor._id)
          );
        }
      });
    }
  }

  deleteNote(debtor: any) {
    this.overlayService.startConfirmation(
      {
        debtor: debtor._id,
        _id: debtor._id,
      },
      null,
      'question',
      'delete',
      null,
      EvaluationsActions.SavingNote,
      null,
      EvaluationSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'Nota eliminada',
        },
      },
      this.fetchDebtor.bind(this, this.selectedInvoice.debtor._id)
    );
  }

  editContacts(debtor: any) {
    const dialogRef = this.dialog.open(AddContactsEmailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: { debtor },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchDebtor(this.selectedInvoice.debtor._id);
      }
    });
  }

  editValidContacts($event) {
    this.addContactMode = 'edit';
    let contactInfo;
    let data = {
      mode: 'edit',
    };
    contactInfo = this.contacts?.debtorContacts.validatedContacts.find(
      (con) => con.email == $event.email
    );
    let contact = this.contactInformation
      ? this.contactInformation
      : contactInfo;
    data = { ...data, ...contact };

    let dialogRef = this.openDialogHelper(AddContactDialogComponent, {
      ruc: this.debtor.companyRuc,
      debtor: this.debtor._id,
      data,
      showContactTypes: true,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      this.openModalContact = true;
      if (result) {
        this.contactInformation = { ...result };

        this.overlayService.startConfirmation(
          { ...result, debtor: this.debtor._id },
          {
            component: AddContactConfirmationComponent,
            entity: { ...result },
          },
          'details',
          'edit',
          null,
          InvoicesActions.SaveContact,
          `invoices/${this.selectedInvoice._id}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onConfirm: {
              title: 'Confirmación datos del contacto',
            },
            onSuccess: {
              showCheck: true,
              title: 'Se editó el contacto correctamente',
            },
          },
          this.getContacts.bind(this)
        );
      } else {
        this.openModalContact = false;
      }
    });
  }

  addContact(data?) {
    this.addContactMode = 'add';
    let dialogRef = this.openDialogHelper(AddContactDialogComponent, {
      ruc: this.debtor.companyRuc,
      debtor: this.debtor._id,
      data,
      showContactTypes: this.loggedInUserRole == 'risk analyst' ? true : false,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      this.openModalContact = true;
      // debugger;
      if (result) {
        this.contactInformation = { ...result };

        result.debtor = this.debtor._id;

        this.overlayService.startConfirmation(
          { ...result, _id: this.debtor._id },
          {
            component: AddContactConfirmationComponent,
            entity: { ...result },
          },
          'details',
          'create',
          null,
          InvoicesActions.SaveContact,
          `invoices/${this.selectedInvoice._id}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onConfirm: {
              title: 'Confirmación datos del contacto',
            },
            onSuccess: {
              showCheck: true,
              title: 'Se agregó el contacto correctamente',
            },
          },
          this.getContacts.bind(this)
        );
      } else {
        this.openModalContact = false;
      }
    });
  }

  editTelegram(debtor: any) {
    this.setConditionsModal = true;

    let dialogRef = this.dialog.open(AddtelegramComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      data: { debtor: this.telegramModalData || debtor },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event !== 'Cancel') {
          this.telegramModalData = {
            _id: debtor._id,
            companyDetail: result.companyDetail,
            detailUpdatedAt: new Date(),
          };

          this.overlayService.startConfirmation(
            {
              _id: debtor._id,
              companyDetail: result.companyDetail,
            },
            {
              component: TelegramConfirmationComponent,
              entity: {
                companyDetail: result.companyDetail,
                detailUpdatedAt: new Date(),
              },
            },
            'details',
            'edit',
            null,
            EvaluationsActions.SavingNote,
            null,
            EvaluationSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: 'Información actualizada',
              },
            },
            this.fetchDebtor.bind(this, this.selectedInvoice.debtor._id)
          );
        } else {
          this.telegramModalData = null;
        }
      }
    });
  }

  saveSector(data: any) {
    if (data.debtor && data.sector) {
      this.debtorService
        .updateOneField(this.debtor._id, { sector: data.sector })
        .subscribe((res) => {
          this.openSaveResult();
          this.fetchDebtor(this.selectedInvoice.debtor._id);
        });
    }
  }

  saveActivity(data: any) {
    if (data.debtor && data.activity) {
      this.debtorService
        .updateOneField(this.debtor._id, { activity: data.activity })
        .subscribe((res) => {
          this.openSaveResult();
          this.fetchDebtor(this.selectedInvoice.debtor._id);
        });
    }
  }

  saveRiskLevel(data: any) {
    if (data.debtor && data.riskLevel) {
      this.debtorService
        .updateOneField(this.debtor._id, { riskLevel: data.riskLevel })
        .subscribe((res) => {
          this.openSaveResult();
          this.fetchDebtor(this.selectedInvoice.debtor._id);
        });
    }
  }

  newEvaluation(debtorData: any) {
    let debtor = {
      debtor: debtorData._id,
    };
    this.newEvaluationLoading = true;
    this.debtorService.createEvaluation(debtor).subscribe((data) => {
      if (data) {
        this.newEvaluationLoading = false;
        setTimeout(() => {
          this.fetchDebtor(this.selectedInvoice.debtor._id);
        }, 700);
      }
    });
  }

  evaluationDetail(evaluation: any) {
    this.router.navigateByUrl(`/risk-pending-evaluations/${evaluation._id}`);
  }

  openSaveResult() {
    const portalComponent = new ComponentPortal(SaveResultComponent);

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      panelClass: ['full-width', 'reset-display'],
    });
    const componentRef = overlayRef.attach(portalComponent);
    componentRef.instance.success = { savingSucceeded: true };
    componentRef.instance.resultConfig = {
      onSuccess: {
        title: 'Cambio realizado',
        showIcon: true,
        iconName: 'big-check-mark-icon',
      },
    };

    componentRef.instance.onDone.subscribe(() => {
      componentRef.destroy();
      overlayRef.dispose();
    });
  }
  updateTabSelected($event) {
    this.selected = $event;
    this.setLocalStorage();
  }

  setLocalStorage() {
    localStorage.setItem('tab_operation_detail', this.selected.toString());
    //localStorage.setItem('tab_transactions', this.selected.toString())
  }
  getLocalStorage() {
    let selectTab = localStorage.getItem('tab_operation_detail');
    if (selectTab !== null) {
      this.selected = Number(selectTab);
    }
  }

  validate($event) {
    // if (this.isValidating) return;
    let contact;
    let data = {
      mode: 'validate',
    };
    if ($event.isValid) {
      this.isValidating = true;

      contact = this.contacts?.debtorContacts.unValidatedContacts.find(
        (con) => con.email == $event.email
      );
      data = { ...data, ...contact };
      let dialogRef = this.openDialogHelper(AddContactDialogComponent, {
        ruc: this.debtor.companyRuc,
        debtor: this.debtor._id,
        data,
        showContactTypes:
          this.loggedInUserRole == 'risk analyst' ? true : false,
      });

      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          this.contactInformation = { ...result };
          result._id = contact._id;
          result.user = contact.user;
          result.debtor = this.debtor._id;
          result.companyRuc = this.debtor.companyRuc;
          this.overlayService.startConfirmation(
            { ...result, debtor: this.debtor._id },
            {
              component: AddContactConfirmationComponent,
              entity: { ...result },
            },
            'details',
            'edit',
            null,
            InvoicesActions.ValidContact,
            `invoices/${this.selectedInvoice._id}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onConfirm: {
                title: 'Confirmación datos del contacto',
              },
              onSuccess: {
                showCheck: true,
                title: 'Se validó el contacto correctamente',
              },
            },
            this.getContacts.bind(this)
          );

          // this.service
          //   .validateContact(result,)
          //   .subscribe((res: any) => {
          //     this.contacts.debtorContacts = res.debtorContacts;
          //     this.isValidating = false;
          //     this.contactInformation = ""
          //   });
        }
      });
    } else {
      this.isValidating = false;
      contact = this.contacts?.debtorContacts.validatedContacts.find(
        (con) => con.email == $event.email
      );

      const callback = () => {
        this.getContacts();
      };

      data = { ...data, ...contact };
      let dialogRef = this.openDialogHelper(
        RejectContactDialogComponent,
        {
          ruc: this.debtor.companyRuc,
          debtor: this.debtor._id,
          data,
          showContactTypes:
            this.loggedInUserRole == 'risk analyst' ? true : false,
        },
        false
      );
      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          this.overlayService.startConfirmation(
            {
              ...contact,
              _id: this.debtor._id,
              companyRuc: this.debtor.companyRuc,
              rejectionReason: result,
            },
            {
              component: AddContactConfirmationComponent,
              entity: { ...contact },
            },
            'details',
            'delete',
            null,
            InvoicesActions.SaveContact,
            `invoices/${this.selectedInvoice._id}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onConfirm: {
                title: 'Quitar de contactos validados',
              },
              onSuccess: {
                showCheck: true,
                title: 'Se invalidó el contacto',
              },
            },
            callback.bind(this)
          );
        }
      });

      // contact = this.contacts?.debtorContacts.validatedContacts.find(
      //   (con) => con.email == $event.email
      // );
      // this.service
      //   .unvalidateContact(contact, this.debtor._id, this.debtor.companyRuc)
      //   .subscribe((res: any) => {
      //     this.contacts.debtorContacts = res.debtorContacts;
      //     this.isValidating = false;
      //   });
    }
  }

  applyValidation(targetContact, validating, result) {
    if (validating) {
      if (!result?._id) return;
      this.contacts.debtorContacts.unValidatedContacts =
        this.contacts.debtorContacts.unValidatedContacts.filter(
          (con) => con.email != targetContact.email
        );
      this.contacts.debtorContacts.validatedContacts.push(targetContact);
    } else {
      if (result.nModified == 0) return;
      this.contacts.debtorContacts.validatedContacts =
        this.contacts.debtorContacts.validatedContacts.filter(
          (con) => con.email != targetContact.email
        );
      if (targetContact?.createdBy) {
        if (targetContact?.createdBy.role != this.loggedInUserRole) {
          this.contacts.debtorContacts.unValidatedContacts.push(targetContact);
        }
      } else {
        this.contacts.debtorContacts.unValidatedContacts.push(targetContact);
      }
    }
  }

  getObservations(data) {
    const clientId = data?.issuer ? data.issuer._id : data;

    this.service.getObservations(clientId).subscribe((res) => {
      if (res) {
        this.userObservations = res;
      }
    });
  }

  hideDownload() {
    if (this.displayDownload) {
      this.displayDownload = false;
    }
  }
  downloadInvoice(invoiceId) {
    if (
      this.loggedInUserRole === Roles.ADMIN ||
      this.loggedInUserRole === Roles.SUPER_ADMIN
      // !this.selectedInvoice.isConfirming
    ) {
      this.displayDownload = true;
    }
    // else {
    //   this.downloadInvoiceFile(invoiceId);
    // }
  }

  downloadInvoiceFile(invoiceId) {
    this.spinner.show();
    this.displayDownload = false;

    this.service.downloadInvoiceFiles(invoiceId).subscribe((result) => {
      if (result.file) {
        const fileName = `invoice_${new Date().toISOString()}_${
          this.selectedInvoiceId
        }.zip`;
        saveAs(result.file, fileName);
      } else {
        window.alert('¡No se encuentra el archivo!');
      }

      this.spinner.hide();
    });
  }

  downloadFormalDocument(type: string, invoiceId) {
    this.spinner.show();
    this.displayDownload = false;
    this.service
      .downloadInvoiceDocument(invoiceId, type)
      .subscribe((result) => {
        const codes = this.selectedInvoice.physicalInvoices
          .map((p: any) => p.code)
          .join(',');
        const operationType = this.selectedInvoice.isConfirming
          ? 'Confirming'
          : 'Factoring';

        if (result) {
          let fileName = '';

          switch (type) {
            case 'summary_sheet':
              fileName = `Hoja de Resumen - Condiciones de ${operationType} - ${codes}.pdf`;
              break;
            case 'transfer_rights':
              fileName = `Carta de cesión de derechos - ${codes}.pdf`;
              break;
            case 'invoicing':
              fileName = `${this.selectedInvoice?.issuer?.companyName?.toUpperCase()} - ${codes}.zip`;
              break;
          }

          var ab = new ArrayBuffer(result.file.data.length);
          var view = new Uint8Array(ab);
          for (var i = 0; i < result.file.data.length; ++i) {
            view[i] = result.file.data[i];
          }

          const f = new Blob([new Uint8Array(result.file.data)]);

          saveAs(f, fileName);
        }

        this.spinner.hide();
      });
  }

  rejectInvoice() {
    const dialogRef = this.openDialogHelper(
      MotiveDialogComponent,
      {
        data: this.rejectionReason,
        type: 'invoice',
      },
      false
    );

    this.connectDialogListeners(dialogRef, (result) => {
      if (result?.event == 'cancel' || !result) {
        this.showMotiveDialog = false;
        this.rejectionReason = '';
        return;
      }
      this.showMotiveDialog = true;
      this.rejectionReason = result?.data;
      this.overlayService.startConfirmation(
        {
          status: 'disapproved',
          _id: this.selectedInvoiceId,
          rejectionReason: this.rejectionReason,
        },
        {
          component: InvoiceMotiveConfirmationComponent,
          entity: { rejectionReason: this.rejectionReason },
        },
        'details',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'La factura se ha desaprobado',
          },
        },
        this.reFetchInvoice.bind(this)
      );
    });
  }

  isInvoiceReady2(invoice) {
    const objIndexPayer = this.blockersInvoiceForSale.findIndex(
      (obj) => obj.item === 'riskObservePayer'
    );
    const objIndexCheck = this.blockersInvoiceForSale.findIndex(
      (obj) => obj.item === 'checkList'
    );
    const objIndexWarranty = this.blockersInvoiceForSale.findIndex(
      (obj) => obj.item === 'confimingWarranty'
    );
    const objIndexDuration = this.blockersInvoiceForSale.findIndex(
      (obj) => obj.item === 'minimunDuration'
    );
    // const objIndexCavali = this.blockersInvoiceForSale.findIndex(
    //   (obj) => obj.item === 'cavaliRealiableNotication'
    // );
    const objIndexCredit = this.blockersInvoiceForSale.findIndex(
      (obj) => obj.item === 'pendingCreditNote'
    );
    const objIndexDesembolso = this.blockersInvoiceForSale.findIndex(
      (obj) => obj.item === 'desembolsoEmail'
    );

    if (
      this.loggedInUserRole == 'account manager supervisor' &&
      this.selectedInvoice.isConfirming == false
    ) {
      if (
        invoice.checklist
          .find((el) => el.type === 'risk')
          .subChecks.find((el) => el.type === 'coactive debt').status ===
        'exception'
      ) {
        this.blockersInvoiceForSale[objIndexDesembolso].value = true;
      } else {
        this.blockersInvoiceForSale[objIndexDesembolso].value = false;
      }
    }

    if (invoice.checklist) {
      let check = invoice.checklist.every(
        (c) => c.status == 'approved' || c.status == 'exception'
      );
      this.blockersInvoiceForSale[objIndexCheck].value = !check;
    }

    if (
      invoice.physicalInvoices.some(
        (inv) =>
          inv.cavaliPayerResponse === 'rectified compliance' && !inv.creditNote
      )
    ) {
      this.blockersInvoiceForSale[objIndexCredit].value = true;
    } else {
      this.blockersInvoiceForSale[objIndexCredit].value = false;
    }

    if (invoice.debtor?.isObserve) {
      this.blockersInvoiceForSale[objIndexPayer].value = true;
    } else {
      this.blockersInvoiceForSale[objIndexPayer].value = false;
    }

    if (
      invoice.isConfirming &&
      Number(invoice.reservationPercentage) > 0 &&
      invoice.evaluationDays < 0
    ) {
      this.blockersInvoiceForSale[objIndexWarranty].value = true;
    } else if (!invoice.isConfirming && invoice.evaluationDays < 0) {
      this.blockersInvoiceForSale[objIndexWarranty].value = true;
    } else {
      this.blockersInvoiceForSale[objIndexWarranty].value = false;
    }

    if ((invoice.forecastedDuration || invoice.minimumDuration) < 15) {
      this.blockersInvoiceForSale[objIndexDuration].value = true;
    } else {
      this.blockersInvoiceForSale[objIndexDuration].value = false;
    }

    // if (
    //   invoice.physicalInvoices.every(
    //     (inv) => inv.cavaliReliableNotification === 'done'
    //   )
    // ) {
    //   this.blockersInvoiceForSale[objIndexCavali].value = false;
    // } else {
    //   this.blockersInvoiceForSale[objIndexCavali].value = true;
    // }
  }

  isInvoiceReady(invoice) {
    return invoice.status === 'for sale' ||
      invoice.status === 'sale closed' ||
      invoice.status === 'partially sold'
      ? true
      : invoice.checklist &&
          invoice.status === 'pending confirmation' &&
          invoice.checklist.every(
            (c) => c.status == 'approved' || c.status == 'exception'
          ) &&
          invoice.physicalInvoices.every((inv) =>
            inv.cavaliPayerResponse === 'rectified compliance'
              ? inv.creditNote
              : true
          ) &&
          invoice.evaluation.status !== 'rejected' &&
          invoice.evaluation.lastEvaluationDays <= 60 &&
          invoice.evaluation.hasOwnProperty('status') &&
          !invoice.debtor?.isObserve &&
          (invoice.isConfirming
            ? Number(invoice.reservationPercentage) == 0 ||
              invoice.evaluationDays >= 0
            : invoice.evaluationDays >= 0);
  }

  putInvoiceForSale(invoice, result?) {
    if (this.blockersInvoiceForSale.every((item) => !item.value)) {
      let dialogRef = this.openDialogHelper(
        InvoiceForSaleDialogComponent,
        invoice,
        false
      );

      this.connectDialogListeners(dialogRef, (result: any) => {
        if (result) {
          let invoicesTelgram = [this.selectedInvoice];

          if (result.selectedInvoices && result.selectedInvoices.length)
            invoicesTelgram = invoicesTelgram.concat(result.selectedInvoices);

          this.service
            .getTelegramMessage(invoicesTelgram, result.onSaleTime)
            .subscribe((data: any) => {
              this.scheduleInvoice = result;
              this.openModalSchedule = true;

              result.telegramMessage = data.telegram;

              if (result.event === 'Cancel') {
                this.deactivateModalSchedule();
                return;
              }

              const callbackSchedule = () => {
                this.reFetchInvoice();
                this.deactivateModalSchedule();
              };

              this.overlayService.startConfirmation(
                {
                  status: 'for sale',
                  _id: this.selectedInvoiceId,
                  onSaleDate: result.onSaleDate,
                  onSaleSlot: result.onSaleSlot,
                  invoiceIds: result.selectedInvoices?.map((inv) => {
                    return { _id: inv._id };
                  }),
                  telegramMessage: result.telegramMessage,
                },
                {
                  component: ConfirmInvoiceForSaleComponent,
                  entity: { ...this.selectedInvoice, ...result },
                },
                'details',
                'edit',
                null,
                InvoicesActions.Saving,
                `invoices/${this.selectedInvoiceId}`,
                InvoiceSelectors.savingSuccessStateById,
                {
                  onSuccess: {
                    showCheck: true,
                    title: 'La factura ha sido enviada a las oportunidades.',
                  },
                  onConfirm: {
                    title: '¿Estás seguro?',
                  },
                },
                callbackSchedule.bind(this)
              );
            });
        } else {
          this.openModalSchedule = false;
        }
      });
    }
  }

  deactivateModalSchedule() {
    this.openModalSchedule = false;
  }

  cancelSale() {
    this.overlayService.startConfirmation(
      { status: 'pending confirmation', _id: this.selectedInvoiceId },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La factura se ha retirado',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  payAdvanceToClient(invoice) {
    if (
      this.isInvoiceReady(invoice) &&
      !invoice.advancePaymentDate &&
      invoice.emailLog.some((e: any) => e.type === 'DESEMBOLSO')
    ) {
      let dialogRef = this.openDialogHelper(PayClientAdvanceDialogComponent);
      this.isAdvanceModalOpened = true;
      this.connectDialogListeners(dialogRef, (result) => {
        this.isAdvanceModalOpened = false;

        if (result) {
          const confirmationDialog = this.dialog.open(
            NewConfirmationModalComponent,
            {
              maxWidth: '444px',
              maxHeight: '100vh',
              height: 'auto',
              autoFocus: false,
              disableClose: true,
              data: {
                question: `¿Estás seguro que la transferencia de la operación ya fue realizada el ${moment(
                  result.advancePaymentDate,
                  'YYYY-MM-DD'
                ).format('DD/MM/YYYY')}?`,
                warning: `Esta acción será notificada al cliente por Whatsapp y en la plataforma.`,
              },
            }
          );

          confirmationDialog.afterClosed().subscribe((res: any) => {
            if (res) {
              this.spinner.show();

              this.service
                .update({
                  _id: this.selectedInvoice._id,
                  advancePaymentDate: result.advancePaymentDate,
                })
                .subscribe(
                  (response) => {
                    this.spinner.hide();
                    this.toaster.open({
                      text: response
                        ? 'Se envió el mensaje al cliente'
                        : 'Hubo un error, intentarlo más tarde',
                      caption: response ? 'Adelanto pagado' : 'Error',
                      duration: 4000,
                      autoClose: true,
                      position: 'top-right',
                      type: response ? 'success' : 'danger',
                      preventDuplicates: true,
                    });
                  },
                  (error) => {
                    this.spinner.hide();
                    if (error?.error == 'INVOICE.IS_ALREADY_COLLECTED') {
                      this.toaster.open({
                        text: 'El adelanto de la operación ya se encontraba realizado.',
                        caption: 'Error',
                        autoClose: false,
                        position: 'top-right',
                        type: 'danger',
                        preventDuplicates: true,
                      });
                    } else {
                      this.toaster.open({
                        text: 'Hubo un error, intentarlo más tarde.',
                        caption: 'Error',
                        autoClose: false,
                        position: 'top-right',
                        type: 'danger',
                        preventDuplicates: true,
                      });
                    }
                  }
                );
            } else {
              this.payAdvanceToClient(invoice);
            }
          });
        }
      });
    }
  }

  closeSale(invoice) {
    const confirmationDialog = this.dialog.open(NewConfirmationModalComponent, {
      maxWidth: '444px',
      maxHeight: '100vh',
      height: 'auto',
      autoFocus: false,
      disableClose: true,
      data: {
        question: `¿Estás seguro que quieres cerrar la venta?`,
        warning:
          invoice.availableBalancePercentage != '0.00'
            ? `Recuerda que el monto de inversión faltante será <span style="font-weight:400;">asumido por Finsmart</span> y esta operación será retirada de la venta.`
            : 'Esta operación será retirada de la venta.',
      },
    });

    confirmationDialog.afterClosed().subscribe((res: any) => {
      if (res) {
        this.spinner.show();

        this.service
          .update({
            _id: this.selectedInvoice._id,
            status: InvoiceStatus['Awaiting Collection'],
          })
          .subscribe(
            (response) => {
              this.spinner.hide();

              this.toaster.open({
                text: response
                  ? 'Se retiró la factura de la venta'
                  : 'Hubo un error, intentarlo más tarde',
                caption: response ? 'Se cerró la venta' : 'Error',
                duration: 4000,
                autoClose: true,
                position: 'top-right',
                type: response ? 'success' : 'danger',
                preventDuplicates: true,
              });
            },
            (error) => {
              this.toaster.open({
                text: 'Hubo un error, intentarlo más tarde',
                caption: 'Error',
                autoClose: false,
                position: 'top-right',
                type: 'danger',
                preventDuplicates: true,
              });
            }
          );
      }
    });
  }

  collectInvoice(paymentDate) {
    let dialogRef = this.openDialogHelper(CollectInvoiceDialogComponent, {
      paymentDate,
      partialPaymentDate: this.selectedInvoice?.partialPaymentDate,
      invoice: this.selectedInvoice,
    });
    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.overlayService.startConfirmation(
          { ...result, _id: this.selectedInvoiceId },
          null,
          'resultOnly',
          'edit',
          null,
          InvoicesActions.Saving,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          null,
          this.reFetchInvoice.bind(this)
        );
      }
    });
  }
  partialPayment(paymentDate, data?) {
    this.paymentDate = paymentDate;
    let dialogRef = this.openDialogHelper(PartialPaymentDialogComponent, {
      paymentDate,
      data,
    });
    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.dataPartialPayment = { ...result };
        this.partialPaymentModal = true;
        this.service
          .getPartialPayment(this.selectedInvoiceId, result)
          .subscribe((data: any) => {
            if (data) {
              data.invoice = this.selectedInvoice;
              data.invoice.actualPaymentDate = result.partialPaymentDate;
              const callback = () => {
                this.reFetchInvoice();
                this.partialPaymentModal = !this.partialPayment;
              };
              this.overlayService.startConfirmation(
                { ...result, _id: this.selectedInvoiceId },
                {
                  component: PartialPaymentConfirmationComponent,
                  entity: data,
                },
                'details',
                'edit',
                null,
                InvoicesActions.Saving,
                `invoices/${this.selectedInvoiceId}`,
                InvoiceSelectors.savingSuccessStateById,
                {
                  onConfirm: {
                    title: '¿Estás seguro?',
                  },
                  onSuccess: {
                    showCheck: true,
                    title: 'Ajuste realizado',
                  },
                },
                callback.bind(this)
              );
            }
          });
      }
    });
  }

  finalizeInvoice() {
    if (
      !this.selectedInvoice.invoicingConfiguration ||
      this.selectedInvoice.invoicingConfiguration === 'noInvoicing' ||
      this.selectedInvoice.invoicingStatus === 'delay'
    ) {
      this.overlayService.startConfirmation(
        {
          status: 'finalized',
          _id: this.selectedInvoiceId,
          homeUrl: environment.clientUrl,
        },
        null,
        'question',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'La factura ha sido finalizada',
          },
        },
        this.reFetchInvoice.bind(this)
      );
    } else {
      this.overlayService.startConfirmation(
        {
          status: 'finalized',
          _id: this.selectedInvoiceId,
          homeUrl: environment.clientUrl,
        },
        {
          component: InvoicingConfirmationComponent,
          entity: { invoice: this.selectedInvoice },
        },
        'details',
        'accept',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'La factura ha sido finalizada',
          },
        },
        this.reFetchInvoice.bind(this)
      );
    }
  }

  addInvoicingConcept() {
    if (!this.isAdditionalInvoicingDisabled) {
      let dialogRef = this.openDialogHelper(AddInvoicingConceptComponent, {
        invoice: this.selectedInvoice,
      });
      this.connectDialogListeners(dialogRef, (additionalInvoicings) => {
        if (additionalInvoicings) {
          this.overlayService.startConfirmation(
            {
              _id: this.selectedInvoiceId,
              additionalInvoicings,
            },
            {
              component: InvoicingConfirmationComponent,
              entity: { invoice: this.selectedInvoice, additionalInvoicings },
            },
            'details',
            'accept',
            null,
            InvoicesActions.SaveAdditionalnvoicings,
            `invoices/${this.selectedInvoiceId}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: 'Se emitieron las facturas correctamente',
              },
            },
            this.reFetchInvoice.bind(this)
          );
        }
      });
    }
  }

  navigateToInvest(invoice: any) {
    const navigationExtra: NavigationExtras = {
      state: { invoice },
    };

    this.router.navigate(['/financial-transactions/invest'], navigationExtra);
  }

  getValidationTitle(invoice, type?) {
    //this.isInvoiceReady2(invoice)
    if (
      this.isInvoiceReady(invoice) &&
      (type === 'advancePaymentDate'
        ? invoice.emailLog.some((e: any) => e.type === 'DESEMBOLSO')
        : true) &&
      (type !== 'advancePaymentDate'
        ? (invoice.forecastedDuration || invoice.minimumDuration) > 14 &&
          (this.debtor ? this.debtor?.stats?.expiredInvoiceCount == 0 : false)
        : true)
    ) {
      return 'title c-pointer';
    } else {
      return 'title red-filter .no-cursor';
    }
  }

  getValidationClass(invoice, type?) {
    if (
      this.isInvoiceReady(invoice) &&
      (type === 'advancePaymentDate'
        ? invoice.emailLog.some((e: any) => e.type === 'DESEMBOLSO')
        : true) &&
      (type !== 'advancePaymentDate'
        ? (invoice.forecastedDuration || invoice.minimumDuration) > 14 &&
          (this.debtor ? this.debtor?.stats?.expiredInvoiceCount == 0 : false)
        : true)
    ) {
      return 'c-pointer';
    } else {
      return 'red-filter .no-cursor';
    }
  }

  getInvestedPercentage(availableBalancePercentage) {
    availableBalancePercentage = availableBalancePercentage
      ? availableBalancePercentage
      : 100.0;
    return Big(100).minus(availableBalancePercentage).toFixed(2);
  }

  goBack() {
    this.location.back();
  }

  send($event, isResend?) {
    if ($event.type) {
      if (
        (this.loggedInUserRole === Roles.ACCOUNT_MANGER ||
          this.loggedInUserRole === Roles.ACCOUNT_MANAGER_SUPERVISOR) &&
        $event.type !== 'CORREO_B'
      ) {
        return;
      }
      let data: any = {
        type: $event.type,
        isExternal: $event.isExternal,
        file: $event.file,
        notes: $event.notes,
        _id: this.selectedInvoiceId,
        currency: this.selectedInvoice.currency,
        to: $event.sendTo,
        cc: $event.cc,
        subject: $event.subject,
        observations: $event.observations,
      };
      // data = this.dataEmail ? this.dataEmail : data;
      let dialogRef;
      let supplier = this.selectedInvoice.isConfirming
        ? this.selectedInvoice.supplier._id
        : this.selectedInvoice.issuer._id;

      if (
        $event.type === 'CORREO_B' ||
        $event.type === 'OPERATION_START' ||
        $event.type === 'CONFORMIDAD_CAVALI'
      ) {
        document.body.classList.add('no-scroll');
        const filtersArray = [];
        let statusFilter =
          'status:in:approved,status:in:configured,status:in:pending confirmation';
        let sunatProcess = `isSunatProcess:eq:${this.selectedInvoice.isSunatProcess}`;
        let confirmingProcess = `isConfirming:eq:${this.selectedInvoice.isConfirming}`;
        let debtor = `debtor:eq:${this.selectedInvoice.debtor._id}`;
        let issuer = `issuer:eq:${supplier}`;
        let supplierFilter = `supplier:eq:${supplier}`;

        filtersArray.push(statusFilter);
        filtersArray.push(sunatProcess);
        filtersArray.push(confirmingProcess);
        filtersArray.push(debtor);
        if (this.selectedInvoice.isConfirming) {
          filtersArray.push(supplierFilter);
        } else {
          filtersArray.push(issuer);
        }

        this.service
          .getOperationsFiltered(filtersArray)
          .pipe(
            map((res) => Object.values(res['entities'])),
            map((res) => {
              return this.sortArray(res);
            })
          )
          .subscribe((operations: any) => {
            if ($event.type === 'CORREO_B') {
              operations = this.filterOperations(
                this.selectedInvoice,
                operations
              );
            }
            dialogRef = this.openDialogHelper(
              ChooseEmailsDialogComponent,
              {
                data: this.dataEmail ? this.dataEmail : data,
                debtor: this.selectedInvoice.debtor.companyName.toUpperCase(),
                issuer: this.selectedInvoice.isConfirming
                  ? this.selectedInvoice.supplier.companyName.toUpperCase()
                  : this.selectedInvoice.issuer.companyName.toUpperCase(),
                isConfirming: this.selectedInvoice.isConfirming,
                isSunatProcess: this.selectedInvoice.isSunatProcess || false,
                selectedInvoice: this.selectedInvoice,
                operations: operations,
              },
              true,
              '100%'
            );

            dialogRef.beforeClosed().subscribe((res) => {
              this.dataEmail = '';
            });

            this.connectDialogListeners(dialogRef, (result) => {
              document.body.classList.remove('no-scroll');
              if (result) {
                if (result.event === 'Cancel') {
                  this.dataEmail = '';
                  return;
                }
                if (this.dataEmail) {
                  this.dataEmail.cc = result.cc;
                  this.dataEmail.to = result.to;
                }
                this.sendEmail = true;
                this.dataEmail = this.dataEmail ? this.dataEmail : result;
                const callbackEmail = () => {
                  this.reFetchInvoice();
                  this.resetEmailSend();
                };

                result.isConfirming = this.selectedInvoice.isConfirming;

                this.overlayService.startConfirmation(
                  { result: result, _id: this.selectedInvoiceId },
                  {
                    component: EmailPreviewComponent,
                    entity: result,
                  },
                  'details',
                  'edit',
                  null,
                  InvoicesActions.SavingEmail,
                  `invoices/${this.selectedInvoiceId}`,
                  InvoiceSelectors.savingSuccessStateById,
                  {
                    onSuccess: {
                      showCheck: true,
                      title: 'Correo enviado!',
                    },
                    onConfirm: {
                      title: EmailType[$event.type],
                    },
                  },
                  callbackEmail.bind(this),
                  '60%',
                  'Enviar',
                  'Regresar'
                );
              }
            });
          });
      } else if ($event.type === 'INVOICE_CHANGE') {
        if (isResend) {
          this.isEditorFlow = false;
          let emailData: any = {};
          emailData.cc = this.selectedInvoice?.accountManager?.email;
          emailData.subject = `Cambio de Datos | ${this.selectedInvoice.debtor.companyName.toUpperCase()} | ${this.selectedInvoice.physicalInvoices
            .map((i) => i.code)
            .join(',')}`;
          emailData.to = this.selectedInvoice?.issuer?.email;
          emailData.isConfirming = this.selectedInvoice.isConfirming;
          emailData.type = 'INVOICE_CHANGE';
          emailData.isExternal = true;
          emailData._id = this.selectedInvoice._id;

          this.sendEmail = true;

          this.dataEmail = {
            ...emailData,
            paymentDate: this.selectedInvoice.paymentDate,
            physicalInvoices: [],
          };

          const callbackEmail = () => {
            this.reFetchInvoice();
            this.resetEmailSend();
          };

          this.overlayService.startConfirmation(
            { result: emailData, _id: this.selectedInvoiceId },
            {
              component: EmailPreviewComponent,
              entity: emailData,
            },
            'details',
            'edit',
            null,
            InvoicesActions.SavingEmail,
            `invoices/${this.selectedInvoiceId}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: 'Correo enviado!',
              },
              onConfirm: {
                title: EmailType[$event.type],
              },
            },
            callbackEmail.bind(this),
            '60%',
            'Enviar',
            'Regresar'
          );
        } else {
          if (this.isEditorFlow) {
            data = this.selectedInvoice;

            this.dataEmail = { ...this.selectedInvoice, ...this.dataEmail };

            dialogRef = this.openDialogHelper(
              EditAmountsInvoceDialogComponent,
              {
                invoice: this.dataEmail ? this.dataEmail : data,
                wasEdited: true,
              },
              false,
              '500px'
            );
            dialogRef.beforeClosed().subscribe((res) => {
              this.dataEmail = '';
            });

            this.connectDialogListeners(dialogRef, (result) => {
              if (result) {
                if (result.event === 'Cancel') {
                  this.dataEmail = '';
                  return;
                }

                let emailData: any = {};
                emailData.cc = this.selectedInvoice?.accountManager?.email;
                emailData.subject = `Cambio de Datos | ${this.selectedInvoice.debtor.companyName.toUpperCase()} | ${this.selectedInvoice.physicalInvoices
                  .map((i) => i.code)
                  .join(',')}`;
                emailData.to = this.selectedInvoice?.issuer?.email;
                emailData.isConfirming = this.selectedInvoice.isConfirming;
                emailData.type = 'INVOICE_CHANGE';
                emailData.isExternal = true;
                emailData._id = this.selectedInvoice._id;

                if (result.paymentDate !== this.selectedInvoice.paymentDate) {
                  emailData.paymentDate = result.paymentDate;
                }

                emailData.physicalInvoices = result.physicalInvoices.filter(
                  (p: any) =>
                    this.selectedInvoice.physicalInvoices.find(
                      (pi: any) => pi.code == p.code
                    )?.netAmount !== p.netAmount
                );

                this.sendEmail = true;

                this.dataEmail = {
                  ...emailData,
                  paymentDate:
                    result.paymentDate || this.selectedInvoice.paymentDate,
                  physicalInvoices: lodash
                    .cloneDeep(this.selectedInvoice.physicalInvoices)
                    .map((p: any) => {
                      if (result?.physicalInvoices?.length) {
                        const inv = result?.physicalInvoices.find(
                          (i: any) => i.code == p.code
                        );

                        if (inv) {
                          p.netAmount = inv.netAmount;
                        }
                      }
                      return p;
                    }),
                };

                const callbackEmail = () => {
                  this.reFetchInvoice();
                  this.resetEmailSend();
                };

                result.isConfirming = this.selectedInvoice.isConfirming;
                this.overlayService.startConfirmation(
                  { result: emailData, _id: this.selectedInvoiceId },
                  {
                    component: EmailPreviewComponent,
                    entity: emailData,
                  },
                  'details',
                  'edit',
                  null,
                  InvoicesActions.SavingEmail,
                  `invoices/${this.selectedInvoiceId}`,
                  InvoiceSelectors.savingSuccessStateById,
                  {
                    onSuccess: {
                      showCheck: true,
                      title: 'Correo enviado!',
                    },
                    onConfirm: {
                      title: EmailType[$event.type],
                    },
                  },
                  callbackEmail.bind(this),
                  '60%',
                  'Enviar',
                  'Regresar'
                );
              }
            });
          }
        }
      } else {
        dialogRef = this.openDialogHelper(EmailBoDialogComponent, {
          data: this.dataEmail ? this.dataEmail : data,
        });
        dialogRef.beforeClosed().subscribe((res) => {
          this.dataEmail = '';
        });

        this.connectDialogListeners(dialogRef, (result) => {
          if (result) {
            if (result.event === 'Cancel') {
              this.dataEmail = '';
              return;
            }
            if (this.dataEmail) {
              this.dataEmail.cc = result.cc;
              this.dataEmail.to = result.to;
            }
            this.sendEmail = true;
            this.dataEmail = this.dataEmail ? this.dataEmail : result;
            const callbackEmail = () => {
              this.reFetchInvoice();
              this.resetEmailSend();
            };

            result.isConfirming = this.selectedInvoice.isConfirming;
            this.overlayService.startConfirmation(
              { result: result, _id: this.selectedInvoiceId },
              {
                component: EmailPreviewComponent,
                entity: result,
              },
              'details',
              'edit',
              null,
              InvoicesActions.SavingEmail,
              `invoices/${this.selectedInvoiceId}`,
              InvoiceSelectors.savingSuccessStateById,
              {
                onSuccess: {
                  showCheck: true,
                  title: 'Correo enviado!',
                },
                onConfirm: {
                  title: EmailType[$event.type],
                },
              },
              callbackEmail.bind(this),
              '60%',
              'Enviar',
              'Regresar'
            );
          }
        });
      }
    }

    // if(this.selectedInvoice.isConfirming && this.isEditorFlow){
    //     this.dataEmail = {...this.selectedInvoice, ...this.dataEmail}

    //     dialogRef = this.openDialogHelper(EditAmountsInvoceDialogComponent,
    //       {
    //         invoice: this.dataEmail ? this.dataEmail : data,
    //         wasEdited: true
    //       },
    //       false,
    //       '500px');
    //     dialogRef.beforeClosed().subscribe((res) => {
    //       this.dataEmail = '';
    //     });

    //     this.connectDialogListeners(dialogRef, (result) => {
    //       if (result) {
    //         if (result.event === 'Cancel') {
    //           this.dataEmail = '';
    //           return;
    //         }

    //         let emailData: any = {};
    //         emailData.cc = this.selectedInvoice?.accountManager?.email;
    //         emailData.subject = `Cambio de Datos | ${this.selectedInvoice.debtor.companyName.toUpperCase()} | ${this.selectedInvoice.physicalInvoices.map(i => i.code).join(",")}`
    //         emailData.to = this.selectedInvoice?.issuer?.email;
    //         emailData.isConfirming = this.selectedInvoice.isConfirming;
    //         emailData.type = "INVOICE_CHANGE";
    //         emailData.isExternal = true;
    //         emailData._id = this.selectedInvoice._id;

    //         if (result.paymentDate !== this.selectedInvoice.paymentDate) {
    //           emailData.paymentDate = result.paymentDate;
    //         }

    //         emailData.physicalInvoices = result.physicalInvoices.filter((p: any) => this.selectedInvoice.physicalInvoices.find((pi: any) => pi.code == p.code)?.netAmount !== p.netAmount);

    //         this.sendEmail = true;

    //         this.dataEmail = {
    //           ...emailData,
    //           paymentDate: result.paymentDate || this.selectedInvoice.paymentDate,
    //           physicalInvoices: lodash.cloneDeep(this.selectedInvoice.physicalInvoices).map((p: any) => {
    //             if (result?.physicalInvoices?.length) {
    //               const inv = result?.physicalInvoices.find((i: any) => i.code == p.code);

    //               if (inv) {
    //                 p.netAmount = inv.netAmount;
    //               }
    //             }
    //             return p;
    //           })
    //         };

    //         const callbackEmail = () => {
    //           this.reFetchInvoice();
    //           this.resetEmailSend();
    //         };

    //         result.isConfirming = this.selectedInvoice.isConfirming;
    //         this.overlayService.startConfirmation(
    //           { result: emailData, _id: this.selectedInvoiceId },
    //           {
    //             component: EmailPreviewComponent,
    //             entity: emailData,
    //           },
    //           'details',
    //           'edit',
    //           null,
    //           InvoicesActions.SavingEmail,
    //           `invoices/${this.selectedInvoiceId}`,
    //           InvoiceSelectors.savingSuccessStateById,
    //           {
    //             onSuccess: {
    //               showCheck: true,
    //               title: 'Correo enviado!',
    //             },
    //             onConfirm: {
    //               title: EmailType[$event.type],
    //             },
    //           },
    //           callbackEmail.bind(this),
    //           '60%',
    //           'Enviar',
    //           'Regresar'
    //         );
    //       }
    //     });
    //   }
    // }
  }

  filterOperations(invoice, operations) {
    return operations.filter((operation) => {
      const currencyMatches = operation.currency === invoice.currency;
      const pendingConfirmation =
        operation.status === InvoiceStatus['Pending Confirmation'];
      const hasNoAdvancePaymentDate = !invoice.advancePaymentDate;
      return currencyMatches && hasNoAdvancePaymentDate && pendingConfirmation;
    });
  }

  checkDesembolsoStatus() {
    this.desembolsoFlag = this.selectedInvoice?.emailLog.some(
      (email) => email.type === 'DESEMBOLSO'
    );
    if (this.desembolsoFlag) {
      this.emailTitle = 'Reenviar correo de desembolso';
    } else {
      this.emailTitle = 'Correo de desembolso';
    }
  }

  sendDesembolsoEmail(invoice, data2?) {
    if (this.blockersInvoiceForSale.every((item) => !item.value)) {
      let data;
      let emailData;
      const desembolsoFlag = invoice?.emailLog.some(
        (email) => email.type === 'DESEMBOLSO'
      );
      if (desembolsoFlag) {
        //resend
        emailData = invoice.emailSent?.filter(
          (email) => email.type === 'DESEMBOLSO'
        );
      } else {
        //send
        emailData = invoice.emailReady?.filter(
          (email) => email.type === 'DESEMBOLSO'
        );
      }

      data = {
        type: 'DESEMBOLSO',
        isExternal: false,
        _id: invoice._id,
        currency: invoice.currency,
        to: emailData[0]?.to?.toString(),
        cc: emailData[0]?.cc?.toString(),
        subject: emailData[0]?.subject,
        observations: emailData[0]?.observations,
        file: emailData[0]?.file,
        notes: emailData[0]?.notes,
      };

      let dialogRef;

      dialogRef = this.openDialogHelper(EmailBoDialogComponent, {
        data: this.dataEmailDesembolso ? this.dataEmailDesembolso : data,
      });

      dialogRef.beforeClosed().subscribe((res) => {
        this.dataEmailDesembolso = '';
      });

      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          if (result.event === 'Cancel') {
            this.dataEmailDesembolso = '';
            return;
          }

          if (this.dataEmailDesembolso) {
            this.dataEmailDesembolso.cc = result.cc;
            this.dataEmailDesembolso.to = result.to;
          }

          this.sendEmailDesembolso = true;
          this.dataEmailDesembolso = this.dataEmailDesembolso
            ? this.dataEmailDesembolso
            : result;
          const callbackEmail = () => {
            this.reFetchInvoice();
            this.resetEmailDesembolsoSend();
          };

          result.isConfirming = invoice.isConfirming;
          this.overlayService.startConfirmation(
            { result: result, _id: invoice._id },
            {
              component: EmailPreviewComponent,
              entity: result,
            },
            'details',
            'edit',
            null,
            InvoicesActions.SavingEmail,
            `invoices/${this.selectedInvoiceId}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: 'Correo enviado!',
              },
              onConfirm: {
                title: EmailType['DESEMBOLSO'],
              },
            },
            callbackEmail.bind(this),
            '60%',
            'Enviar',
            'Regresar'
          );
        }
      });
    }
  }

  resetEmailDesembolsoSend() {
    this.sendEmailDesembolso = !this.sendEmailDesembolso;
    this.dataEmailDesembolso = '';
  }
  resetEmailSend() {
    this.sendEmail = !this.sendEmail;
    this.dataEmail = '';
  }
  approveInvoice() {
    this.overlayService.startConfirmation(
      { status: 'approved', _id: this.selectedInvoiceId },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.Saving,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'La factura se ha aprobado',
        },
      },
      this.reFetchInvoice.bind(this)
    );
  }

  operationDetailEvent(event: any) {
    switch (event.eventType) {
      case 'save': {
        switch (event.dataPoint) {
          case 'payerBankAccountEditor': {
            const payload = {
              _id: this.selectedInvoice._id,
              bankAccount: event.data._id,
            };
            this.selectedInvoice.bankAccount = event.data;

            this.openSaveResult();

            this.service.update(payload).subscribe((res) => {
              this.fetchInvoice(this.selectedInvoiceId);
            });

            break;
          }
          case 'supplierBankAccountEditor': {
            let payload: any = { _id: this.selectedInvoice._id };

            if (this.selectedInvoice.isConfirming) {
              payload.supplierBankAccount = event.data._id;
              this.selectedInvoice.supplierBankAccount = event.data;
            } else {
              payload.bankAccount = event.data._id;
              this.selectedInvoice.bankAccount = event.data;
            }

            this.openSaveResult();

            this.service.update(payload).subscribe((res) => {
              this.fetchInvoice(this.selectedInvoiceId);
            });

            break;
          }
          case 'ungroupingEditor': {
            this.ungroupModal = true;
            const entity = {
              codes: this.invoiceUngroupList.map((i) => i.code).toString(),
              _id: this.selectedInvoiceId,
              physicalInvoices: this.invoiceUngroupList,
            };

            const resultConfig = {
              onConfirm: {
                title: 'Estás a punto de desagrupar las siguientes facturas',
              },
              onSuccess: {
                showCheck: true,
                title: 'Se desagrupó la factura',
              },
              hasServerValue: true,
            };

            const callback = () => {
              this.reFetchInvoice();
              this.ungroupModal = false;
            };

            this.overlayService.startConfirmation(
              entity,
              {
                component: PhysicalInvoiceTableOrganism,
                entity: {
                  codes: this.invoiceUngroupList.map((i) => i.code).toString(),
                  _id: this.selectedInvoiceId,
                  physicalInvoices: this.invoiceUngroupList,
                  invoice: this.selectedInvoice,
                  investments: this.investments,
                },
              },
              'details',
              'edit',
              null,
              InvoicesActions.Saving,
              `invoices/${this.selectedInvoice._id}`,
              InvoiceSelectors.savingSuccessStateById,
              resultConfig,
              callback.bind(this)
            );

            break;
          }
          case 'paymentDateEditor': {
            const payload = {
              _id: this.selectedInvoice._id,
              paymentDate: event.data,
            };
            this.selectedInvoice.paymentDate = event.data;
            this.openSaveResult();

            this.service.update(payload).subscribe((res) => {
              this.fetchInvoice(this.selectedInvoiceId);
            });
            break;
          }
        }
        break;
      }
      case 'editAmount':
      case 'editPaymentDate': {
        this.isEditorFlow = true;
        if (this.selectedInvoice.isConfirming) {
          let dialogRef = this.openDialogHelper(
            EditAmountsInvoceDialogComponent,
            {
              invoice: event.data.invoice,
              wasEdited: false,
            },
            false,
            '500px'
          );

          this.connectDialogListeners(dialogRef, (result) => {
            if (result) {
              if (result.event === 'Cancel') {
                this.dataEmail = '';
                this.confirmationData = null;
                return;
              }

              const callback = () => {
                this.reFetchInvoice();
                this.confirmationData = null;
              };

              this.confirmationData = result;

              this.overlayService.startConfirmation(
                {
                  _id: this.selectedInvoiceId,
                  physicalInvoices: result.physicalInvoices,
                  paymentDate: result.paymentDate,
                },
                {
                  component: EditAmountConfirmationComponent,
                  entity: {
                    newData: result,
                    originalInvoice: this.selectedInvoice,
                  },
                },
                'details',
                'edit',
                null,
                InvoicesActions.Saving,
                `invoices/${this.selectedInvoiceId}`,
                InvoiceSelectors.savingSuccessStateById,
                {
                  onSuccess: {
                    showCheck: true,
                    title: 'Facturas modificadas',
                  },
                  onConfirm: {
                    title: 'Confirma los cambios',
                  },
                },
                callback.bind(this),
                '60%',
                'Confirmar',
                'Regresar'
              );
            }
          });
        } else {
          let dialogRef = this.openDialogHelper(
            EditAmountsInvoceDialogComponent,
            {
              invoice: event.data.invoice,
              wasEdited: false,
            },
            false,
            '500px'
          );

          this.connectDialogListeners(dialogRef, (result) => {
            if (result) {
              if (result.event === 'Cancel') {
                this.dataEmail = '';
                return;
              }
              let emailData: any = {};
              emailData.cc = this.selectedInvoice?.accountManager?.email;
              emailData.subject = `Cambio de Datos | ${this.selectedInvoice.debtor.companyName.toUpperCase()} | ${this.selectedInvoice.physicalInvoices
                .map((i) => i.code)
                .join(',')}`;
              emailData.to = this.selectedInvoice?.issuer?.email;
              emailData.isConfirming = this.selectedInvoice.isConfirming;
              emailData.type = 'INVOICE_CHANGE';
              emailData.isExternal = true;
              emailData._id = this.selectedInvoice._id;

              if (result.paymentDate !== this.selectedInvoice.paymentDate) {
                emailData.paymentDate = result.paymentDate;
              }

              emailData.physicalInvoices = result.physicalInvoices.filter(
                (p: any) =>
                  this.selectedInvoice.physicalInvoices.find(
                    (pi: any) => pi.code == p.code
                  )?.netAmount !== p.netAmount
              );

              this.dataEmail = {
                ...emailData,
                paymentDate:
                  result.paymentDate || this.selectedInvoice.paymentDate,
                physicalInvoices: lodash
                  .cloneDeep(this.selectedInvoice.physicalInvoices)
                  .map((p: any) => {
                    if (result?.physicalInvoices?.length) {
                      const inv = result?.physicalInvoices.find(
                        (i: any) => i.code == p.code
                      );

                      if (inv) {
                        p.netAmount = inv.netAmount;
                      }
                    }
                    return p;
                  }),
              };

              this.sendEmail = true;
              const callbackEmail = () => {
                this.reFetchInvoice();
                this.resetEmailSend();
              };

              this.overlayService.startConfirmation(
                { result: emailData, _id: this.selectedInvoiceId },
                {
                  component: EmailPreviewComponent,
                  entity: emailData,
                },
                'details',
                'edit',
                null,
                InvoicesActions.SavingEmail,
                `invoices/${this.selectedInvoiceId}`,
                InvoiceSelectors.savingSuccessStateById,
                {
                  onSuccess: {
                    showCheck: true,
                    title: 'Correo enviado!',
                  },
                  onConfirm: {
                    title: EmailType['INVOICE_CHANGE'],
                  },
                },
                callbackEmail.bind(this),
                '60%',
                'Enviar',
                'Regresar'
              );
            }
          });
        }

        break;
      }
      case 'uploadCavali': {
        const codes = this.selectedInvoice.physicalInvoices
          .filter((inv) => {
            if (
              !inv.hasOwnProperty('cavaliStatus') ||
              inv.cavaliStatus === 'error' ||
              inv.cavaliStatus === 'withdrawn'
            ) {
              return inv;
            }
          })
          .map((inv): any => inv.code)
          .toString();

        const entity = {
          _id: this.selectedInvoiceId,
          codes,
        };

        const resultConfig = {
          onConfirm: {
            title: '¿Estás seguro?',
          },
          onSuccess: {
            showCheck: true,
            title: 'Modificación realizada',
          },
        };

        const callback = () => {
          this.fetchInvoice(this.selectedInvoiceId);
        };

        this.overlayService.startConfirmation(
          entity,
          InvoiceToCavaliComponent,
          'details',
          'create',
          null,
          InvoicesActions.SendingCavali,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          resultConfig,
          callback.bind(this)
        );
        break;
      }
      case 'withdrawCavali': {
        const codes = this.selectedInvoice.physicalInvoices
          .filter((inv) => {
            if (inv.cavaliStatus === 'in progress') {
              return inv;
            }
          })
          .map((inv): any => inv.code)
          .toString();

        const physicalInvoices = this.selectedInvoice.physicalInvoices.filter(
          (inv) => inv.cavaliStatus === 'in progress'
        );

        const entity = {
          codes,
          _id: this.selectedInvoiceId,
          physicalInvoices,
          hasConfirmation: true,
          isSunatProcess: this.selectedInvoice.isSunatProcess,
        };

        const resultConfig = {
          onConfirm: {
            title: '¿Estás seguro?',
          },
          onSuccess: {
            showCheck: true,
            title: 'Retiro de CAVALI realizado',
          },
        };

        const callback = () => {
          this.fetchInvoice(this.selectedInvoiceId);
          // this.finishLoading();
        };

        this.overlayService.startConfirmation(
          entity,
          PhysicalInvoiceTableOrganism,
          'details',
          'edit',
          null,
          InvoicesActions.SendingCavali,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          resultConfig,
          callback.bind(this)
        );
        break;
      }
      case 'syncCavali': {
        const payload = {
          _id: this.selectedInvoice._id,
        };
        this.clearPhysicalInvoice(this.selectedInvoiceId);
        this.service.syncWithCavali(payload).subscribe();
        break;
      }
      case 'groupInvoice': {
        let filterDefault;
        if (this.selectedInvoice.isConfirming) {
          filterDefault = {
            statuses: [this.selectedInvoice.status],
            supplier: this.selectedInvoice.supplier?._id,
            debtor: this.selectedInvoice.debtor?._id,
            issuer: this.selectedInvoice.issuer?._id,
            currency: this.selectedInvoice.currency,
            isConfirming: this.selectedInvoice.isConfirming,
            isSunatProcess: this.selectedInvoice.isSunatProcess,
          };
        } else {
          filterDefault = {
            statuses: [this.selectedInvoice.status],
            debtor: this.selectedInvoice.debtor?._id,
            issuer: this.selectedInvoice.issuer?._id,
            currency: this.selectedInvoice.currency,
            isConfirming: this.selectedInvoice.isConfirming,
            isSunatProcess: this.selectedInvoice.isSunatProcess,
          };
        }

        if (this.selectedInvoice.isSunatProcess) {
          filterDefault.paymentDate = this.selectedInvoice.paymentDate;
        }

        this.service
          .getGroupInvoice(filterDefault, this.selectedInvoice)
          .subscribe((res: any) => {
            if (res) {
              const actualInvoices = res.entities;
              const invoicesGroup = actualInvoices
                .filter((inv: any) => inv._id != this.selectedInvoice._id)
                .filter((inv: any) => {
                  if (this.selectedInvoice.checklist && inv.checklist) {
                    //risk checks
                    const mainInvoiceDetail = this.selectedInvoice.checklist
                      .find((i: any) => i.type === 'risk')
                      .subChecks.find((s: any) => s.type === 'invoice detail');
                    const mainInvoiceRisk = this.selectedInvoice.checklist
                      .find((i: any) => i.type === 'risk')
                      .subChecks.find((s: any) => s.type === 'accepted risk');

                    const invoiceDetail = inv.checklist
                      .find((i: any) => i.type === 'risk')
                      .subChecks.find((s: any) => s.type === 'invoice detail');
                    const invoiceRisk = inv.checklist
                      .find((i: any) => i.type === 'risk')
                      .subChecks.find((s: any) => s.type === 'accepted risk');

                    //warranty check
                    let warrantyStatus = true;
                    if (this.selectedInvoice.isConfirming) {
                      const mainWarrantyCheck =
                        this.selectedInvoice.checklist.find(
                          (i: any) => i.type === 'warranty'
                        );
                      const warrantyCheck = inv.checklist.find(
                        (i: any) => i.type === 'warranty'
                      );

                      warrantyStatus =
                        mainWarrantyCheck.status === warrantyCheck.status;
                    }

                    //cavali checks
                    if (this.selectedInvoice.isSunatProcess) {
                      const mainEmailResponseCheck =
                        this.selectedInvoice.checklist
                          .find((i: any) => i.type === 'cavali')
                          .subChecks.find(
                            (s: any) => s.type === 'email response'
                          );
                      const mainDateAmountCheck = this.selectedInvoice.checklist
                        .find((i: any) => i.type === 'cavali')
                        .subChecks.find((s: any) => s.type === 'date amount');

                      const mainPayerResponseCheck =
                        this.selectedInvoice.checklist
                          .find((i: any) => i.type === 'cavali')
                          .subChecks.find(
                            (s: any) => s.type === 'payer response validation'
                          );

                      const emailResponseCheck = inv.checklist
                        .find((i: any) => i.type === 'cavali')
                        .subChecks.find(
                          (s: any) => s.type === 'email response'
                        );
                      const dateAmountCheck = inv.checklist
                        .find((i: any) => i.type === 'cavali')
                        .subChecks.find((s: any) => s.type === 'date amount');

                      const payerResponseCheck = inv.checklist
                        .find((i: any) => i.type === 'cavali')
                        .subChecks.find(
                          (s: any) => s.type === 'payer response validation'
                        );

                      if (
                        mainEmailResponseCheck?.status ===
                          emailResponseCheck?.status &&
                        mainDateAmountCheck?.status ===
                          dateAmountCheck?.status &&
                        mainInvoiceDetail?.status === invoiceDetail?.status &&
                        mainInvoiceRisk?.status === invoiceRisk?.status &&
                        mainPayerResponseCheck?.status === payerResponseCheck?.status &&
                        warrantyStatus
                      ) {
                        return inv;
                      }
                    } else {
                      if (
                        mainInvoiceDetail?.status === invoiceDetail?.status &&
                        mainInvoiceRisk?.status === invoiceRisk?.status &&
                        warrantyStatus
                      ) {
                        return inv;
                      }
                    }
                  } else {
                    return inv;
                  }
                });

              this.showDialogGroup(
                this.selectedInvoice,
                invoicesGroup
                // invoiceAlreadyGrouped
              );
            }
          });

        break;
      }
      case 'ungroupInvoice': {
        const physicalInvoices = this.selectedInvoice.physicalInvoices.filter(
          (inv): any => this.invoiceUngroupList.includes(inv.code)
        );

        const entity = {
          codes: this.invoiceUngroupList.toString(),
          _id: this.selectedInvoiceId,
          physicalInvoices,
        };

        const resultConfig = {
          onConfirm: {
            title: 'Estás a punto de desagrupar las siguientes facturas',
          },
          onSuccess: {
            showCheck: true,
            title: 'Se desagrupó la factura',
          },
          hasServerValue: true,
        };

        const callback = () => {
          this.reFetchInvoice();
        };

        this.overlayService.startConfirmation(
          entity,
          PhysicalInvoiceTableOrganism,
          'details',
          'edit',
          null,
          InvoicesActions.Saving,
          `invoices/${this.selectedInvoice._id}`,
          InvoiceSelectors.savingSuccessStateById,
          resultConfig,
          callback.bind(this)
        );
        break;
      }
      case 'creditNote': {
        this.creditNoteFile.nativeElement.value = null;
        this.creditNoteFile.nativeElement.click();
        break;
      }
      case 'checkChanged': {
        if (event?.data?.event?.checked) {
          if (!this.invoiceUngroupList.includes(event?.data?.physicalInvoice)) {
            this.invoiceUngroupList.push(event?.data?.physicalInvoice);
          }
        } else {
          this.invoiceUngroupList = this.invoiceUngroupList.filter(
            (i: any) => i?.code !== event?.data?.physicalInvoice?.code
          );
        }
        break;
      }
      case 'openCavaliDetail': {
        let dialogRef = this.openDialogHelper(CavaliResponseDialogComponent, {
          invoiceId: this.selectedInvoiceId,
          code: event?.data?.invoiceDetail,
        });

        this.connectDialogListeners(dialogRef, (result) => {
          if (result) {
            if (result.action === 'upload') {
              this.uploadAnInvoiceToCavali(result.code);
            } else if (result.action === 'remove') {
              this.removeAnInvoiceFromCavali(result.code);
            }
          }
        });
        break;
      }
      case 'acceptWarranty': {
        const callback = () => {
          this.fetchInvoice(this.selectedInvoiceId);
        };

        this.overlayService.startConfirmation(
          {
            warrantyCheck: true,
            _id: this.selectedInvoice._id,
          },
          null,
          'details',
          'edit',
          null,
          InvoicesActions.Saving,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onConfirm: {
              showCheck: true,
              title: 'Confirmación de modificación',
            },
            onSuccess: {
              showCheck: true,
              title: 'Modificación realizada',
            },
          },
          callback.bind(this)
        );
        break;
      }
      case 'close': {
        if (event.dataPoint === 'ungroupingEditor') {
          this.invoiceUngroupList = [];
        }
      }
    }
  }

  uploadAnInvoiceToCavali(physicalInvoice: any) {
    const codes = [physicalInvoice.code].toString();

    const entity = {
      _id: this.selectedInvoiceId,
      codes,
    };

    const resultConfig = {
      onConfirm: {
        title: '¿Estás seguro?',
      },
      onSuccess: {
        showCheck: true,
        title: 'Modificación realizada',
      },
    };

    const callback = () => {
      this.fetchInvoice(this.selectedInvoiceId);
    };

    this.overlayService.startConfirmation(
      entity,
      InvoiceToCavaliComponent,
      'details',
      'create',
      null,
      InvoicesActions.SendingCavali,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      resultConfig,
      callback.bind(this)
    );
  }

  removeAnInvoiceFromCavali(physicalInvoice: any) {
    let invoice = this.selectedInvoice;
    const physicalInvoices = invoice.physicalInvoices.filter(
      (inv): any => inv.code === physicalInvoice.code
    );
    const codes = [physicalInvoice.code].toString();
    const entity = {
      _id: this.selectedInvoiceId,
      codes,
      physicalInvoices,
      hasConfirmation: true,
      isSunatProcess: this.selectedInvoice.isSunatProcess,
    };

    const resultConfig = {
      onConfirm: {
        title: '¿Estás seguro?',
      },
      onSuccess: {
        showCheck: true,
        title: 'Retiro de CAVALI realizado',
      },
    };

    const callback = () => {
      this.fetchInvoice(this.selectedInvoiceId);
    };

    this.overlayService.startConfirmation(
      entity,
      PhysicalInvoiceTableOrganism,
      'details',
      'edit',
      null,
      InvoicesActions.SendingCavali,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      resultConfig,
      callback.bind(this)
    );
  }

  invoicesGroup;
  invoiceForGrouping;
  groupingModal;
  showDialogGroup(mainInvoice, invoicesGroup, invoiceAlreadyGrouped?) {
    this.selectedInvoice = mainInvoice;
    this.invoicesGroup = invoicesGroup;
    const dialogRef = this.dialog.open(GroupingInvoiceComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      minWidth: '100vw',
      height: '100%',
      autoFocus: false,
      position: {
        left: '0',
      },
      data: { mainInvoice, invoicesGroup, invoiceAlreadyGrouped },
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.groupingModal = true;
        this.invoiceForGrouping = result;

        //to send to InvoiceGroupConfirmationComponent all the codes to display in table
        const physicalInvoices = this.invoiceForGrouping
          .map((inv) => inv.physicalInvoices)
          .reduce((acc, val) => {
            if (acc) {
              return [...acc, ...val];
            }
          });

        const groupList = result;

        //to pass to the InvoiceGroupConfirmationComponent
        const invoices = {
          groupList,
          mainInvoice,
          physicalInvoices,
        };

        //to send to the endpoint only the ids to group without the main one
        const invoiceIds = this.invoiceForGrouping
          .filter((inv) => inv._id != mainInvoice._id)
          .map((inv) => {
            return { _id: inv._id };
          });

        //to send to the endpoint
        const entity = {
          invoiceIds,
          _id: this.selectedInvoiceId,
        };

        const callbackGrouping = () => {
          this.reFetchInvoice();
          this.groupingModal = false;
        };

        this.overlayService.startConfirmation(
          entity,
          {
            component: InvoiceGroupConfirmationComponent,
            entity: invoices,
          },
          'details',
          'edit',
          null,
          InvoicesActions.Saving,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Ajuste realizado',
            },
            onConfirm: {
              title: '¿Estás seguro?',
              message: 'Este es el detalle de la nueva agrupación:',
            },
          },
          callbackGrouping.bind(this),
          '60%'
        );
      } else {
        this.groupingModal = false;
      }
    });
  }

  uploadCreditNote(fileInput: any) {
    let filePromises = [];
    let isDisabled = false;

    Array.from(fileInput).forEach((f: any) => {
      const filePromise = new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = () =>
          resolve({ filename: f.name, reader: reader.result });
      });

      filePromises.push(filePromise);
    });

    Promise.all(filePromises).then((files: any) => {
      let errorMessages = [];
      let firstPaymentDate = '';
      let invoicesCreditNote = [];
      let newPaymentDate = '';

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        let fileText = '';

        try {
          fileText = decode(file.reader.split(';base64,')[1]);
        } catch (error) {
          console.log('wrong format');
        }

        const parsedCreditNote = this.service.parseXmlCreditNote(fileText);

        if (parsedCreditNote) {
          parsedCreditNote.xml = fileText;

          let errors = [];

          if (
            invoicesCreditNote.some(
              (c: any) => c.code === parsedCreditNote.relatedInvoiceCode
            )
          ) {
            errors.push(
              `ya se está aplicando una nota a la factura ${parsedCreditNote.relatedInvoiceCode}`
            );
          }

          if (
            Date.parse(parsedCreditNote.invoiceIssueDate) >
            Date.parse(parsedCreditNote.issueDate)
          ) {
            errors.push(
              'la fecha de emisión de la nota debe ser mayor o igual a la de la factura'
            );
          }

          if (
            this.selectedInvoice.debtor.companyRuc !== parsedCreditNote.payerRuc
          ) {
            errors.push('no tiene el mismo deudor');
          }

          if (
            parsedCreditNote.issuerRuc !==
            (this.selectedInvoice.isConfirming
              ? this.selectedInvoice.supplier.companyRuc
              : this.selectedInvoice.issuer.companyRuc)
          ) {
            errors.push('no tiene el mismo proveedor');
          }

          if (
            !this.selectedInvoice.physicalInvoices.some(
              (p: any) => p.code === parsedCreditNote.relatedInvoiceCode
            )
          ) {
            errors.push(
              `el código "${parsedCreditNote.relatedInvoiceCode}" no está contenido en el grupo de facturas`
            );
          }

          if (
            firstPaymentDate &&
            parsedCreditNote.paymentDate !== firstPaymentDate
          ) {
            errors.push('debe tener la misma fecha de pago que las otras');
          }

          let existingCreditNote = this.selectedInvoice.physicalInvoices
            .map((p: any) => p.creditNote)
            ?.find((c: any) => c && c?.code === parsedCreditNote.code);

          if (
            existingCreditNote &&
            moment(parsedCreditNote.issueDate, 'YYYY-MM-DD').isBefore(
              moment(existingCreditNote.issueDate, 'YYYY-MM-DD')
            )
          ) {
            errors.push(
              'la fecha de emisión de la nueva nota debe ser mayor a la aplicada actualmente'
            );
          }

          if (
            existingCreditNote &&
            existingCreditNote.code === parsedCreditNote.code
          ) {
            errors.push(`el código ${parsedCreditNote.code} ya está asociado`);
          }

          if (errors.length > 0) {
            errorMessages.push(
              `• La nota de crédito "${file.filename}" ${errors
                .join(', ')
                .replace(/,([^,]*)$/, ' y' + '$1')}.`
            );
          } else {
            if (index === 0) {
              firstPaymentDate = parsedCreditNote.paymentDate;

              if (firstPaymentDate !== this.selectedInvoice.paymentDate) {
                newPaymentDate =
                  firstPaymentDate || this.selectedInvoice.paymentDate;
              }
            }
            const physicalInvoice = lodash.cloneDeep(
              this.selectedInvoice.physicalInvoices.find(
                (p: any) => p.code === parsedCreditNote.relatedInvoiceCode
              )
            );

            if (!parsedCreditNote.netAmount) {
              physicalInvoice.originalNetAmount = physicalInvoice.netAmount;
              physicalInvoice.originalRetentionAmount = Number(
                physicalInvoice.retentionAmount
              ).toFixed(2);
            } else {
              const discount =
                Number(physicalInvoice.netAmount) -
                Number(parsedCreditNote.netAmount);

              physicalInvoice.originalNetAmount =
                physicalInvoice.originalNetAmount || physicalInvoice.netAmount;
              physicalInvoice.originalRetentionAmount =
                physicalInvoice.originalRetentionAmount ||
                Number(physicalInvoice.retentionAmount).toFixed(2);
              physicalInvoice.netAmount = parsedCreditNote.netAmount;
              physicalInvoice.retentionAmount = (
                Number(physicalInvoice.retentionAmount) + discount
              ).toFixed(2);
            }

            physicalInvoice.creditNote = parsedCreditNote;

            if (physicalInvoice.retentionAmount < 0) {
              errorMessages.push(
                `• La nota de crédito "${file.filename}" posee un monto mayor al original.`
              );
            } else {
              invoicesCreditNote.push(physicalInvoice);
            }
          }
        } else {
          errorMessages.push(
            `• El archivo "${file.filename}" no es una nota de crédito válida.`
          );
        }
      }

      if (!invoicesCreditNote.length) {
        isDisabled = true;
      }

      this.overlayService.startConfirmation(
        { invoicesCreditNote, invoice: this.selectedInvoice, newPaymentDate },
        {
          component: CreditNoteConfirmationComponent,
          entity: {
            invoicesCreditNote,
            invoice: this.selectedInvoice,
            newPaymentDate,
            errorMessages,
          },
        },
        'details',
        'edit',
        null,
        InvoicesActions.Saving,
        `invoices/${this.selectedInvoiceId}`,
        InvoiceSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: 'Se actualizó la operación',
          },
        },
        this.reFetchInvoice.bind(this),
        null,
        'Confirmar',
        'Cancelar',
        false,
        isDisabled
      );
    });
  }

  activeObservation(obs) {
    obs.isDone = !obs.isDone;
    this.updateObservation(obs);
  }

  setIGV(obs) {
    obs.isTaxable = !obs.isTaxable;
    this.updateObservation(obs);
  }

  updateObservation(obs) {
    this.service.updateObservation(obs).subscribe((data) => {
      if (data) {
        this.getObservations(obs.client);
      }
    });
  }

  addObservation(invoice) {
    let data = {
      invoiceId: this.selectedInvoiceId,
      userId: invoice.issuer._id,
    };
    let dialogRef = this.openDialogHelper(NewObservationDialogComponent, {
      data: data,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.service.saveObservations(result).subscribe((data) => {
          if (data) {
            this.getObservations(result.client);
          }
        });
      }
    });
  }

  deleteObs(obs) {
    let targetObservation = { ...obs };
    targetObservation.isDeleted = true;
    this.overlayService.startConfirmation(
      { _id: this.selectedInvoiceId, ...targetObservation },
      null,
      'question',
      'edit',
      null,
      InvoicesActions.DeleteObs,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: 'Se ha eliminado la observación',
        },
      },
      this.updateAllObs.bind(this)
    );
  }

  updateAllObs(shouldWait = true) {
    if (shouldWait) {
      setTimeout(() => {
        this.getObservations(this.selectedInvoice.issuer._id);
      }, 500);
    } else {
      this.getObservations(this.selectedInvoice.issuer._id);
    }
  }

  setMode() {
    if (
      this.selectedInvoice.status !== 'finalized' &&
      this.selectedInvoice.toBeCollectedIn !== 'En mora'
    ) {
      this.mode = 'actual';
    } else if (
      this.selectedInvoice.status !== 'finalized' &&
      this.selectedInvoice.toBeCollectedIn === 'En mora'
    ) {
      this.mode = 'delayed';
    } else if (this.selectedInvoice.status === 'finalized') {
      this.mode = 'historic';
    }
  }

  getInvestorsList(data) {
    this.service.getInvestors(data._id).subscribe((data) => {
      if (data) {
        this.investments = data;
      }
    });
  }

  resendEmail(investor: any) {
    let payload = {
      email: investor.email,
      names: capitalizeFirstLetters(investor.names),
      invoiceId: this.selectedInvoice._id,
    };

    this.overlayService.startConfirmation(
      payload,
      null,
      'question',
      'create',
      null,
      InvoicesActions.ResendAnnouncementEmail,
      `invoices/${this.selectedInvoiceId}`,
      InvoiceSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: '!Correo enviado!',
        },
        onConfirm: {
          title: '¿Estás seguro de reenviar el correo?',
        },
      },
      null
    );
  }

  newAnnouncement(invoice, announcementDetail?) {
    this.confirmationMode = 'save';
    const dialogRef = this.dialog.open(AddAnnouncementDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: false,
      data: { announcementDetail },
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.openModalComment = true;
        this.announcementDetail = result;

        if (result.event === 'Cancel') {
          this.openModalComment = false;
          return;
        }

        if (result.file === '') {
          delete result.file;
        }
        if (result.filename === '') {
          delete result.filename;
        }
        this.overlayService.startConfirmation(
          { ...result, _id: this.selectedInvoiceId },
          {
            component: CommentOnConfirmationComponent,
            entity: result,
          },
          'details',
          'create',
          null,
          InvoicesActions.SaveAnnouncement,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Actualización realizada',
            },
            onConfirm: {
              title: '¿Estás seguro?',
            },
          },
          this.reFetchInvoice.bind(this)
        );
      } else {
        this.openModalComment = false;
      }
    });
  }

  resendAnnouncement(announcement) {
    let announcementDetail;

    if (announcement?.announcementDetail) {
      announcementDetail = {
        ...announcement.announcementDetail,
        invoices: announcement.invoices,
        linkedInvoices: announcement.linkedInvoices,
      };
    } else {
      announcementDetail = announcement;
    }
    announcementDetail.mainInvoiceId = this.selectedInvoice;
    const dialogRef = this.dialog.open(ForwardannouncementComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: false,
      data: { announcementDetail },
    });

    this.connectDialogListeners(dialogRef, (result) => {
      if (result) {
        this.openModalResend = true;

        this.announcementDetail = {
          announcementDetail: result.announcementDetail,
          invoices: result.invoices,
          linkedInvoices: result.linkedInvoices,
        };

        if (result.event === 'Cancel') {
          this.openModalResend = false;
          return;
        }

        let dataForConfirmation = {
          invoices: result.invoices,
          announcementDetail: result.announcementDetail,
        };

        let dataToSave = {
          invoiceIds: result?.invoiceIds,
          announcementId:
            result?.announcement?.announcementId || result?.announcementId,
        };

        this.overlayService.startConfirmation(
          { ...dataToSave, _id: this.selectedInvoiceId },
          {
            component: ForwardAnnouncementConfirmationComponent,
            entity: dataForConfirmation,
          },
          'details',
          'create',
          null,
          InvoicesActions.ForwardAnnouncement,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Actualización realizada',
            },
            onConfirm: {
              title: '¿Estás seguro?',
            },
          },
          this.reFetchAfterEdit.bind(this)
        );
      } else {
        this.openModalResend = false;
      }
    });
  }

  reFetchAfterEdit() {
    if (this.loggedInUserRole === Roles.CLIENT_HAPPINESS) {
      this.openModalComment = false;
      this.openModalResend = false;
    }
    localStorage.removeItem('originalMessage');
    localStorage.removeItem('originalFileName');

    this.fetchInvoice(this.selectedInvoiceId);
  }

  editAnnouncement(announcementDetail, mode = '') {
    this.confirmationMode = 'edit';

    const dialogRef = this.dialog.open(AddAnnouncementDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      position: {
        left: '0',
      },
      autoFocus: false,
      data: { announcementDetail, mode: 'edit' },
    });

    this.connectDialogListeners(dialogRef, (result) => {
      // debugger;
      if (result) {
        this.openModalComment = true;
        this.announcementDetail = result;

        if (mode !== 'return') {
          this.originalMessage = result.originalMessage;
        }

        if (result.event === 'Cancel') {
          this.openModalComment = false;
          return;
        }

        if (result.file === '') {
          delete result.file;
        }
        if (result.filename === '') {
          delete result.filename;
        }

        let data = { ...result, _id: this.selectedInvoiceId };

        if (data.hasOwnProperty('originalMessage')) {
          delete data.originalMessage;
        }

        if (!data.hasOwnProperty('announcementId')) {
          data.announcementId = announcementDetail.announcementId;
        }

        this.overlayService.startConfirmation(
          data,
          {
            component: CommentOnConfirmationComponent,
            entity: {
              ...result,
              mode: this.confirmationMode,
              savedOriginalMessage: this.originalMessage,
            },
          },
          'details',
          'edit',
          null,
          InvoicesActions.SaveAnnouncement,
          `invoices/${this.selectedInvoiceId}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: 'Actualización realizada',
            },
            onConfirm: {
              title: '¿Estás seguro?',
            },
          },
          this.reFetchAfterEdit.bind(this)
        );
      } else {
        this.openModalComment = false;
      }
    });
  }

  getAnnouncementsList(data) {
    this.service.getAnnouncements(data._id).subscribe((res) => {
      if (res) {
        this.announcements = res;
      }
    });
  }

  updateTea($event) {
    if ($event.dataPoint == 'teaEditor' && $event.eventType == 'open') {
      let dialogRef = this.openDialogHelper(
        InvoiceTeaConfigurationDialogComponent,
        { invoice: this.selectedInvoice },
        false,
        '643px'
      );

      this.connectDialogListeners(dialogRef, (result) => {
        if (result.codes) {
          this.overlayService.startConfirmation(
            result,
            null,
            'resultOnly',
            'edit',
            null,
            InvoicesActions.Saving,
            `invoices/${this.selectedInvoiceId}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: 'La factura se ha actualizado',
              },
            },
            this.reFetchInvoice.bind(this)
          );
        }
      });
    }
  }

  sortArray(operations) {
    let arraySent = [];
    let arrayNotSent = [];
    let mainInvoice = operations.filter(
      (op) => op._id === this.selectedInvoice._id
    );

    let array = operations.map((op) => {
      let obj = op.emailLog.find(
        (element) =>
          element.type !== 'OPERATION_START' &&
          op._id !== this.selectedInvoice._id
      );
      let obj2 = op.emailLog.find(
        (element) =>
          element.type === 'OPERATION_START' &&
          op._id !== this.selectedInvoice._id
      );

      let obj3 = op.emailLog.length;
      if (obj && !obj2) {
        arrayNotSent.push(op);
      } else if (obj2) {
        arraySent.push(op);
      } else if (obj3 === 0 && op._id !== this.selectedInvoice._id) {
        arrayNotSent.push(op);
      } else {
        return;
      }
    });

    mainInvoice = mainInvoice.concat(arrayNotSent, arraySent);

    return (operations = mainInvoice);
  }

  clearPhysicalInvoice(invoice) {
    this.selectedInvoice.physicalInvoices.map((inv) => {
      inv.cavaliPayerResponse = '';
      inv.cavaliProcessId = '';
      inv.cavaliSentAt = '';
      inv.cavaliStatus = 'loading';
      inv.cavaliCreatedAt = '';
      inv.cavaliObservation = '';
      inv.cavaliUniqueId = '';
      inv.cavaliReliableNotification = 'pending';
    });
  }

  getValidationSplitInvoice() {
    return this.selectedInvoice.status === 'pending confirmation' &&
      this.selectedInvoice.checklist.every(
        (c) => c.status == 'approved' || c.status == 'exception'
      ) &&
      this.selectedInvoice.physicalInvoices.length === 1 &&
      this.selectedInvoice.availableBalanceAmount !== '0.00'
      ? true
      : false;
  }

  openInvoiceSplit(data?) {
    if (this.getValidationSplitInvoice()) {
      let dialogRef = this.openDialogHelper(SplitInvoiceDialogComponent, {
        _id: this.selectedInvoiceId,
        invoice: this.selectedInvoice,
        partitions: data,
      });

      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          const callback = () => {
            this.reFetchInvoice();
            this.splitInvoiceModal = false;
          };
          result.partitions[0].isMain = true;
          this.resultPartitions = result;
          this.splitInvoiceModal = true;
          this.overlayService.startConfirmation(
            {
              ...result,
              _id: this.selectedInvoiceId,
            },
            {
              component: ConfirmationSplitInvoiceComponent,
              entity: { ...result, ...this.selectedInvoice },
            },
            'details',
            'edit',
            null,
            InvoicesActions.Saving,
            `invoices/${this.selectedInvoiceId}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onConfirm: {
                title: 'Confirmar partición de factura',
                messsage: 'Confirma los montos de las particiones a crear.',
              },
              onSuccess: {
                showCheck: true,
                title: 'Particion de Factura',
                messsage: 'La factura se ha particionado correctamente.',
              },
            },
            callback.bind(this),
            '50%'
          );
        } else {
          this.splitInvoiceModal = false;
        }
      });
    }
  }
}
