<h1>Facturar por otro concepto</h1>
<form [formGroup]="form">
    <div *ngIf="selectedConcepts.controls">
        <div class="service-cost-frame" *ngFor="let concept of selectedConcepts.controls; let i = index">
            <div [formGroup]="selectedConcepts.at(i)">
                <mat-form-field class="input">
                    <mat-label>Concepto de Factura</mat-label>
                    <mat-select formControlName="type" placeholder="Selecciona el concepto">
                        <mat-option *ngFor="let option of concepts" [value]="option.value"
                            [ngStyle]="{ display: option.isHidden ? 'none' : 'block' }">
                            {{ option.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <app-input class="input" type="text" label="Monto" formControlName="amount">
                </app-input>

                <mat-checkbox style="margin-top: 4px;"
                    *ngIf="!data?.invoice?.isConfirming && (selectedConcepts.at(i).get('type')?.value === 'legalExpenses' || selectedConcepts.at(i).get('type')?.value === 'legalExpensesIgv' || selectedConcepts.at(i).get('type')?.value === 'additionalFee')"
                    formControlName="isToDebtor" color="accent">Facturar al deudor
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="add-concept" (click)="addConcept()">Agregar concepto</div>

    <div class="button-container">
        <fs-ui-button [action]="'submit'" (click)="onSubmit()" [label]="'Siguiente'" [type]="'secondary'">
        </fs-ui-button>
        <fs-ui-button (click)="cancel()" [label]="'Cancelar'" [type]="'tertiary'"></fs-ui-button>
    </div>
</form>