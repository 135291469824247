<app-top-bar *ngIf="(isLoggedIn$ | async) && isMobile"></app-top-bar>

<lib-ui-lib></lib-ui-lib>
<div class="container" id="main-container"
    [ngClass]="{oppScreen: (routeURL?.split('/')[1] == 'operations') || (routeURL?.split('/').length > 2 && routeURL?.split('/')[1] === 'risk-debtors') || (routeURL?.split('/').length > 2 && routeURL?.split('/')[1] === 'invoices') }">
    <app-side-menu class="intercom-sidebar-menu"
        [hidden]="!(((isSideMenuVisible$ | async) || !isMobile) && (isLoggedIn$ | async))">
    </app-side-menu>

    <div [ngClass]="(isLoggedIn$ | async) ? 'content' : 'content anonymous'"
        [hidden]="((isSideMenuVisible$ | async) && isMobile)">
        <app-top-bar *ngIf="(isLoggedIn$ | async) && !isMobile"></app-top-bar>
        <ng-container *ngIf="!hideSetup">
            <ng-container *allowedRoles="['client', 'investor']">
                <ng-container *ngIf="(hideByDefault && !profile.setupbarClosed)">
                    <div class="container-setup" *ngIf="((isLoggedIn$ | async) && !profile.setupbarClosed)">
                        <app-setup-bar [stepsSetupBar]="stepsSetupBarGeneral" [currenStepSetupBar]="currenStepSetupBar"
                            [titleSetupBar]="titleSetupBar" [checkedByDefault]="checkedByDefault"></app-setup-bar>
                        <button type="button" *ngIf="displayCloseButton && !isMobile" (click)="closeSetup()"
                            class="button">Cerrar</button>

                        <button *ngIf="displayCloseButton && isMobile" type="button" class="button-mobile button-gray"
                            (click)="closeSetup()">Cerrar</button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <div class="content-outlet">

            <router-outlet></router-outlet>

            <!-- <div data-tf-popover="Or8sDzYS" data-tf-button-color="#0445AF" data-tf-button-text="Launch me" data-tf-iframe-props="title=My typeform" data-tf-medium="snippet" data-tf-hidden="user_id=xxxxx,name=xxxxx" style="all:unset;"></div> -->
        </div>
        <app-footer *ngIf="(isLoggedIn$ | async) && !(isAdmin$ | async)"></app-footer>
    </div>
</div>
<app-spinner></app-spinner>