import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-invoicing-ticket',
  templateUrl: './invoicing-ticket.component.html',
  styleUrls: ['./invoicing-ticket.component.scss'],
})
export class InvoicingTicketComponent implements OnChanges {
  @Input() title: string;
  @Input() costDetail: any[];
  totalCost: string;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['costDetail'] &&
      Array.isArray(changes['costDetail'].currentValue)
    ) {
      this.totalCost = changes['costDetail'].currentValue
        .reduce((acc, cur) => acc + Number(cur.amount), 0)
        .toFixed(2);
    }
  }
}
