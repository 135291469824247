import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AppState } from 'src/app/app.states';
import { Store, Action } from '@ngrx/store';
import {
  tap,
  map,
  catchError,
  mergeMap,
  switchMap,
  shareReplay,
  concatMap,
  take,
} from 'rxjs/operators';
import { Observable, pipe, of } from 'rxjs';
import { InvoiceService } from '../services/invoice.service';
import { InvoicesActions } from './invoices.action-types';
import { UsersActions } from '../../users/state/users.action-types';
import { UsersSelectors } from '../../users/state/users.selector-types';
import { InvoiceSelectors } from './invoices.selector-types';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { GAService } from 'src/app/shared/services/ga.service';
import { ClientService } from '../../my-clients/services/client.service';

@Injectable()
export class InvoicesEffects {
  $saving = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.Saving),
        concatMap((action) =>
          this.invoiceService.save(action.entity, action.mode).pipe(
            switchMap((receivedEntity) => {
              return [
                InvoicesActions.SaveSuccess({ entity: receivedEntity }),
                UsersActions.LoadProfile(),
              ];
            }),

            catchError((error) => {
              this.store
                .select(UsersSelectors.profile())
                .pipe(take(1))
                .subscribe((profile) => {
                  if (profile.role === 'client') {
                    this.store
                      .select(InvoiceSelectors.all)
                      .subscribe((invoices) => {
                        const eventName = 'invoice_upload';
                        const eventData = {
                          create_date: new Date().toISOString(),
                          operation_type: invoices[0].isConfirming
                            ? 'Confirming'
                            : 'Factoring',
                          operation_id: invoices[0]._id,
                          currency: invoices[0].currency.toUpperCase(),
                          amount: invoices[0].netAmount,
                          invoice_count: invoices.length,
                          is_success: false,
                          error_description: `Unkown error ${error.status}`,
                        };
                        this.gaService.sendEvent(eventName, eventData);
                        this.mixpanelService.newEvent(eventName, eventData);
                      });
                  }
                });
              return of(InvoicesActions.SaveFailed({ error }));
            })
          )
        )
      ),
    { dispatch: true }
  );

  $saveAnnouncement = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.SaveAnnouncement),
        concatMap((action) =>
          this.invoiceService.saveAnnouncement(action.entity, action.mode).pipe(
            switchMap((receivedEntity) => {
              return [
                InvoicesActions.SaveSuccess({ entity: receivedEntity }),
                InvoicesActions.LoadAll(),
                UsersActions.LoadProfile(),
              ];
            }),

            catchError((error) => {
              return of(InvoicesActions.SaveFailed({ error }));
            })
          )
        )
      ),
    { dispatch: true }
  );

  $saveAdditionalnvoicings = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.SaveAdditionalnvoicings),
        concatMap((action) =>
          this.invoiceService
            .createAdditionalInvoicings(action.entity, action.mode)
            .pipe(
              switchMap((receivedEntity) => {
                return [
                  InvoicesActions.SaveSuccess({ entity: receivedEntity }),
                  // InvoicesActions.LoadAll(),
                  // UsersActions.LoadProfile(),
                ];
              }),

              catchError((error) => {
                return of(InvoicesActions.SaveFailed({ error }));
              })
            )
        )
      ),
    { dispatch: true }
  );

  $forwardAnnouncement = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.ForwardAnnouncement),
        concatMap((action) =>
          this.invoiceService
            .forwardAnnouncement(action.entity, action.mode)
            .pipe(
              switchMap((receivedEntity) => {
                return [
                  InvoicesActions.SaveSuccess({ entity: receivedEntity }),
                  UsersActions.LoadProfile(),
                ];
              }),

              catchError((error) => {
                return of(InvoicesActions.SaveFailed({ error }));
              })
            )
        )
      ),
    { dispatch: true }
  );

  $sendingCavali = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.SendingCavali),
        concatMap((action) =>
          this.invoiceService.sendingToCavali(action.entity, action.mode).pipe(
            switchMap((receivedEntity) => {
              return [InvoicesActions.SaveSuccess({ entity: receivedEntity })];
            }),

            catchError((error) => of(InvoicesActions.SaveFailed({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  $resendAnnouncementEmail = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.ResendAnnouncementEmail),
        concatMap((action) =>
          this.invoiceService
            .resendAnnouncementEmail(action.entity, action.mode)
            .pipe(
              switchMap((receivedEntity) => {
                return [
                  InvoicesActions.SaveSuccess({ entity: receivedEntity }),
                ];
              }),

              catchError((error) => of(InvoicesActions.SaveFailed({ error })))
            )
        )
      ),
    { dispatch: true }
  );

  $savingEmail = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.SavingEmail),
        concatMap((action) =>
          this.invoiceService.sendEmail(action.entity, action.mode).pipe(
            tap((receivedEntity) =>
              console.log('receivedEntity', receivedEntity)
            ),
            switchMap((receivedEntity) => [
              InvoicesActions.SaveSuccess({ entity: receivedEntity }),
            ]),
            catchError((error) => of(InvoicesActions.SaveFailed({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  $SaveContact = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.SaveContact),
        concatMap((action) =>
          this.clientService
            .createContactDebtor(action.entity, action.mode)
            .pipe(
              map((receivedEntity) => {
                console.log('saved at', new Date().getTime());
                return InvoicesActions.SaveSuccess({ entity: receivedEntity });
              }),
              catchError((error) => of(InvoicesActions.SaveFailed({ error })))
            )
        )
      ),
    { dispatch: true }
  );

  $ValidContact = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.ValidContact),
        concatMap((action) =>
          this.clientService.validateContact(action.entity, action.mode).pipe(
            map((receivedEntity) =>
              InvoicesActions.SaveSuccess({ entity: receivedEntity })
            ),
            catchError((error) => of(InvoicesActions.SaveFailed({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  $deleteObs = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.DeleteObs),
        concatMap((action) =>
          this.invoiceService
            .updateObservation(action.entity, action.mode)
            .pipe(
              map((receivedEntity) =>
                InvoicesActions.SaveSuccess({ entity: receivedEntity })
              ),
              catchError((error) => of(InvoicesActions.SaveFailed({ error })))
            )
        )
      ),
    { dispatch: true }
  );

  $loadAll = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoicesActions.LoadAll),
      concatMap((action) => this.invoiceService.getAll()),
      map((invoices) => InvoicesActions.LoadAllSuccess({ entities: invoices }))
    )
  );

  $loadAllOpportunities = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.LoadAllOpportunities),
        concatMap((action) => this.invoiceService.getAllOpportunities()),
        pipe(
          map((opportunities) =>
            InvoicesActions.LoadAllOpportunitiesSuccess({ opportunities })
          ),
          catchError((error) =>
            of(InvoicesActions.LoadAllOpportunitiesFailed({ error }))
          )
        )
      ),
    { dispatch: true }
  );

  $loadAllOperations = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoicesActions.LoadAllOperations),
        concatMap((action) => this.invoiceService.getAllOperations()),
        pipe(
          map((operations) =>
            InvoicesActions.LoadAllOperationsSuccess({ operations })
          ),
          catchError((error) =>
            of(InvoicesActions.LoadAllOperationsFailed({ error }))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private invoiceService: InvoiceService,
    private clientService: ClientService,
    private mixpanelService: MixpanelService,
    private gaService: GAService
  ) {}
}
