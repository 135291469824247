<div class="service-cost-frame">
    <p class="service-cost-name">{{title}}</p>
    <div class="service-cost-detail">
        <div class="row" *ngFor="let item of costDetail">
            <span class="label">{{item.label}}</span>
            <span class="amount">{{item.currency}} {{item.amount | numberSpacing}}</span>
        </div>
        <hr>
        <div class="row">
            <span class="label">Total</span>
            <span class="total">{{costDetail?.length ? costDetail[0]?.currency : ''}} {{totalCost |
                numberSpacing}}</span>
        </div>
    </div>
</div>