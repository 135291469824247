import { InvoiceService } from './../../../services/invoice.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoicing-confirmation',
  templateUrl: './invoicing-confirmation.component.html',
  styleUrls: ['./invoicing-confirmation.component.scss'],
})
export class InvoicingConfirmationComponent implements OnInit {
  @Input() entity: any;
  invoicingDetails: any = {};
  bulkInvoicingDetails = [];
  isBulkInvoicing = false;
  constructor(private invoiceService: InvoiceService) {}

  ngOnInit(): void {
    if (this.entity.additionalInvoicings?.length) {
      this.invoicingDetails.additionalInvoicings = {
        user: this.entity.additionalInvoicings
          ?.filter((invoicing: any) => !invoicing.isToDebtor)
          .map((invoicing: any) => {
            const currency =
              this.entity.invoice.currency === 'pen' ? 'S/' : '$';

            const additionalInvoicing = [
              {
                label: invoicing.typeDescription,
                amount: Number(invoicing.amount).toFixed(2),
                currency,
              },
            ];

            if (invoicing.type === 'legalExpensesIgv') {
              additionalInvoicing.push({
                label: 'IGV',
                amount: (Number(invoicing.amount) * 0.18).toFixed(2),
                currency,
              });
            }

            return {
              title: invoicing.typeDescription,
              additionalInvoicing,
            };
          }),
        debtor: this.entity.additionalInvoicings
          ?.filter((invoicing: any) => invoicing.isToDebtor)
          .map((invoicing: any) => {
            const currency =
              this.entity.invoice.currency === 'pen' ? 'S/' : '$';

            const additionalInvoicing = [
              {
                label: invoicing.typeDescription,
                amount: Number(invoicing.amount).toFixed(2),
                currency,
              },
            ];

            if (invoicing.type === 'legalExpensesIgv') {
              additionalInvoicing.push({
                label: 'IGV',
                amount: (Number(invoicing.amount) * 0.18).toFixed(2),
                currency,
              });
            }

            return {
              title: invoicing.typeDescription,
              additionalInvoicing,
            };
          }),
      };
    } else {
      this.invoiceService
        .getInvoicingDetails(
          this.entity.invoice._id,
          this.entity.invoice.invoicingStatus
        )
        .subscribe((data: any) => {
          if (Array.isArray(data)) {
            this.isBulkInvoicing = true;
            this.bulkInvoicingDetails = data.map((d) => {
              const detail: any = {
                operationCodes: d.operationCodes,
              };

              detail.user = {
                service: [
                  {
                    label: 'Comisión Finsmart',
                    amount: d.user.service.finsmartReturn,
                    currency: d.user.service.currency,
                  },
                  {
                    label: 'Costo de financiamiento',
                    amount: d.user.service.investorReturn,
                    currency: d.user.service.currency,
                  },
                  {
                    label: 'IGV',
                    amount: d.user.service.finsmartReturnIGV,
                    currency: d.user.service.currency,
                  },
                ],
              };

              if (d.user.lateFee) {
                detail.user.lateFee = [
                  {
                    label: 'Mora',
                    amount: d.user.lateFee.amount,
                    currency: d.user.lateFee.currency,
                  },
                ];
              }

              if (d.debtor) {
                detail.debtor = {
                  service: [
                    {
                      label: 'Comisión Finsmart',
                      amount: d.debtor.service.finsmartReturn,
                      currency: d.debtor.service.currency,
                    },
                    {
                      label: 'Costo de financiamiento',
                      amount: d.debtor.service.investorReturn,
                      currency: d.debtor.service.currency,
                    },
                    {
                      label: 'IGV',
                      amount: d.debtor.service.finsmartReturnIGV,
                      currency: d.debtor.service.currency,
                    },
                  ],
                };

                if (d.debtor.lateFee) {
                  detail.debtor.lateFee = [
                    {
                      label: 'Mora',
                      amount: d.debtor.lateFee.amount,
                      currency: d.debtor.lateFee.currency,
                    },
                  ];
                }
              }
              return detail;
            });
          } else {
            this.invoicingDetails.user = {
              service: [
                {
                  label: 'Comisión Finsmart',
                  amount: data.user.service.finsmartReturn,
                  currency: data.user.service.currency,
                },
                {
                  label: 'Costo de financiamiento',
                  amount: data.user.service.investorReturn,
                  currency: data.user.service.currency,
                },
                {
                  label: 'IGV',
                  amount: data.user.service.finsmartReturnIGV,
                  currency: data.user.service.currency,
                },
              ],
            };

            if (data.user.lateFee) {
              this.invoicingDetails.user.lateFee = [
                {
                  label: 'Mora',
                  amount: data.user.lateFee.amount,
                  currency: data.user.lateFee.currency,
                },
              ];
            }

            if (data.debtor) {
              this.invoicingDetails.debtor = {
                service: [
                  {
                    label: 'Comisión Finsmart',
                    amount: data.debtor.service.finsmartReturn,
                    currency: data.debtor.service.currency,
                  },
                  {
                    label: 'Costo de financiamiento',
                    amount: data.debtor.service.investorReturn,
                    currency: data.debtor.service.currency,
                  },
                  {
                    label: 'IGV',
                    amount: data.debtor.service.finsmartReturnIGV,
                    currency: data.debtor.service.currency,
                  },
                ],
              };

              if (data.debtor.lateFee) {
                this.invoicingDetails.debtor.lateFee = [
                  {
                    label: 'Mora',
                    amount: data.debtor.lateFee.amount,
                    currency: data.debtor.lateFee.currency,
                  },
                ];
              }
            }
          }
        });
    }
  }
}
