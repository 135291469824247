import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getDaysDiff } from '../../../../../shared/util/date.util';
import { Subscription } from 'rxjs';
import { InvoiceService } from '../../../services/invoice.service';
import Big from 'big.js';

@Component({
  selector: 'app-collect-invoice-dialog',
  templateUrl: './collect-invoice-dialog.component.html',
  styleUrls: ['./collect-invoice-dialog.component.scss'],
})
export class CollectInvoiceDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  dateWarning;
  isChecked = false;
  feeData: any = {};
  private formSubscription: Subscription;
  newRefundAmount;
  delayedDays = 0;
  isInvoicingStopped = false;

  invoicingOptions = [];

  constructor(
    public dialogRef: MatDialogRef<CollectInvoiceDialogComponent>,
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      actualPaymentDate: ['', [Validators.required]],
      invoicingConfiguration: [''],
    });

    this.data.invoice.currencySymbol =
      this.data.invoice.currency === 'pen' ? 'S/ ' : '$ ';

    this.formSubscription = this.form
      .get('actualPaymentDate')
      .valueChanges.subscribe((selectedDate) => {
        const daysDiff = getDaysDiff(
          this.data.paymentDate,
          this.data?.partialPaymentDate || selectedDate
        );
        this.isChecked = false;
        this.isInvoicingStopped = false;

        this.invoicingOptions = [
          {
            text: 'Factura al girador: Girador asume todo el pago (Se consume la garantía)',
            value: 'normal',
          },
          {
            text: 'Facturación partida: Girador asume solo lo pactado',
            value: 'debtorAll',
          },
        ];

        this.form.controls['invoicingConfiguration'].setValue('normal');

        if (daysDiff < 0) {
          this.dateWarning = 'before';
        } else if (daysDiff === 0) {
          this.dateWarning = 'notDelayed';
        } else {
          this.isChecked = true;
          this.delayedDays = daysDiff;
          this.getInformationDelayed();
          this.dateWarning = 'delayed';
        }
      });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      let invoicingConfiguration = '';
      if (this.isInvoicingStopped) {
        invoicingConfiguration = 'noInvoicing';
      } else {
        if (this.data.invoice.isConfirming) {
          invoicingConfiguration = 'normal';
        } else if (this.data.invoice.invoicingStatus === 'delay') {
          invoicingConfiguration = 'delay';
        } else {
          invoicingConfiguration = this.form.value.invoicingConfiguration;
        }
      }

      if (this.data?.partialPaymentDate) {
        this.dialogRef.close({
          invoicingConfiguration,
          actualPaymentDate: this.data.partialPaymentDate,
          isFeeApplied: this.isChecked,
        });
      } else {
        this.dialogRef.close({
          invoicingConfiguration,
          actualPaymentDate: this.form.value.actualPaymentDate,
          isFeeApplied: this.isChecked,
        });
      }
    }
  }

  selectDelayedFee(isChecked) {
    this.isChecked = isChecked;
    if (isChecked) {
      this.getInformationDelayed();
    }
  }

  stopInvoicing(isChecked) {
    this.isInvoicingStopped = isChecked;
  }

  getInformationDelayed() {
    this.invoiceService
      .getLatefee(
        this.data.invoice._id,
        this.data.partialPaymentDate || this.form.get('actualPaymentDate').value
      )
      .subscribe((data) => {
        this.feeData = data;
        this.newRefundAmount = new Big(this.data.invoice.reservationAmount)
          .minus(new Big(this.feeData?.newTotalReturn))
          .toFixed(2);

        if (Number(this.newRefundAmount) < 0) {
          if (
            !this.invoicingOptions.some(
              (option) => option.value === 'debtorExtra'
            )
          ) {
            this.invoicingOptions = [
              {
                text: 'Facturación partida: Girador asume hasta agotar garantía',
                value: 'debtorExtra',
              },
              {
                text: 'Facturación partida: Girador asume solo lo pactado',
                value: 'debtorAll',
              },
              {
                text: 'Factura al girador: Girador asume todo el pago (Se consume la garantía)',
                value: 'normal',
              },
            ];
          }

          this.form.controls['invoicingConfiguration'].setValue('debtorExtra');
        } else {
          this.form.controls['invoicingConfiguration'].setValue('normal');
        }
        this.isInvoicingStopped = false;
      });
  }
  cancel() {
    this.dialogRef.close();
  }
}
